header.banner {
	
	#open-close-menu {
		position: absolute;
		top: 50%;
		margin-top: -30px;
		left: 1rem;
		z-index: 10;
		
		.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
			background-color: $light-blue;	
		}
		
	}
	
	.header-main {
		padding-top: 1rem;
		padding-bottom: 1rem;
		
		a.brand {
			img {
				max-height: 70px;
				@include media-breakpoint-up(sm) {
					max-height: 120px;
				}
			}
		}
		
			
		ul.nav {
			text-transform: uppercase;
			
			li {
				padding-right: 1rem;
				font-size: .7rem;
				&:last-child {
					padding-right: 0;
				}
				
				@include media-breakpoint-up(lg) {
					padding-right: 2.4rem;
					font-size: .75rem;
				}
				@include media-breakpoint-up(xl) {
					font-size: .9rem;
					padding-right: 3.2rem;
				}
				
				a {
					position: relative;
					padding-bottom: .4rem;
					&:before {
						font-family: "Font Awesome 5 Free";
						font-weight: 900; 
						content: "\f1b0";
						position: absolute;
						top: 0;
						line-height: 1.6rem;
						left: 50%;
						color: $white;
						font-size: 1.6rem;
						margin-left: -13px;
						z-index: -1;
						@include animate-element(.2s);
					}
					&:after {
						content: '';
						position: absolute;
						left: .2rem;
						right: .2rem;
						bottom: 0;
						height: 2px;
						background: transparent;
						@include animate-element(.2s);
					}
					&:hover {
						color: $brown;
						&:before {
							color: $light-blue;
							top: -28px;
						}
						&:after {
							background: $light-blue;
						}
					}	
				}
				
				&.active {
					a {
						&:before {
							color: $light-blue;
							top: -28px;
						}
						&:after {
							background: $light-blue;
						}
					}
				}
							
			}
		}
		
	}

	ul.nav.contact-nav {
		text-align: right;
		li {
			padding-right: 1rem;
		}

		@include media-breakpoint-up(lg) {
			li {
				padding-right: 1.6rem;
			}
		}
		@include media-breakpoint-up(xl) {
			li {
				padding-right: 2.5rem;
			}
		}
		
	}
	
	ul.social {
		float: right;
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
		font-size: .8rem;
		position: relative;
		top: 2px;
		li {
			display: inline-block;
			margin-left: .5rem;
			&:first-child {
				margin-left: 0;
			}
		}
		@include media-breakpoint-up(md) {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			display: block;
			font-size: .7rem
		}
		@include media-breakpoint-up(xl) {
			display: block;
			font-size: .8rem;
		}

	}
	
	.header-bar {
		background: $tan;
		font-size: .6rem;
		padding-top: .7rem;
		padding-bottom: .6rem;
		font-weight: 300;
		color: $brown;
		@extend .text-center;
		
		@include media-breakpoint-up(sm) {
			font-size: .8rem;
		}
		
		a.phone-number,
		address,
		.hours-header {
			display: inline-block;
			margin-bottom: 0;
		}
		
		a.phone-number {
			display: inline-block;
			margin-right: 1rem;
			@include media-breakpoint-up(sm) {
				margin-right: 2rem;
			}
		}
		
		address {
			
		}
		
		.hours-header {
			list-style: none;
			padding-left: 0;
			margin-right: 2rem;
			li {
				display: inline-block;
				position: relative;
				margin-right: 1rem;
				&:after {
					content: '';
					height: 13px;
					width: 1px;
					background: $brown;
					top: 3px;
					right: -.6rem;
					z-index: 10;
					position: absolute;
				}
				&:nth-child(3) {
					margin-right: 0;
					&:after {
						display: none;
					}
				}
				&:last-child {
					display: none;
				}
			}
		}
			
	}
	
}

body.single-services {
	header.banner {
		.header-main {
			ul.nav {
				li.menu-services {
					a {
						&:before {
							color: $light-blue;
							top: -28px;
						}
						&:after {
							background: $light-blue;
						}
					}
				}
			}	
		}
	}
}

body.single-team, {
	header.banner {
		.header-main {
			ul.nav {
				li.menu-our-team {
					a {
						&:before {
							color: $light-blue;
							top: -28px;
						}
						&:after {
							background: $light-blue;
						}
					}
				}
			}	
		}
	}
}

body.contact-us, {
	header.banner {
		.header-main {
			ul.nav {
				li.contact-us {
					a {
						&:before {
							color: $light-blue;
							top: -28px;
						}
						&:after {
							background: $light-blue;
						}
					}
				}
			}	
		}
	}
}
