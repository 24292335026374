.btn {
	
	&.btn {
		@include animate-element(.2s);
		@include round(0px);
		padding: .4rem 1rem .5rem;
		font-size: .9rem;
	}
	
	&.btn-primary {
		background: #b3d8ef;
		border-color: $white;
		font-weight: 700;
		color: $white;
				
		&:hover {
			background: $brown;
			color: $light-blue;
		}
	}
	
	&.btn-secondary {
		background: $tan;
		border: 1px solid $brown;
		color: $brown;
		
		&:hover {
			background: $brown;
			color: $white;
		}
	}
	
	&.btn-info {
		background: $brown;
		color: $light-blue;
		border: 1px solid $light-blue;
		padding: .3rem 1rem .4rem;
		i {
			margin-left: .3rem;
		}
		&:hover {
			background: $light-blue;
			border: 1px solid $light-blue;
			color: $white;
		}
	}
	
	&.btn-white-outline {
		background: transparent;
		color: $white;
		border: 1px solid $white;
		padding: .4rem 1rem .4rem;
		font-size: .8rem;
		font-weight: 700;
		@include round(2px);
		i {
			margin-left: .3rem;
		}
		&:hover {
			background: $white;
			color: $brown;	
		}
	}
	
}