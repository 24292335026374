footer.content-info {
	background: $light-blue;
	.quote {
		background: $white;
		color: $light-blue;
		position: relative;
		padding-top: 1.5rem;
		padding-bottom: 1.8rem;
		font-size: 1.5rem;
		line-height: 2rem;
		font-weight: 300;
		&:after {
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -10px;
			content: '';
			width: 0; 
			height: 0; 
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			
			border-top: 10px solid $white;			
		}
		
		@include media-breakpoint-up(md) {
			font-size: 2rem;
			line-height: 1.5rem;
		}
	}
	
	.footer-columns {
		background: $brown;
		padding-top: 3rem;
		padding-bottom: 3rem;
		color: $tan;
		font-size: .8rem;
		line-height: 1.3rem;
		
		h3 {
			color: $tan;
		}
		
		a.brand {
			float: left;
			display: block;
			width: 20%;
			margin-right: 1rem;
			
			img {
				max-width: 100%;
				margin-bottom: 1rem;
			}

			@include media-breakpoint-up(md) {
				float: none;
				width: auto;
				margin-right: 0;
				img {
					max-width: 35%;
				}
			}
			
		}
		
		
		a {
			color: $light-blue;
		}
		
		p {
			font-size: .8rem;
			line-height: 1.3rem;
			font-weight: 400;
			color: $tan;
		}
		
		ul {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			color: $light-blue;
		}
		
		address {
			margin-bottom: 0;
			color: $light-blue;
			border-top: 1px solid $tan;
			border-bottom: 1px solid $tan;
			padding: .7rem 0;
			margin: 1rem 0;
		}
		
	}

	
	.copyright {
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: .7rem;
		line-height: 19px;
		
		cite,
		nav,
		ul.social {
			display: block;
			@include media-breakpoint-up(md) {
				display: inline-block;
			}
		}
		
		cite {
			margin-right: 0;
			color: $white;
			font-style: normal;
			margin-bottom: .6rem;
			@include media-breakpoint-up(md) {
				margin-right: 3.5rem;
				margin-bottom: 0;
			}
		}
		
		nav {
			text-align: center;
			ul {
				
				li {
					padding: .2rem 1rem .2rem 0;
					a {
						&:hover {
							color: $white;
						}
					}
				}
			}
			@include media-breakpoint-up(md) {
				ul {
					li {
						padding: 0 2.5rem 0 0;
						a {
							
						}
					}
				}
			}
		}
		
		ul.social {
			
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
			padding-top: .5rem;
			font-size: .8rem;
			li {
				display: inline-block;
				margin-right: .8rem;
			}
			@include media-breakpoint-up(md) {
				padding-top: 0;
				float: right;
				margin-left: .3rem;
				margin-right: 0;
			}
		}
	}
	
	.latest-posts-footer {
		
		.col-sm-6 {
			&:nth-child(1),
			&:nth-child(2) {
				margin-bottom: 1.5rem;
				
				.post-inner {
					border-bottom: 1px solid $tan;
					padding-bottom: .75rem;
				}
			}
		}
		
		h5 {
			font-size: 1rem;
			margin-left: 1rem;
			margin: 0 1rem .5rem;
		}
		
		p {
			height: 60px;
			overflow: hidden;
			margin: 0 1rem;
			font-size: .7rem;
			line-height: 1.2rem;
		}
		
	}
	
}