/* Page Header styles */
.page-header {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-image: url('../images/heading-bg.jpg');
	@include background-cover();
	
	h1 {
		margin-bottom: 0;
		//padding: 0 1rem;
		font-weight: 300;
		color: $brown;
	}
	
	cite {
		font-weight: 100;
		font-style: normal;
		color: $brown;
	}
	
	@include media-breakpoint-up(md) {
		padding-top: 4rem;
		padding-bottom: 4rem;
		h1 {
			
		}
	
	}
	
}

.background-video {
	//height: 200px;
	position: relative;
	overflow: hidden;
	
	#myVideo {
		display: none;
		source {

		}
		@include media-breakpoint-up(md) {
			display: block;
		}
	}
	
	.banner-content {
		background:  rgb(247,249,247);
		background: rgba(247,249,247,.8);
		padding: 1.5rem 2rem 1.8rem;		
		h2 {
			font-weight: 700;
			color: $brown;
			font-size: 2.7em;
			line-height: 2.8rem;
			margin-bottom: 0;
		}
		p {
			font-weight: 300;
			font-size: 1rem;
			color: $brown;
			margin-bottom: 1.2rem;
		}
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 50%;
			left: 15%;
			margin-top: -89px;
		}
	}
	
	@include media-breakpoint-up(md) {
		height: 550px;

		#myVideo {
			width: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
		}

	}
	
}

/* Owl Carousel styles */
.owl-carousel {
	
	&.hero-carousel {
		
		.banner-content {
			background:  rgb(247,249,247);
			background: rgba(247,249,247,.8);
			padding: 1.5rem 2rem 1.8rem;
			
			h2 {
				font-weight: 700;
				color: $brown;
				font-size: 2.7em;
				line-height: 2.8rem;
				margin-bottom: 0;
			}
			p {
				font-weight: 300;
				font-size: 1rem;
				color: $brown;
				margin-bottom: 1.2rem;
			}
			
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 50%;
				left: 15%;
				margin-top: -89px;
			}
		}
		
		.owl-nav {
			
			.owl-prev,
			.owl-next {
				top: 24%;
				@include media-breakpoint-up(sm) {
					top: 26%;
				}			
				@include media-breakpoint-up(md) {
					top: 50%;
				}			
			}
			
		}
		
		.owl-dots {
			display: none;
			@include media-breakpoint-up(md) {
				display: block;
			}
		}
		
	}
	
	.owl-nav {
		.owl-prev,
		.owl-next {
			position: absolute;
			top: 50%;
			margin-top: -20px;
			color: #8c9b97;
			background:  rgb(255,255,255);
			background: rgba(255,255,255,.2);
			width: 40px;
			height: 40px;
			line-height: 40px;
			font-size: 1.1rem;
			text-align: center;
			@include round(50px);
			@include animate-element(.2s);
			&:hover {
				background:  rgb(255,255,255);
				background: rgba(255,255,255,.5);
			}
			&.disabled {
				display: none;
			}
		}
		.owl-prev {
			left: 2rem;
		}
		.owl-next {
			right: 2rem;
		}
	}
	
	.owl-dots {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 2rem;
		text-align: center;
		.owl-dot {
			display: inline-block;
			span {
				display: block;
				width: 15px;
				height: 15px;
				margin: 0 .3rem;
				background:  rgb(255,255,255);
				background: rgba(255,255,255,.25);
				@include round(15px);
				@include animate-element(.2s);
			}
			&.active,
			&:hover {
				span {
					background:  rgb(255,255,255);
					background: rgba(255,255,255,.5);
				}
			}
		}
		
	}
	
}

/* Content builder styles */
section.content-builder {
	padding-top: 2rem;
	padding-bottom: 2rem;
	
	@include media-breakpoint-up(md) {
		padding-top: 4rem;
		padding-bottom: 4rem;	
	}
	
	/* Column Styles */
	&.columns {
		
		img {
			max-width: 80%;
			margin-bottom: 1rem;
		}
		
		h3 {
			font-weight: 400;
			font-size: 1.2rem;
			color: $brown;
			margin-bottom: 1rem;
		}
		
		p {
			font-size: .8rem;
			color: $blue-text;
			font-weight: 300;
			line-height: 1.4rem;
			margin-bottom: 0;
			a {
				font-style: italic;
				text-transform: uppercase;
				&:hover {
					text-decoration: underline;
					color: $brown;
				}
			}
		}
		
		.col-md {
			margin-bottom: 2rem;
			padding-left: 2rem;
			padding-right: 2rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
		
		@include media-breakpoint-up(sm) {
			
			.col-md {
				margin-bottom: 0;
				padding-left: 15px;
				padding-right: 15px;
			}
			
		}
		
	}

	/* Team Styles */
	&.our-team {
		background: $light-blue;
		
		h3 {
			font-weight: 100;
			font-size: 2.2rem;
			margin-bottom: 1.5rem;
			color: $brown;
		}
		p {
			color: $brown;
			font-weight: 300;
			margin-bottom: 1.5rem;
		}
		
		.meet-our-team {
			padding-left: 2rem;
			padding-right: 2rem;
			margin-bottom: 2rem;
			@include media-breakpoint-up(md) {
				padding-left: 15px;
				padding-right: 15px;
			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 0
			}
			
		}
		
		.team-member {
			padding-left: 2rem;
			padding-right: 2rem;
			margin-bottom: 2rem;
			&:last-child {
				margin-bottom: 0;
			}
			
			@include media-breakpoint-up(md) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 0;			
			}
			
			a {
				margin-bottom: 75px;
				display: block;
			}
			
			.member-info {
				background: #f5f5f5;
				padding: 1rem 1.5rem;
				position: absolute;
				bottom: 0;
				left: 2rem;
				right: 2rem;
				height: 75px;
				@include animate-element(.2s);
				
				@include media-breakpoint-up(md) {
					left: 0;
					right: 0;
				}
				
				h4 {
					color: $brown;
					font-size: 1.1rem;
					font-weight: 700;
					margin-bottom: 0;
					@include animate-element(.2s);	
				}
				p {
					color: $blue-text;
					font-size: .8rem;
					line-height: 1.4rem;
					margin-bottom: 0;
				}

				&:after {
					position: absolute;
					top: -6px;
					left: 2rem;
					content: '';
					width: 0; 
					height: 0; 
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					
					border-bottom: 8px solid #f5f5f5;
					@include animate-element(.2s);	
				}

			}
			
			&:hover {
				
				.member-info {
					background: $brown;
					padding-bottom: 1rem;
					height: 80px;
					h4 {
						color: $tan;
					}
					&:after {
						border-bottom: 6px solid $brown;
					}
				}
				
			}
						
		}
	}
	
	/* Content Block Styles */
	&.content-block {
		
		.block-image,
		.block-content {
			padding-left: 2rem;
			padding-right: 2rem;
			@include media-breakpoint-up(md) {
				padding-left: 15px;
				padding-right: 15px;
				.block-image {
					margin-bottom: 0;
				}
			}
			
		}
		.block-image {
			margin-bottom: 2rem;
		}
		.block-content {
			
		}
		
		.content-area {
			margin-bottom: 2rem;
			&:last-child {
				margin-bottom: 0;
			}
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
				&:nth-child(1),
				&:nth-child(2) {
					margin-bottom: 2rem;	
				}			
			}
		}
		
		h2 {
			font-weight: 100;
			font-size: 2.5rem;
			margin-bottom: 2rem;
		}
		
		h3 {
			color: $blue-text;
			font-size: 1.1rem;
			font-weight: 300;
			margin-bottom: 1rem;
			i {
				color: #dad3ac;
				font-size: 1.4rem;
				margin-right: .2rem;
				position: relative;
				top: 2px;
			}
		}
		
		p {
			font-size: .8rem;
			line-height: 1.4rem;
			margin-bottom: .5rem;
		}
		
		a {
			font-weight: 700;
			font-size: .8rem;
			line-height: 1.4rem;
		}
				
	}
	
	/* Service Block Styles */
	&.sevices-block {
		padding-top: 2rem;
		padding-bottom: 2rem;
		@include background-cover();
	
		@include media-breakpoint-up(md) {
			padding-top: 6rem;
			padding-bottom: 6rem;		
		}
		
		.service-wrap {
			padding-left: 2rem;
			padding-right: 2rem;
			@include media-breakpoint-up(md) {
				padding-left: 15px;
				padding-right: 15px;
			}
			
			.service-inner {
				padding: 2rem;
				@include white-overlary();
			}
			
			ul {
				list-style: none;
				padding-left: 0;

-moz-column-count: 2;
-moz-column-gap: 20px;
-webkit-column-count: 2;
-webkit-column-gap: 20px;
column-count: 2;
column-gap: 20px;
				
				li.service {
					padding-left: 0;
					padding-right: 0;
				}

			}
			
			h3 {
				font-weight: 100;
				margin-bottom: 1.5rem;
			}
			
			p {
				font-weight: 300;
				font-size: .8rem;
				line-height: 1.5rem;
				margin-bottom: 1.5rem;
			}
			
			.row {
				margin-bottom: 1.5rem;
			}
			
			.service {
				font-size: .8rem;
				font-weight: 700;
				margin-bottom: .3rem;
				padding-left: 30px;
				padding-right: 30px;
				a {
					position: relative;
					display: block;
					padding-left: 15px;
					&:before {
						content: '';
						position: absolute;
						top: 4px;
						left: 0;
						display: block;
						width: 10px;
						height: 10px;
						background: $white;
						@include round(10px);
						@include animate-element(.2s);	
					}
					&:hover {
						color: $brown;
						&:before {
							background: $light-blue;	
						}
					}
				}
			}
			
		}
	}
	
	/* Counter Block Styles */
	&.counter-columns {
		background: $brown;
		color: $white;
		padding-top: 2.5rem;
		padding-bottom: 3rem;
		
		@include media-breakpoint-up(md) {
			padding-top: 3.5rem;
			padding-bottom: 4rem;
		}
		
		.count-block {
			margin-bottom: 2.5rem;
			padding-left: 2rem;
			padding-right: 2rem;
			&:last-child {
				margin-bottom: 0;
			}
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		
		h5 {
			text-transform: uppercase;
			font-size: .9rem;	
			line-height: 1.3rem;
			margin-bottom: 1.5rem;
		}
		
		span.fig-number {
			font-size: 3rem;
			font-weight: 100;
			display: block;
			margin-bottom: 1rem;
			line-height: 3rem;
		}
		
	}
	
	/* Testimonials Block Styles */
	&.testimonials {
		padding-top: 10%;
		padding-bottom: 10%;
		@include background-cover();
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			@include white-overlary();
		}

		.col-sm-8 {
			padding-left: 2rem;
			padding-right: 2rem;
			@include media-breakpoint-up(md) {
				padding-left: 15px;
				padding-right: 15px;
			}
			
		}
		
		p {
			font-weight: 700;
			font-size: 1rem;
			line-height: 1.8rem;
		}
		
		h4 {
			font-size: .9rem;
			font-weight: 300;
			color: $brown;
		}
		
	}

	/* Latest Posts Styles */
	&.latest-posts {
		padding-top: 0;
		padding-bottom: 0;
		
		.container-fluid {
			padding-left: 0;
			padding-right: 0;
		}
			
		h3 {
			font-weight: 100;
			font-size: 2.2rem;
			margin-bottom: 1.5rem;
			color: $brown;
		}
		p {
			color: $brown;
			font-weight: 300;
			margin-bottom: 1.5rem;
		}
		
		.team-member {
			padding-left: 0;
			padding-right: 0;
			
			a {
				margin-bottom: 75px;
				display: block;
			}
			
			.member-info {
				background: $light-blue;
				padding: 1rem;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 75px;
				@include animate-element(.2s);	
				
				h4 {
					color: $brown;
					font-size: 1rem;
					font-weight: 700;
					margin-bottom: 0;
					@include animate-element(.2s);	
				}
				p {
					color: $blue-text;
					font-size: .8rem;
					line-height: 1.4rem;
					margin-bottom: 0;
				}

				&:after {
					position: absolute;
					top: -6px;
					left: 2rem;
					content: '';
					width: 0; 
					height: 0; 
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					
					border-bottom: 8px solid $light-blue;
					@include animate-element(.2s);	
				}

			}
			
			&:hover {
				
				.member-info {
					background: $brown;
					padding-bottom: 1rem;
					height: 80px;
					h4 {
						color: $tan;
					}
					&:after {
						border-bottom: 6px solid $brown;
					}
				}
				
			}
						
		}
	}
	
	/* Gallery / Video Block Styles */
	&.gallery-video-block {
		background: $tan;
		padding-top: 3rem;
		
		@include media-breakpoint-up(md) {
			padding-top: 4rem;
		}
		
		.col-lg-6 {
			padding-left: 2rem;
			padding-right: 2rem;
			
			&:first-child {
				margin-bottom: 2rem;
			}
					
			@include media-breakpoint-up(lg) {
				padding-left: 15px;
				padding-right: 15px;
				&:first-child {
					margin-bottom: 0;
				}
			}
			
		}
		
		.inner {
			background: $white;
			padding: .5rem;	
		}

		h2 {
			font-weight: 100;
			font-size: 2.5rem;
			margin-bottom: 1rem;
		}		

		h4 {
			font-weight: 100;
			font-size: 2rem;
			margin-bottom: 1rem;
			color: $brown;
		}		

		p {
			font-weight: 300;
			font-size: .8rem;
			line-height: 1.5rem;
			margin-bottom: 1rem;
		}
		
		ul {
			list-style: none;
			margin-bottom: 1.5rem;
			padding-left: 1rem;
			&:last-child {
				margin-bottom: 0;
			}

			li {
				font-size: .9rem;
				line-height: 1.5rem;
				margin-bottom: .5rem;
				color: $brown;
				position: relative;
				padding-left: 1rem;
				&:before {
					font-family: "Font Awesome 5 Free";
					font-weight: 900; 
					content: "\f105";
					position: absolute;
					top: 0px;
					left: 0;
					color: $light-blue;
				}
			}
		}
				
	}

	/* Google Map Block Styles */
	&.google-map {
		padding-top: 0;
		padding-bottom: 0;
		border-top: 3px solid $brown;
		
		.row {
			margin-left: 0;
			margin-right: 0;
		}
		.contact-info {
			padding-left: 2rem !important;
			padding-right: 2rem !important;
			.row {
				margin-left: 0;
				margin-right: 0;
				.col-sm-6 {
					padding-left: 0;
					padding-right: 0;
					&:nth-child(1) {
						padding-right: 1.5rem;
					}
					&:nth-child(2) {
						padding-left: 1.5rem;
					}
				}
			}
			
		}
		.col-md-6 {
			padding-left: 0;
			padding-right: 0;
			&:nth-child(1) {
				height: 550px;
				overflow: hidden;
			}
		}
		
		h3 {
			color: $blue-text;
			font-weight: 400;
			font-size: 1.1rem;
		}
		
		p {
			font-size: .9rem;
			line-height: 1.5rem;
			margin-bottom: 1.5rem			
		}

		address {
			font-size: .9rem;
			line-height: 1.5rem;
			color: $brown;
			margin-bottom: 0;
		}
		
	}
	
	/* Service List Styles */
	&.sevices-list {
		padding-bottom: 2rem;
		h2 {
			font-weight: 100;
			color: $brown;
			text-align: center;
			margin-bottom: 2rem;
			
			@include media-breakpoint-up(md) {
				margin-bottom: 4rem;
			}
		}
		
		.service {
			margin-bottom: 3rem;
			padding-left: 2rem;
			padding-right: 2rem;
			
			h3 {
				font-weight: 700;
				font-size: 1.2rem;
			}
			
			p {
				font-size: .8rem;
				line-height: 1.4rem;
				color: $blue-text;
				font-weight: 300;
				margin-bottom: 0;
			}
			
			a {
				font-size: .8rem;
				line-height: 1.4rem;
				font-weight: 300;
				text-transform: uppercase;
			}
			
			img {
				margin-bottom: 1.5rem;
			}
						
			@include media-breakpoint-up(md) {
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 2rem;
				
				
				
				img {
					padding-bottom: 0;
				}
			}
			
		}
		
	}
	
	/* Full Width Content Styles */
	&.full-width-content {
		@include background-cover();
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			@include white-overlary();
		}

		h3 {
			font-weight: 100;
			font-size: 2.5rem;
			margin-bottom: 1rem;
		}		

		h4 {
			font-weight: 100;
			font-size: 2rem;
			margin-bottom: 1rem;
			color: $brown;
		}		
		
		ul {
			list-style: none;
			margin-bottom: 1.5rem;
			padding-left: 1rem;
			li {
				font-size: .9rem;
				line-height: 1.5rem;
				margin-bottom: .5rem;
				color: $brown;
				position: relative;
				padding-left: 1rem;
				&:before {
					font-family: "Font Awesome 5 Free";
					font-weight: 900; 
					content: "\f105";
					position: absolute;
					top: 0px;
					left: 0;
					color: $light-blue;
				}
			}
		}
		
		p {
			font-size: .9rem;
			line-height: 1.5rem;
			margin-bottom: 1.5rem;
		}
		
	}
	
}