.form-control {
	border: none;
	@include round(0px);
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
  border: 1px solid $brown;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
  display: none;
}

// Gravity Form Styles
.content-builder {
	
	.gform_wrapper {
		margin: 0;
	
		.gform_body {
			margin-left: -15px;
			margin-right: -15px;
		}
		
		ul.gform_fields {
			@extend .row;
			
			li.gfield {
				padding-right: .5rem;
				margin-top: 0;
				margin-bottom: 1rem;
				padding-left: .5rem;
				&:before {
					display: none !important;
				}
				
				&.no-label {
					.gfield_label {
						display: none;
					}
				}
			}
		}
		
		.top_label {
			div.ginput_container {
				margin-top: 0;
			}
		}
		
		input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), textarea {
			@extend .form-control;
		}
		
		textarea {
			min-height: 150px;
		}
		
		.gform_footer {
			padding: 1rem 0 0;
			margin: 0;
			input[type="submit"] {
				@extend .btn;
				@extend .btn-info;
			}
		}
		
	}
		
}
