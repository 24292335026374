// Blog Index Styling
body.blog,
body.category,
body.search {
	
	.blog-nav {
		margin-top: 2.5rem;
		section.widget {
			display: inline-block;
			h3 {
				display: none;
			}
			ul {
				list-style: none;
				padding-left: 0;
				margin-bottom: 2rem;
				margin-right: 1rem;
				li {
					display: inline-block;
					margin-right: .3rem;
					margin-bottom: .5rem;
					a {
						@extend .btn;
						@extend .btn-primary;
					}
					
					&.current-cat {
						a {
							@extend .btn-info;
						}
					}
					
				}
			}
		}
		
	}
	
	.blog-list-outer {
		background: $tan;
	}
	
	.blog-list {
		margin-top: 2.5rem;
		margin-bottom: 3rem;
		margin-left: 0;
		margin-right: 0;
		
		img {
			margin-bottom: 90px;
		}
		
		h2 {
			font-size: 1rem;
			margin-bottom: 0;
			a {
				
			}
		}

		p {
			font-size: .8rem;
			margin-bottom: 0;
			margin-top: 1rem;
			color: $light-blue;
			@include animate-element(.2s);
		}
		
		.post-content {
			padding: 1.5rem;
			background: $white;
			min-height: 90px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			@include animate-element(.2s);

			&:after {
				position: absolute;
				top: -8px;
				left: 2rem;
				content: '';
				width: 0; 
				height: 0; 
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				
				border-bottom: 8px solid $white;
				@include animate-element(.2s);	
			}
		}

		.featured,
		.two-five,
		.regular-post {
			padding: 0;
		}
		
		.featured {
			
			h2 {
				font-size: 1.1rem;
			}
			
			&:hover {
				.post-content {
					background: $brown;
					padding-bottom: 2rem;
					&:after {
						border-bottom: 8px solid $brown;
					}
					h2 {
						a {
							color: $tan;
						}
					}
					p {
						color: $white;
						a {
							color: $light-blue;
						}
					}
				}
			}
			
		}
		
		.two-five {
			.row {
				margin: 0;
				.col-sm-6 {
					padding: 0;

					&:hover {
						.post-content {
							background: $brown;
							padding-bottom: 2rem;
							&:after {
								border-bottom: 8px solid $brown;
							}
							h2 {
								a {
									color: $tan;
								}
							}
							p {
								color: $white;
								a {
									color: $light-blue;
								}
							}
						}
					}

				}
			}
		}
		
		.regular-post {
			&:hover {
				.post-content {
					background: $brown;
					padding-bottom: 2rem;
					&:after {
						border-bottom: 8px solid $brown;
					}
					h2 {
						a {
							color: $tan;
						}
					}
					p {
						color: $white;
						a {
							color: $light-blue;
						}
					}
				}
			}
		}
		
	}
	
}

body.single-post {
	.page-content-wrap {
		padding-top: 4rem;
		padding-bottom: 4rem;
		background: $tan;
		
		.entry-content {
			img {
				background: $white;
				padding: .4rem;
				margin-bottom: 1rem;
				&.alignleft {
					margin-right: 2.5rem;
				}
				&.alignright {
					margin-left: 2.5rem;
				}
			}
			
			ul {
				color: $brown;
				
				li {
					
				}
			}

			p {
				font-size: .9rem;
				line-height: 1.5rem;
				margin-bottom: 1.5rem;
				&:last-child {
					margin-bottom: 0;
				}
			}

		}
	}
}

body.single-team {
	
	.page-content-wrap {
		background: $light-blue;
		padding-top: 2.5rem;
		padding-bottom: 4.5rem;
	}
	
	.team-main,
	.team-blocks {
		margin-left: 0;
		margin-right: 0;
		> div {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	.team-main {
		h2 {
			font-weight: 100;
			padding-right: 2rem;
			margin-bottom: 1.5rem;
		}
		p {
			padding-right: 2rem;
			font-weight: 300;
			line-height: 1.7rem;
			margin-bottom: 1.5rem;
		}
	}
	
	.team-blocks {
		
		> div {
			background: $brown;
		}
		
		.p-2 {
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}
		
		h3 {
			color: $tan;
			font-weight: 100;
			font-size: 2rem;
		}
		p {
			color: $tan;
			font-weight: 300;
			margin-bottom: 0;
		}
		
	}
	
}