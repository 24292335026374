@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled, .comment-list {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid, .wp-caption > img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure, .wp-caption {
  display: inline-block; }

.figure-img, .wp-caption > img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, .wp-caption-text {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .content-builder .gform_wrapper ul.gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #FFFFFF; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #FFFFFF;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea, .search-form .search-field, .content-builder .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control, .comment-form input[type="text"],
    .comment-form input[type="email"],
    .comment-form input[type="url"],
    .comment-form textarea, .search-form .search-field, .content-builder .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea {
      transition: none; } }
  .form-control::-ms-expand, .comment-form input[type="text"]::-ms-expand,
  .comment-form input[type="email"]::-ms-expand,
  .comment-form input[type="url"]::-ms-expand,
  .comment-form textarea::-ms-expand, .search-form .search-field::-ms-expand, .content-builder .gform_wrapper input:not([type='file']):not([type='image']):not([type='button']):not([type='submit']):not([type='checkbox']):not([type='radio'])::-ms-expand, .content-builder .gform_wrapper textarea::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .comment-form input[type="text"]:focus,
  .comment-form input[type="email"]:focus,
  .comment-form input[type="url"]:focus,
  .comment-form textarea:focus, .search-form .search-field:focus, .content-builder .gform_wrapper input:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder, .comment-form input[type="text"]::placeholder,
  .comment-form input[type="email"]::placeholder,
  .comment-form input[type="url"]::placeholder,
  .comment-form textarea::placeholder, .search-form .search-field::placeholder, .content-builder .gform_wrapper input:not([type='file']):not([type='image']):not([type='button']):not([type='submit']):not([type='checkbox']):not([type='radio'])::placeholder, .content-builder .gform_wrapper textarea::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .comment-form input[type="text"]:disabled,
  .comment-form input[type="email"]:disabled,
  .comment-form input[type="url"]:disabled,
  .comment-form textarea:disabled, .search-form .search-field:disabled, .content-builder .gform_wrapper input:disabled:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea:disabled, .form-control[readonly], .comment-form input[readonly][type="text"],
  .comment-form input[readonly][type="email"],
  .comment-form input[readonly][type="url"],
  .comment-form textarea[readonly], .search-form [readonly].search-field, .content-builder .gform_wrapper input[readonly]:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]), .search-form select.search-field:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value, .search-form select.search-field:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control, .comment-form .input-group-sm > input.form-control-plaintext[type="text"],
  .comment-form .input-group-sm > input.form-control-plaintext[type="email"],
  .comment-form .input-group-sm > input.form-control-plaintext[type="url"],
  .comment-form .input-group-sm > textarea.form-control-plaintext, .search-form .input-group-sm > .form-control-plaintext.search-field, .content-builder .gform_wrapper .input-group-sm > input.form-control-plaintext:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group-sm > textarea.form-control-plaintext,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .comment-form
  .input-group-sm > .input-group-prepend > input.form-control-plaintext[type="submit"], .search-form
  .input-group-sm > .input-group-prepend > .form-control-plaintext.search-submit, .content-builder .gform_wrapper .gform_footer
  .input-group-sm > .input-group-prepend > input.form-control-plaintext[type="submit"], body.blog .blog-nav section.widget ul li
  .input-group-sm > .input-group-prepend > a.form-control-plaintext,
  body.category .blog-nav section.widget ul li
  .input-group-sm > .input-group-prepend > a.form-control-plaintext,
  body.search .blog-nav section.widget ul li
  .input-group-sm > .input-group-prepend > a.form-control-plaintext,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .comment-form
  .input-group-sm > .input-group-append > input.form-control-plaintext[type="submit"], .search-form
  .input-group-sm > .input-group-append > .form-control-plaintext.search-submit, .content-builder .gform_wrapper .gform_footer
  .input-group-sm > .input-group-append > input.form-control-plaintext[type="submit"], body.blog .blog-nav section.widget ul li
  .input-group-sm > .input-group-append > a.form-control-plaintext,
  body.category .blog-nav section.widget ul li
  .input-group-sm > .input-group-append > a.form-control-plaintext,
  body.search .blog-nav section.widget ul li
  .input-group-sm > .input-group-append > a.form-control-plaintext, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control, .comment-form .input-group-lg > input.form-control-plaintext[type="text"],
  .comment-form .input-group-lg > input.form-control-plaintext[type="email"],
  .comment-form .input-group-lg > input.form-control-plaintext[type="url"],
  .comment-form .input-group-lg > textarea.form-control-plaintext, .search-form .input-group-lg > .form-control-plaintext.search-field, .content-builder .gform_wrapper .input-group-lg > input.form-control-plaintext:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group-lg > textarea.form-control-plaintext,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .comment-form
  .input-group-lg > .input-group-prepend > input.form-control-plaintext[type="submit"], .search-form
  .input-group-lg > .input-group-prepend > .form-control-plaintext.search-submit, .content-builder .gform_wrapper .gform_footer
  .input-group-lg > .input-group-prepend > input.form-control-plaintext[type="submit"], body.blog .blog-nav section.widget ul li
  .input-group-lg > .input-group-prepend > a.form-control-plaintext,
  body.category .blog-nav section.widget ul li
  .input-group-lg > .input-group-prepend > a.form-control-plaintext,
  body.search .blog-nav section.widget ul li
  .input-group-lg > .input-group-prepend > a.form-control-plaintext,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn, .comment-form
  .input-group-lg > .input-group-append > input.form-control-plaintext[type="submit"], .search-form
  .input-group-lg > .input-group-append > .form-control-plaintext.search-submit, .content-builder .gform_wrapper .gform_footer
  .input-group-lg > .input-group-append > input.form-control-plaintext[type="submit"], body.blog .blog-nav section.widget ul li
  .input-group-lg > .input-group-append > a.form-control-plaintext,
  body.category .blog-nav section.widget ul li
  .input-group-lg > .input-group-append > a.form-control-plaintext,
  body.search .blog-nav section.widget ul li
  .input-group-lg > .input-group-append > a.form-control-plaintext {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control, .comment-form .input-group-sm > input[type="text"],
.comment-form .input-group-sm > input[type="email"],
.comment-form .input-group-sm > input[type="url"],
.comment-form .input-group-sm > textarea, .search-form .input-group-sm > .search-field, .content-builder .gform_wrapper .input-group-sm > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group-sm > textarea,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn, .comment-form
.input-group-sm > .input-group-prepend > input[type="submit"], .search-form
.input-group-sm > .input-group-prepend > .search-submit, .content-builder .gform_wrapper .gform_footer
.input-group-sm > .input-group-prepend > input[type="submit"], body.blog .blog-nav section.widget ul li
.input-group-sm > .input-group-prepend > a,
body.category .blog-nav section.widget ul li
.input-group-sm > .input-group-prepend > a,
body.search .blog-nav section.widget ul li
.input-group-sm > .input-group-prepend > a,
.input-group-sm > .input-group-append > .btn, .comment-form
.input-group-sm > .input-group-append > input[type="submit"], .search-form
.input-group-sm > .input-group-append > .search-submit, .content-builder .gform_wrapper .gform_footer
.input-group-sm > .input-group-append > input[type="submit"], body.blog .blog-nav section.widget ul li
.input-group-sm > .input-group-append > a,
body.category .blog-nav section.widget ul li
.input-group-sm > .input-group-append > a,
body.search .blog-nav section.widget ul li
.input-group-sm > .input-group-append > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), .search-form .input-group-sm > select.search-field:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .search-form
.input-group-sm > .input-group-prepend > select.search-submit:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), .search-form
.input-group-sm > .input-group-append > select.search-submit:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control, .comment-form .input-group-lg > input[type="text"],
.comment-form .input-group-lg > input[type="email"],
.comment-form .input-group-lg > input[type="url"],
.comment-form .input-group-lg > textarea, .search-form .input-group-lg > .search-field, .content-builder .gform_wrapper .input-group-lg > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group-lg > textarea,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn, .comment-form
.input-group-lg > .input-group-prepend > input[type="submit"], .search-form
.input-group-lg > .input-group-prepend > .search-submit, .content-builder .gform_wrapper .gform_footer
.input-group-lg > .input-group-prepend > input[type="submit"], body.blog .blog-nav section.widget ul li
.input-group-lg > .input-group-prepend > a,
body.category .blog-nav section.widget ul li
.input-group-lg > .input-group-prepend > a,
body.search .blog-nav section.widget ul li
.input-group-lg > .input-group-prepend > a,
.input-group-lg > .input-group-append > .btn, .comment-form
.input-group-lg > .input-group-append > input[type="submit"], .search-form
.input-group-lg > .input-group-append > .search-submit, .content-builder .gform_wrapper .gform_footer
.input-group-lg > .input-group-append > input[type="submit"], body.blog .blog-nav section.widget ul li
.input-group-lg > .input-group-append > a,
body.category .blog-nav section.widget ul li
.input-group-lg > .input-group-append > a,
body.search .blog-nav section.widget ul li
.input-group-lg > .input-group-append > a {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .search-form .input-group-lg > select.search-field:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .search-form
.input-group-lg > .input-group-prepend > select.search-submit:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .search-form
.input-group-lg > .input-group-append > select.search-submit:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group, .comment-form p, .search-form label {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #FFFFFF;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .was-validated .comment-form input[type="text"]:valid, .comment-form .was-validated input[type="text"]:valid, .was-validated
.comment-form input[type="email"]:valid,
.comment-form .was-validated input[type="email"]:valid, .was-validated
.comment-form input[type="url"]:valid,
.comment-form .was-validated input[type="url"]:valid, .was-validated
.comment-form textarea:valid,
.comment-form .was-validated textarea:valid, .was-validated .search-form .search-field:valid, .search-form .was-validated .search-field:valid, .was-validated .content-builder .gform_wrapper input:valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .was-validated input:valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .was-validated .content-builder .gform_wrapper textarea:valid, .content-builder .gform_wrapper .was-validated textarea:valid, .form-control.is-valid, .comment-form input.is-valid[type="text"],
.comment-form input.is-valid[type="email"],
.comment-form input.is-valid[type="url"],
.comment-form textarea.is-valid, .search-form .is-valid.search-field, .content-builder .gform_wrapper input.is-valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .was-validated .comment-form input[type="text"]:valid:focus, .comment-form .was-validated input[type="text"]:valid:focus, .was-validated
  .comment-form input[type="email"]:valid:focus,
  .comment-form .was-validated input[type="email"]:valid:focus, .was-validated
  .comment-form input[type="url"]:valid:focus,
  .comment-form .was-validated input[type="url"]:valid:focus, .was-validated
  .comment-form textarea:valid:focus,
  .comment-form .was-validated textarea:valid:focus, .was-validated .search-form .search-field:valid:focus, .search-form .was-validated .search-field:valid:focus, .was-validated .content-builder .gform_wrapper input:valid:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .was-validated input:valid:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .was-validated .content-builder .gform_wrapper textarea:valid:focus, .content-builder .gform_wrapper .was-validated textarea:valid:focus, .form-control.is-valid:focus, .comment-form input.is-valid[type="text"]:focus,
  .comment-form input.is-valid[type="email"]:focus,
  .comment-form input.is-valid[type="url"]:focus,
  .comment-form textarea.is-valid:focus, .search-form .is-valid.search-field:focus, .content-builder .gform_wrapper input.is-valid:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .comment-form input[type="text"]:valid ~ .valid-feedback, .comment-form .was-validated input[type="text"]:valid ~ .valid-feedback, .was-validated
  .comment-form input[type="email"]:valid ~ .valid-feedback,
  .comment-form .was-validated input[type="email"]:valid ~ .valid-feedback, .was-validated
  .comment-form input[type="url"]:valid ~ .valid-feedback,
  .comment-form .was-validated input[type="url"]:valid ~ .valid-feedback, .was-validated
  .comment-form textarea:valid ~ .valid-feedback,
  .comment-form .was-validated textarea:valid ~ .valid-feedback, .was-validated .search-form .search-field:valid ~ .valid-feedback, .search-form .was-validated .search-field:valid ~ .valid-feedback, .was-validated .content-builder .gform_wrapper input:valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .valid-feedback, .content-builder .gform_wrapper .was-validated input:valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .valid-feedback, .was-validated .content-builder .gform_wrapper textarea:valid ~ .valid-feedback, .content-builder .gform_wrapper .was-validated textarea:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .comment-form input[type="text"]:valid ~ .valid-tooltip, .comment-form
  .was-validated input[type="text"]:valid ~ .valid-tooltip,
  .was-validated
  .comment-form input[type="email"]:valid ~ .valid-tooltip,
  .comment-form
  .was-validated input[type="email"]:valid ~ .valid-tooltip,
  .was-validated
  .comment-form input[type="url"]:valid ~ .valid-tooltip,
  .comment-form
  .was-validated input[type="url"]:valid ~ .valid-tooltip,
  .was-validated
  .comment-form textarea:valid ~ .valid-tooltip,
  .comment-form
  .was-validated textarea:valid ~ .valid-tooltip,
  .was-validated .search-form .search-field:valid ~ .valid-tooltip, .search-form
  .was-validated .search-field:valid ~ .valid-tooltip,
  .was-validated .content-builder .gform_wrapper input:valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .valid-tooltip, .content-builder .gform_wrapper
  .was-validated input:valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .valid-tooltip,
  .was-validated .content-builder .gform_wrapper textarea:valid ~ .valid-tooltip, .content-builder .gform_wrapper
  .was-validated textarea:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .comment-form input.is-valid[type="text"] ~ .valid-feedback,
  .comment-form input.is-valid[type="email"] ~ .valid-feedback,
  .comment-form input.is-valid[type="url"] ~ .valid-feedback,
  .comment-form textarea.is-valid ~ .valid-feedback, .search-form .is-valid.search-field ~ .valid-feedback, .content-builder .gform_wrapper input.is-valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .valid-feedback, .content-builder .gform_wrapper textarea.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .comment-form input.is-valid[type="text"] ~ .valid-tooltip,
  .comment-form input.is-valid[type="email"] ~ .valid-tooltip,
  .comment-form input.is-valid[type="url"] ~ .valid-tooltip,
  .comment-form textarea.is-valid ~ .valid-tooltip, .search-form .is-valid.search-field ~ .valid-tooltip, .content-builder .gform_wrapper input.is-valid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .valid-tooltip, .content-builder .gform_wrapper textarea.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #FFFFFF;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .was-validated .comment-form input[type="text"]:invalid, .comment-form .was-validated input[type="text"]:invalid, .was-validated
.comment-form input[type="email"]:invalid,
.comment-form .was-validated input[type="email"]:invalid, .was-validated
.comment-form input[type="url"]:invalid,
.comment-form .was-validated input[type="url"]:invalid, .was-validated
.comment-form textarea:invalid,
.comment-form .was-validated textarea:invalid, .was-validated .search-form .search-field:invalid, .search-form .was-validated .search-field:invalid, .was-validated .content-builder .gform_wrapper input:invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .was-validated input:invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .was-validated .content-builder .gform_wrapper textarea:invalid, .content-builder .gform_wrapper .was-validated textarea:invalid, .form-control.is-invalid, .comment-form input.is-invalid[type="text"],
.comment-form input.is-invalid[type="email"],
.comment-form input.is-invalid[type="url"],
.comment-form textarea.is-invalid, .search-form .is-invalid.search-field, .content-builder .gform_wrapper input.is-invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .was-validated .comment-form input[type="text"]:invalid:focus, .comment-form .was-validated input[type="text"]:invalid:focus, .was-validated
  .comment-form input[type="email"]:invalid:focus,
  .comment-form .was-validated input[type="email"]:invalid:focus, .was-validated
  .comment-form input[type="url"]:invalid:focus,
  .comment-form .was-validated input[type="url"]:invalid:focus, .was-validated
  .comment-form textarea:invalid:focus,
  .comment-form .was-validated textarea:invalid:focus, .was-validated .search-form .search-field:invalid:focus, .search-form .was-validated .search-field:invalid:focus, .was-validated .content-builder .gform_wrapper input:invalid:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .was-validated input:invalid:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .was-validated .content-builder .gform_wrapper textarea:invalid:focus, .content-builder .gform_wrapper .was-validated textarea:invalid:focus, .form-control.is-invalid:focus, .comment-form input.is-invalid[type="text"]:focus,
  .comment-form input.is-invalid[type="email"]:focus,
  .comment-form input.is-invalid[type="url"]:focus,
  .comment-form textarea.is-invalid:focus, .search-form .is-invalid.search-field:focus, .content-builder .gform_wrapper input.is-invalid:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .comment-form input[type="text"]:invalid ~ .invalid-feedback, .comment-form .was-validated input[type="text"]:invalid ~ .invalid-feedback, .was-validated
  .comment-form input[type="email"]:invalid ~ .invalid-feedback,
  .comment-form .was-validated input[type="email"]:invalid ~ .invalid-feedback, .was-validated
  .comment-form input[type="url"]:invalid ~ .invalid-feedback,
  .comment-form .was-validated input[type="url"]:invalid ~ .invalid-feedback, .was-validated
  .comment-form textarea:invalid ~ .invalid-feedback,
  .comment-form .was-validated textarea:invalid ~ .invalid-feedback, .was-validated .search-form .search-field:invalid ~ .invalid-feedback, .search-form .was-validated .search-field:invalid ~ .invalid-feedback, .was-validated .content-builder .gform_wrapper input:invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .invalid-feedback, .content-builder .gform_wrapper .was-validated input:invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .invalid-feedback, .was-validated .content-builder .gform_wrapper textarea:invalid ~ .invalid-feedback, .content-builder .gform_wrapper .was-validated textarea:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .comment-form input[type="text"]:invalid ~ .invalid-tooltip, .comment-form
  .was-validated input[type="text"]:invalid ~ .invalid-tooltip,
  .was-validated
  .comment-form input[type="email"]:invalid ~ .invalid-tooltip,
  .comment-form
  .was-validated input[type="email"]:invalid ~ .invalid-tooltip,
  .was-validated
  .comment-form input[type="url"]:invalid ~ .invalid-tooltip,
  .comment-form
  .was-validated input[type="url"]:invalid ~ .invalid-tooltip,
  .was-validated
  .comment-form textarea:invalid ~ .invalid-tooltip,
  .comment-form
  .was-validated textarea:invalid ~ .invalid-tooltip,
  .was-validated .search-form .search-field:invalid ~ .invalid-tooltip, .search-form
  .was-validated .search-field:invalid ~ .invalid-tooltip,
  .was-validated .content-builder .gform_wrapper input:invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .invalid-tooltip, .content-builder .gform_wrapper
  .was-validated input:invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .invalid-tooltip,
  .was-validated .content-builder .gform_wrapper textarea:invalid ~ .invalid-tooltip, .content-builder .gform_wrapper
  .was-validated textarea:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .comment-form input.is-invalid[type="text"] ~ .invalid-feedback,
  .comment-form input.is-invalid[type="email"] ~ .invalid-feedback,
  .comment-form input.is-invalid[type="url"] ~ .invalid-feedback,
  .comment-form textarea.is-invalid ~ .invalid-feedback, .search-form .is-invalid.search-field ~ .invalid-feedback, .content-builder .gform_wrapper input.is-invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .invalid-feedback, .content-builder .gform_wrapper textarea.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .comment-form input.is-invalid[type="text"] ~ .invalid-tooltip,
  .comment-form input.is-invalid[type="email"] ~ .invalid-tooltip,
  .comment-form input.is-invalid[type="url"] ~ .invalid-tooltip,
  .comment-form textarea.is-invalid ~ .invalid-tooltip, .search-form .is-invalid.search-field ~ .invalid-tooltip, .content-builder .gform_wrapper input.is-invalid:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) ~ .invalid-tooltip, .content-builder .gform_wrapper textarea.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline, .search-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .search-form .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label, .search-form label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group, .search-form .form-group, .form-inline .comment-form p, .comment-form .form-inline p, .search-form .comment-form p, .comment-form .search-form p, .form-inline .search-form label, .search-form .form-inline label, .search-form label {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .search-form .form-control, .form-inline .comment-form input[type="text"], .comment-form .form-inline input[type="text"], .search-form .comment-form input[type="text"], .comment-form .search-form input[type="text"], .form-inline
    .comment-form input[type="email"],
    .comment-form .form-inline input[type="email"], .search-form
    .comment-form input[type="email"],
    .comment-form .search-form input[type="email"], .form-inline
    .comment-form input[type="url"],
    .comment-form .form-inline input[type="url"], .search-form
    .comment-form input[type="url"],
    .comment-form .search-form input[type="url"], .form-inline
    .comment-form textarea,
    .comment-form .form-inline textarea, .search-form
    .comment-form textarea,
    .comment-form .search-form textarea, .search-form .search-field, .form-inline .content-builder .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .form-inline input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .search-form .content-builder .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .search-form input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .form-inline .content-builder .gform_wrapper textarea, .content-builder .gform_wrapper .form-inline textarea, .search-form .content-builder .gform_wrapper textarea, .content-builder .gform_wrapper .search-form textarea {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext, .search-form .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group, .search-form .input-group,
    .form-inline .custom-select, .search-form .custom-select {
      width: auto; }
    .form-inline .form-check, .search-form .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input, .search-form .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control, .search-form .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label, .search-form .custom-control-label {
      margin-bottom: 0; } }

.btn, .comment-form input[type="submit"], .search-form .search-submit, .content-builder .gform_wrapper .gform_footer input[type="submit"], body.blog .blog-nav section.widget ul li a,
body.category .blog-nav section.widget ul li a,
body.search .blog-nav section.widget ul li a {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn, .comment-form input[type="submit"], .search-form .search-submit, .content-builder .gform_wrapper .gform_footer input[type="submit"], body.blog .blog-nav section.widget ul li a,
    body.category .blog-nav section.widget ul li a,
    body.search .blog-nav section.widget ul li a {
      transition: none; } }
  .btn:hover, .comment-form input[type="submit"]:hover, .search-form .search-submit:hover, .content-builder .gform_wrapper .gform_footer input[type="submit"]:hover, body.blog .blog-nav section.widget ul li a:hover,
  body.category .blog-nav section.widget ul li a:hover,
  body.search .blog-nav section.widget ul li a:hover, .btn:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus, .content-builder .gform_wrapper .gform_footer input[type="submit"]:focus, body.blog .blog-nav section.widget ul li a:focus,
  body.category .blog-nav section.widget ul li a:focus,
  body.search .blog-nav section.widget ul li a:focus {
    text-decoration: none; }
  .btn:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus, .content-builder .gform_wrapper .gform_footer input[type="submit"]:focus, body.blog .blog-nav section.widget ul li a:focus,
  body.category .blog-nav section.widget ul li a:focus,
  body.search .blog-nav section.widget ul li a:focus, .btn.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit, .content-builder .gform_wrapper .gform_footer input.focus[type="submit"], body.blog .blog-nav section.widget ul li a.focus,
  body.category .blog-nav section.widget ul li a.focus,
  body.search .blog-nav section.widget ul li a.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .content-builder .gform_wrapper .gform_footer input.disabled[type="submit"], body.blog .blog-nav section.widget ul li a.disabled,
  body.category .blog-nav section.widget ul li a.disabled,
  body.search .blog-nav section.widget ul li a.disabled, .btn:disabled, .comment-form input[type="submit"]:disabled, .search-form .search-submit:disabled, .content-builder .gform_wrapper .gform_footer input[type="submit"]:disabled, body.blog .blog-nav section.widget ul li a:disabled,
  body.category .blog-nav section.widget ul li a:disabled,
  body.search .blog-nav section.widget ul li a:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .comment-form input[type="submit"]:not(:disabled):not(.disabled), .search-form .search-submit:not(:disabled):not(.disabled), .content-builder .gform_wrapper .gform_footer input[type="submit"]:not(:disabled):not(.disabled), body.blog .blog-nav section.widget ul li a:not(:disabled):not(.disabled),
  body.category .blog-nav section.widget ul li a:not(:disabled):not(.disabled),
  body.search .blog-nav section.widget ul li a:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .comment-form input[type="submit"]:not(:disabled):not(.disabled):active, .search-form .search-submit:not(:disabled):not(.disabled):active, .content-builder .gform_wrapper .gform_footer input[type="submit"]:not(:disabled):not(.disabled):active, body.blog .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active,
  body.category .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active,
  body.search .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .comment-form input[type="submit"]:not(:disabled):not(.disabled).active, .search-form .search-submit:not(:disabled):not(.disabled).active, .content-builder .gform_wrapper .gform_footer input[type="submit"]:not(:disabled):not(.disabled).active, body.blog .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active,
  body.category .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active,
  body.search .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled, .search-form a.disabled.search-submit, body.blog .blog-nav section.widget ul li a.disabled,
body.category .blog-nav section.widget ul li a.disabled,
body.search .blog-nav section.widget ul li a.disabled,
fieldset:disabled a.btn,
fieldset:disabled .search-form a.search-submit, .search-form
fieldset:disabled a.search-submit,
fieldset:disabled body.blog .blog-nav section.widget ul li a, body.blog .blog-nav section.widget ul li
fieldset:disabled a,
fieldset:disabled
body.category .blog-nav section.widget ul li a,
body.category .blog-nav section.widget ul li
fieldset:disabled a,
fieldset:disabled
body.search .blog-nav section.widget ul li a,
body.search .blog-nav section.widget ul li
fieldset:disabled a {
  pointer-events: none; }

.btn-primary, body.blog .blog-nav section.widget ul li a,
body.category .blog-nav section.widget ul li a,
body.search .blog-nav section.widget ul li a {
  color: #FFFFFF;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover, body.blog .blog-nav section.widget ul li a:hover,
  body.category .blog-nav section.widget ul li a:hover,
  body.search .blog-nav section.widget ul li a:hover {
    color: #FFFFFF;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, body.blog .blog-nav section.widget ul li a:focus,
  body.category .blog-nav section.widget ul li a:focus,
  body.search .blog-nav section.widget ul li a:focus, .btn-primary.focus, body.blog .blog-nav section.widget ul li a.focus,
  body.category .blog-nav section.widget ul li a.focus,
  body.search .blog-nav section.widget ul li a.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, body.blog .blog-nav section.widget ul li a.disabled,
  body.category .blog-nav section.widget ul li a.disabled,
  body.search .blog-nav section.widget ul li a.disabled, .btn-primary:disabled, body.blog .blog-nav section.widget ul li a:disabled,
  body.category .blog-nav section.widget ul li a:disabled,
  body.search .blog-nav section.widget ul li a:disabled {
    color: #FFFFFF;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, body.blog .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active,
  body.category .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active,
  body.search .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, body.blog .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active,
  body.category .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active,
  body.search .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle, body.blog .blog-nav section.widget ul li
  .show > a.dropdown-toggle,
  body.category .blog-nav section.widget ul li
  .show > a.dropdown-toggle,
  body.search .blog-nav section.widget ul li
  .show > a.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, body.blog .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active:focus,
    body.category .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active:focus,
    body.search .blog-nav section.widget ul li a:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, body.blog .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active:focus,
    body.category .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active:focus,
    body.search .blog-nav section.widget ul li a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus, body.blog .blog-nav section.widget ul li
    .show > a.dropdown-toggle:focus,
    body.category .blog-nav section.widget ul li
    .show > a.dropdown-toggle:focus,
    body.search .blog-nav section.widget ul li
    .show > a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary, .comment-form input[type="submit"], .search-form .search-submit {
  color: #FFFFFF;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover, .comment-form input[type="submit"]:hover, .search-form .search-submit:hover {
    color: #FFFFFF;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .comment-form input[type="submit"]:focus, .search-form .search-submit:focus, .btn-secondary.focus, .comment-form input.focus[type="submit"], .search-form .focus.search-submit {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .comment-form input.disabled[type="submit"], .search-form .disabled.search-submit, .btn-secondary:disabled, .comment-form input[type="submit"]:disabled, .search-form .search-submit:disabled {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .comment-form input[type="submit"]:not(:disabled):not(.disabled):active, .search-form .search-submit:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .comment-form input[type="submit"]:not(:disabled):not(.disabled).active, .search-form .search-submit:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle, .comment-form
  .show > input.dropdown-toggle[type="submit"], .search-form
  .show > .dropdown-toggle.search-submit {
    color: #FFFFFF;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .comment-form input[type="submit"]:not(:disabled):not(.disabled):active:focus, .search-form .search-submit:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .comment-form input[type="submit"]:not(:disabled):not(.disabled).active:focus, .search-form .search-submit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus, .comment-form
    .show > input.dropdown-toggle[type="submit"]:focus, .search-form
    .show > .dropdown-toggle.search-submit:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info, .content-builder .gform_wrapper .gform_footer input[type="submit"], body.blog .blog-nav section.widget ul li.current-cat a,
body.category .blog-nav section.widget ul li.current-cat a,
body.search .blog-nav section.widget ul li.current-cat a {
  color: #FFFFFF;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover, .content-builder .gform_wrapper .gform_footer input[type="submit"]:hover, body.blog .blog-nav section.widget ul li.current-cat a:hover,
  body.category .blog-nav section.widget ul li.current-cat a:hover,
  body.search .blog-nav section.widget ul li.current-cat a:hover {
    color: #FFFFFF;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .content-builder .gform_wrapper .gform_footer input[type="submit"]:focus, body.blog .blog-nav section.widget ul li.current-cat a:focus,
  body.category .blog-nav section.widget ul li.current-cat a:focus,
  body.search .blog-nav section.widget ul li.current-cat a:focus, .btn-info.focus, .content-builder .gform_wrapper .gform_footer input.focus[type="submit"], body.blog .blog-nav section.widget ul li.current-cat a.focus,
  body.category .blog-nav section.widget ul li.current-cat a.focus,
  body.search .blog-nav section.widget ul li.current-cat a.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .content-builder .gform_wrapper .gform_footer input.disabled[type="submit"], body.blog .blog-nav section.widget ul li.current-cat a.disabled,
  body.category .blog-nav section.widget ul li.current-cat a.disabled,
  body.search .blog-nav section.widget ul li.current-cat a.disabled, .btn-info:disabled, .content-builder .gform_wrapper .gform_footer input[type="submit"]:disabled, body.blog .blog-nav section.widget ul li.current-cat a:disabled,
  body.category .blog-nav section.widget ul li.current-cat a:disabled,
  body.search .blog-nav section.widget ul li.current-cat a:disabled {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .content-builder .gform_wrapper .gform_footer input[type="submit"]:not(:disabled):not(.disabled):active, body.blog .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled):active,
  body.category .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled):active,
  body.search .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .content-builder .gform_wrapper .gform_footer input[type="submit"]:not(:disabled):not(.disabled).active, body.blog .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled).active,
  body.category .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled).active,
  body.search .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle, .content-builder .gform_wrapper .gform_footer
  .show > input.dropdown-toggle[type="submit"], body.blog .blog-nav section.widget ul li.current-cat
  .show > a.dropdown-toggle,
  body.category .blog-nav section.widget ul li.current-cat
  .show > a.dropdown-toggle,
  body.search .blog-nav section.widget ul li.current-cat
  .show > a.dropdown-toggle {
    color: #FFFFFF;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .content-builder .gform_wrapper .gform_footer input[type="submit"]:not(:disabled):not(.disabled):active:focus, body.blog .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled):active:focus,
    body.category .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled):active:focus,
    body.search .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .content-builder .gform_wrapper .gform_footer input[type="submit"]:not(:disabled):not(.disabled).active:focus, body.blog .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled).active:focus,
    body.category .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled).active:focus,
    body.search .blog-nav section.widget ul li.current-cat a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus, .content-builder .gform_wrapper .gform_footer
    .show > input.dropdown-toggle[type="submit"]:focus, body.blog .blog-nav section.widget ul li.current-cat
    .show > a.dropdown-toggle:focus,
    body.category .blog-nav section.widget ul li.current-cat
    .show > a.dropdown-toggle:focus,
    body.search .blog-nav section.widget ul li.current-cat
    .show > a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .comment-form .btn-group-lg > input[type="submit"], .search-form .btn-group-lg > .search-submit, .content-builder .gform_wrapper .gform_footer .btn-group-lg > input[type="submit"], body.blog .blog-nav section.widget ul li .btn-group-lg > a,
body.category .blog-nav section.widget ul li .btn-group-lg > a,
body.search .blog-nav section.widget ul li .btn-group-lg > a {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .comment-form .btn-group-sm > input[type="submit"], .search-form .btn-group-sm > .search-submit, .content-builder .gform_wrapper .gform_footer .btn-group-sm > input[type="submit"], body.blog .blog-nav section.widget ul li .btn-group-sm > a,
body.category .blog-nav section.widget ul li .btn-group-sm > a,
body.search .blog-nav section.widget ul li .btn-group-sm > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .comment-form .btn-group > input[type="submit"], .search-form .btn-group > .search-submit, .content-builder .gform_wrapper .gform_footer .btn-group > input[type="submit"], body.blog .blog-nav section.widget ul li .btn-group > a,
  body.category .blog-nav section.widget ul li .btn-group > a,
  body.search .blog-nav section.widget ul li .btn-group > a,
  .btn-group-vertical > .btn, .comment-form
  .btn-group-vertical > input[type="submit"], .search-form
  .btn-group-vertical > .search-submit, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical > input[type="submit"], body.blog .blog-nav section.widget ul li
  .btn-group-vertical > a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical > a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical > a {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover, .comment-form .btn-group > input[type="submit"]:hover, .search-form .btn-group > .search-submit:hover, .content-builder .gform_wrapper .gform_footer .btn-group > input[type="submit"]:hover, body.blog .blog-nav section.widget ul li .btn-group > a:hover,
    body.category .blog-nav section.widget ul li .btn-group > a:hover,
    body.search .blog-nav section.widget ul li .btn-group > a:hover,
    .btn-group-vertical > .btn:hover, .comment-form
    .btn-group-vertical > input[type="submit"]:hover, .search-form
    .btn-group-vertical > .search-submit:hover, .content-builder .gform_wrapper .gform_footer
    .btn-group-vertical > input[type="submit"]:hover, body.blog .blog-nav section.widget ul li
    .btn-group-vertical > a:hover,
    body.category .blog-nav section.widget ul li
    .btn-group-vertical > a:hover,
    body.search .blog-nav section.widget ul li
    .btn-group-vertical > a:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .comment-form .btn-group > input[type="submit"]:focus, .search-form .btn-group > .search-submit:focus, .content-builder .gform_wrapper .gform_footer .btn-group > input[type="submit"]:focus, body.blog .blog-nav section.widget ul li .btn-group > a:focus,
    body.category .blog-nav section.widget ul li .btn-group > a:focus,
    body.search .blog-nav section.widget ul li .btn-group > a:focus, .btn-group > .btn:active, .comment-form .btn-group > input[type="submit"]:active, .search-form .btn-group > .search-submit:active, .content-builder .gform_wrapper .gform_footer .btn-group > input[type="submit"]:active, body.blog .blog-nav section.widget ul li .btn-group > a:active,
    body.category .blog-nav section.widget ul li .btn-group > a:active,
    body.search .blog-nav section.widget ul li .btn-group > a:active, .btn-group > .btn.active, .comment-form .btn-group > input.active[type="submit"], .search-form .btn-group > .active.search-submit, .content-builder .gform_wrapper .gform_footer .btn-group > input.active[type="submit"], body.blog .blog-nav section.widget ul li .btn-group > a.active,
    body.category .blog-nav section.widget ul li .btn-group > a.active,
    body.search .blog-nav section.widget ul li .btn-group > a.active,
    .btn-group-vertical > .btn:focus, .comment-form
    .btn-group-vertical > input[type="submit"]:focus, .search-form
    .btn-group-vertical > .search-submit:focus, .content-builder .gform_wrapper .gform_footer
    .btn-group-vertical > input[type="submit"]:focus, body.blog .blog-nav section.widget ul li
    .btn-group-vertical > a:focus,
    body.category .blog-nav section.widget ul li
    .btn-group-vertical > a:focus,
    body.search .blog-nav section.widget ul li
    .btn-group-vertical > a:focus,
    .btn-group-vertical > .btn:active, .comment-form
    .btn-group-vertical > input[type="submit"]:active, .search-form
    .btn-group-vertical > .search-submit:active, .content-builder .gform_wrapper .gform_footer
    .btn-group-vertical > input[type="submit"]:active, body.blog .blog-nav section.widget ul li
    .btn-group-vertical > a:active,
    body.category .blog-nav section.widget ul li
    .btn-group-vertical > a:active,
    body.search .blog-nav section.widget ul li
    .btn-group-vertical > a:active,
    .btn-group-vertical > .btn.active, .comment-form
    .btn-group-vertical > input.active[type="submit"], .search-form
    .btn-group-vertical > .active.search-submit, .content-builder .gform_wrapper .gform_footer
    .btn-group-vertical > input.active[type="submit"], body.blog .blog-nav section.widget ul li
    .btn-group-vertical > a.active,
    body.category .blog-nav section.widget ul li
    .btn-group-vertical > a.active,
    body.search .blog-nav section.widget ul li
    .btn-group-vertical > a.active {
      z-index: 1; }
  .btn-group .btn + .btn, .btn-group .comment-form input[type="submit"] + .btn, .comment-form .btn-group input[type="submit"] + .btn, .btn-group .search-form .search-submit + .btn, .search-form .btn-group .search-submit + .btn, .btn-group .content-builder .gform_wrapper .gform_footer input[type="submit"] + .btn, .content-builder .gform_wrapper .gform_footer .btn-group input[type="submit"] + .btn, .btn-group body.blog .blog-nav section.widget ul li a + .btn, body.blog .blog-nav section.widget ul li .btn-group a + .btn, .btn-group
  body.category .blog-nav section.widget ul li a + .btn,
  body.category .blog-nav section.widget ul li .btn-group a + .btn, .btn-group
  body.search .blog-nav section.widget ul li a + .btn,
  body.search .blog-nav section.widget ul li .btn-group a + .btn, .btn-group .comment-form .btn + input[type="submit"], .comment-form .btn-group .btn + input[type="submit"], .btn-group .comment-form input[type="submit"] + input[type="submit"], .comment-form .btn-group input[type="submit"] + input[type="submit"], .btn-group .search-form .comment-form .search-submit + input[type="submit"], .comment-form .btn-group .search-form .search-submit + input[type="submit"], .search-form .btn-group .comment-form .search-submit + input[type="submit"], .comment-form .search-form .btn-group .search-submit + input[type="submit"], .btn-group body.blog .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form .btn-group body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li .btn-group .comment-form a + input[type="submit"], .comment-form body.blog .blog-nav section.widget ul li .btn-group a + input[type="submit"], .btn-group
  body.category .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form .btn-group
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li .btn-group .comment-form a + input[type="submit"], .comment-form
  body.category .blog-nav section.widget ul li .btn-group a + input[type="submit"], .btn-group
  body.search .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form .btn-group
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li .btn-group .comment-form a + input[type="submit"], .comment-form
  body.search .blog-nav section.widget ul li .btn-group a + input[type="submit"], .btn-group .search-form .btn + .search-submit, .search-form .btn-group .btn + .search-submit, .btn-group .comment-form .search-form input[type="submit"] + .search-submit, .search-form .btn-group .comment-form input[type="submit"] + .search-submit, .comment-form .btn-group .search-form input[type="submit"] + .search-submit, .search-form .comment-form .btn-group input[type="submit"] + .search-submit, .btn-group .search-form .search-submit + .search-submit, .search-form .btn-group .search-submit + .search-submit, .btn-group .content-builder .gform_wrapper .gform_footer .search-form input[type="submit"] + .search-submit, .search-form .btn-group .content-builder .gform_wrapper .gform_footer input[type="submit"] + .search-submit, .content-builder .gform_wrapper .gform_footer .btn-group .search-form input[type="submit"] + .search-submit, .search-form .content-builder .gform_wrapper .gform_footer .btn-group input[type="submit"] + .search-submit, .btn-group body.blog .blog-nav section.widget ul li .search-form a + .search-submit, .search-form .btn-group body.blog .blog-nav section.widget ul li a + .search-submit, body.blog .blog-nav section.widget ul li .btn-group .search-form a + .search-submit, .search-form body.blog .blog-nav section.widget ul li .btn-group a + .search-submit, .btn-group
  body.category .blog-nav section.widget ul li .search-form a + .search-submit, .search-form .btn-group
  body.category .blog-nav section.widget ul li a + .search-submit,
  body.category .blog-nav section.widget ul li .btn-group .search-form a + .search-submit, .search-form
  body.category .blog-nav section.widget ul li .btn-group a + .search-submit, .btn-group
  body.search .blog-nav section.widget ul li .search-form a + .search-submit, .search-form .btn-group
  body.search .blog-nav section.widget ul li a + .search-submit,
  body.search .blog-nav section.widget ul li .btn-group .search-form a + .search-submit, .search-form
  body.search .blog-nav section.widget ul li .btn-group a + .search-submit, .btn-group .content-builder .gform_wrapper .gform_footer .btn + input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group .btn + input[type="submit"], .btn-group .search-form .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group .search-form .search-submit + input[type="submit"], .search-form .btn-group .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .search-form .btn-group .search-submit + input[type="submit"], .btn-group .content-builder .gform_wrapper .gform_footer input[type="submit"] + input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group input[type="submit"] + input[type="submit"], .btn-group body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li .btn-group .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li .btn-group a + input[type="submit"], .btn-group
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li .btn-group .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li .btn-group a + input[type="submit"], .btn-group
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li .btn-group .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li .btn-group a + input[type="submit"], .btn-group body.blog .blog-nav section.widget ul li .btn + a, body.blog .blog-nav section.widget ul li .btn-group .btn + a, .btn-group .comment-form body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .btn-group .comment-form input[type="submit"] + a, .comment-form .btn-group body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .comment-form .btn-group input[type="submit"] + a, .btn-group .search-form body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li .btn-group .search-form .search-submit + a, .search-form .btn-group body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li .search-form .btn-group .search-submit + a, .btn-group .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .btn-group .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer .btn-group body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group input[type="submit"] + a, .btn-group body.blog .blog-nav section.widget ul li a + a, body.blog .blog-nav section.widget ul li .btn-group a + a, .btn-group
  body.category .blog-nav section.widget ul li .btn + a,
  body.category .blog-nav section.widget ul li .btn-group .btn + a, .btn-group .comment-form
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .btn-group .comment-form input[type="submit"] + a, .comment-form .btn-group
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .comment-form .btn-group input[type="submit"] + a, .btn-group .search-form
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li .btn-group .search-form .search-submit + a, .search-form .btn-group
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li .search-form .btn-group .search-submit + a, .btn-group .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .btn-group .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer .btn-group
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group input[type="submit"] + a, .btn-group body.category .blog-nav section.widget ul li a + a, body.category .blog-nav section.widget ul li .btn-group a + a, .btn-group
  body.search .blog-nav section.widget ul li .btn + a,
  body.search .blog-nav section.widget ul li .btn-group .btn + a, .btn-group .comment-form
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .btn-group .comment-form input[type="submit"] + a, .comment-form .btn-group
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .comment-form .btn-group input[type="submit"] + a, .btn-group .search-form
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li .btn-group .search-form .search-submit + a, .search-form .btn-group
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li .search-form .btn-group .search-submit + a, .btn-group .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .btn-group .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer .btn-group
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group input[type="submit"] + a, .btn-group body.search .blog-nav section.widget ul li a + a, body.search .blog-nav section.widget ul li .btn-group a + a,
  .btn-group .btn + .btn-group,
  .btn-group .comment-form input[type="submit"] + .btn-group, .comment-form
  .btn-group input[type="submit"] + .btn-group,
  .btn-group .search-form .search-submit + .btn-group, .search-form
  .btn-group .search-submit + .btn-group,
  .btn-group .content-builder .gform_wrapper .gform_footer input[type="submit"] + .btn-group, .content-builder .gform_wrapper .gform_footer
  .btn-group input[type="submit"] + .btn-group,
  .btn-group body.blog .blog-nav section.widget ul li a + .btn-group, body.blog .blog-nav section.widget ul li
  .btn-group a + .btn-group,
  .btn-group
  body.category .blog-nav section.widget ul li a + .btn-group,
  body.category .blog-nav section.widget ul li
  .btn-group a + .btn-group,
  .btn-group
  body.search .blog-nav section.widget ul li a + .btn-group,
  body.search .blog-nav section.widget ul li
  .btn-group a + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .comment-form .btn-group + input[type="submit"], .comment-form
  .btn-group .btn-group + input[type="submit"],
  .btn-group .search-form .btn-group + .search-submit, .search-form
  .btn-group .btn-group + .search-submit,
  .btn-group .content-builder .gform_wrapper .gform_footer .btn-group + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group .btn-group + input[type="submit"],
  .btn-group body.blog .blog-nav section.widget ul li .btn-group + a, body.blog .blog-nav section.widget ul li
  .btn-group .btn-group + a,
  .btn-group
  body.category .blog-nav section.widget ul li .btn-group + a,
  body.category .blog-nav section.widget ul li
  .btn-group .btn-group + a,
  .btn-group
  body.search .blog-nav section.widget ul li .btn-group + a,
  body.search .blog-nav section.widget ul li
  .btn-group .btn-group + a,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .comment-form input[type="submit"] + .btn, .comment-form
  .btn-group-vertical input[type="submit"] + .btn,
  .btn-group-vertical .search-form .search-submit + .btn, .search-form
  .btn-group-vertical .search-submit + .btn,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"] + .btn, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical input[type="submit"] + .btn,
  .btn-group-vertical body.blog .blog-nav section.widget ul li a + .btn, body.blog .blog-nav section.widget ul li
  .btn-group-vertical a + .btn,
  .btn-group-vertical
  body.category .blog-nav section.widget ul li a + .btn,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical a + .btn,
  .btn-group-vertical
  body.search .blog-nav section.widget ul li a + .btn,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical a + .btn,
  .btn-group-vertical .comment-form .btn + input[type="submit"], .comment-form
  .btn-group-vertical .btn + input[type="submit"],
  .btn-group-vertical .comment-form input[type="submit"] + input[type="submit"], .comment-form
  .btn-group-vertical input[type="submit"] + input[type="submit"],
  .btn-group-vertical .search-form .comment-form .search-submit + input[type="submit"], .comment-form
  .btn-group-vertical .search-form .search-submit + input[type="submit"], .search-form
  .btn-group-vertical .comment-form .search-submit + input[type="submit"], .comment-form .search-form
  .btn-group-vertical .search-submit + input[type="submit"],
  .btn-group-vertical body.blog .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form
  .btn-group-vertical body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li
  .btn-group-vertical .comment-form a + input[type="submit"], .comment-form body.blog .blog-nav section.widget ul li
  .btn-group-vertical a + input[type="submit"],
  .btn-group-vertical
  body.category .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form
  .btn-group-vertical
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .comment-form a + input[type="submit"], .comment-form
  body.category .blog-nav section.widget ul li
  .btn-group-vertical a + input[type="submit"],
  .btn-group-vertical
  body.search .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form
  .btn-group-vertical
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .comment-form a + input[type="submit"], .comment-form
  body.search .blog-nav section.widget ul li
  .btn-group-vertical a + input[type="submit"],
  .btn-group-vertical .search-form .btn + .search-submit, .search-form
  .btn-group-vertical .btn + .search-submit,
  .btn-group-vertical .comment-form .search-form input[type="submit"] + .search-submit, .search-form
  .btn-group-vertical .comment-form input[type="submit"] + .search-submit, .comment-form
  .btn-group-vertical .search-form input[type="submit"] + .search-submit, .search-form .comment-form
  .btn-group-vertical input[type="submit"] + .search-submit,
  .btn-group-vertical .search-form .search-submit + .search-submit, .search-form
  .btn-group-vertical .search-submit + .search-submit,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer .search-form input[type="submit"] + .search-submit, .search-form
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"] + .search-submit, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical .search-form input[type="submit"] + .search-submit, .search-form .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical input[type="submit"] + .search-submit,
  .btn-group-vertical body.blog .blog-nav section.widget ul li .search-form a + .search-submit, .search-form
  .btn-group-vertical body.blog .blog-nav section.widget ul li a + .search-submit, body.blog .blog-nav section.widget ul li
  .btn-group-vertical .search-form a + .search-submit, .search-form body.blog .blog-nav section.widget ul li
  .btn-group-vertical a + .search-submit,
  .btn-group-vertical
  body.category .blog-nav section.widget ul li .search-form a + .search-submit, .search-form
  .btn-group-vertical
  body.category .blog-nav section.widget ul li a + .search-submit,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .search-form a + .search-submit, .search-form
  body.category .blog-nav section.widget ul li
  .btn-group-vertical a + .search-submit,
  .btn-group-vertical
  body.search .blog-nav section.widget ul li .search-form a + .search-submit, .search-form
  .btn-group-vertical
  body.search .blog-nav section.widget ul li a + .search-submit,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .search-form a + .search-submit, .search-form
  body.search .blog-nav section.widget ul li
  .btn-group-vertical a + .search-submit,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer .btn + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical .btn + input[type="submit"],
  .btn-group-vertical .search-form .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical .search-form .search-submit + input[type="submit"], .search-form
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .search-form
  .btn-group-vertical .search-submit + input[type="submit"],
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"] + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical input[type="submit"] + input[type="submit"],
  .btn-group-vertical body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li
  .btn-group-vertical a + input[type="submit"],
  .btn-group-vertical
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li
  .btn-group-vertical a + input[type="submit"],
  .btn-group-vertical
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li
  .btn-group-vertical a + input[type="submit"],
  .btn-group-vertical body.blog .blog-nav section.widget ul li .btn + a, body.blog .blog-nav section.widget ul li
  .btn-group-vertical .btn + a,
  .btn-group-vertical .comment-form body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li
  .btn-group-vertical .comment-form input[type="submit"] + a, .comment-form
  .btn-group-vertical body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .comment-form
  .btn-group-vertical input[type="submit"] + a,
  .btn-group-vertical .search-form body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li
  .btn-group-vertical .search-form .search-submit + a, .search-form
  .btn-group-vertical body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li .search-form
  .btn-group-vertical .search-submit + a,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical input[type="submit"] + a,
  .btn-group-vertical body.blog .blog-nav section.widget ul li a + a, body.blog .blog-nav section.widget ul li
  .btn-group-vertical a + a,
  .btn-group-vertical
  body.category .blog-nav section.widget ul li .btn + a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .btn + a,
  .btn-group-vertical .comment-form
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .comment-form input[type="submit"] + a, .comment-form
  .btn-group-vertical
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .comment-form
  .btn-group-vertical input[type="submit"] + a,
  .btn-group-vertical .search-form
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .search-form .search-submit + a, .search-form
  .btn-group-vertical
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li .search-form
  .btn-group-vertical .search-submit + a,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical input[type="submit"] + a,
  .btn-group-vertical body.category .blog-nav section.widget ul li a + a, body.category .blog-nav section.widget ul li
  .btn-group-vertical a + a,
  .btn-group-vertical
  body.search .blog-nav section.widget ul li .btn + a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .btn + a,
  .btn-group-vertical .comment-form
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .comment-form input[type="submit"] + a, .comment-form
  .btn-group-vertical
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .comment-form
  .btn-group-vertical input[type="submit"] + a,
  .btn-group-vertical .search-form
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .search-form .search-submit + a, .search-form
  .btn-group-vertical
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li .search-form
  .btn-group-vertical .search-submit + a,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical input[type="submit"] + a,
  .btn-group-vertical body.search .blog-nav section.widget ul li a + a, body.search .blog-nav section.widget ul li
  .btn-group-vertical a + a,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .comment-form input[type="submit"] + .btn-group, .comment-form
  .btn-group-vertical input[type="submit"] + .btn-group,
  .btn-group-vertical .search-form .search-submit + .btn-group, .search-form
  .btn-group-vertical .search-submit + .btn-group,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"] + .btn-group, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical input[type="submit"] + .btn-group,
  .btn-group-vertical body.blog .blog-nav section.widget ul li a + .btn-group, body.blog .blog-nav section.widget ul li
  .btn-group-vertical a + .btn-group,
  .btn-group-vertical
  body.category .blog-nav section.widget ul li a + .btn-group,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical a + .btn-group,
  .btn-group-vertical
  body.search .blog-nav section.widget ul li a + .btn-group,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical a + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .comment-form .btn-group + input[type="submit"], .comment-form
  .btn-group-vertical .btn-group + input[type="submit"],
  .btn-group-vertical .search-form .btn-group + .search-submit, .search-form
  .btn-group-vertical .btn-group + .search-submit,
  .btn-group-vertical .content-builder .gform_wrapper .gform_footer .btn-group + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical .btn-group + input[type="submit"],
  .btn-group-vertical body.blog .blog-nav section.widget ul li .btn-group + a, body.blog .blog-nav section.widget ul li
  .btn-group-vertical .btn-group + a,
  .btn-group-vertical
  body.category .blog-nav section.widget ul li .btn-group + a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical .btn-group + a,
  .btn-group-vertical
  body.search .blog-nav section.widget ul li .btn-group + a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical .btn-group + a,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child, .comment-form .btn-group > input[type="submit"]:first-child, .search-form .btn-group > .search-submit:first-child, .content-builder .gform_wrapper .gform_footer .btn-group > input[type="submit"]:first-child, body.blog .blog-nav section.widget ul li .btn-group > a:first-child,
body.category .blog-nav section.widget ul li .btn-group > a:first-child,
body.search .blog-nav section.widget ul li .btn-group > a:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form .btn-group > .search-submit:not(:last-child):not(.dropdown-toggle), .content-builder .gform_wrapper .gform_footer .btn-group > input[type="submit"]:not(:last-child):not(.dropdown-toggle), body.blog .blog-nav section.widget ul li .btn-group > a:not(:last-child):not(.dropdown-toggle),
body.category .blog-nav section.widget ul li .btn-group > a:not(:last-child):not(.dropdown-toggle),
body.search .blog-nav section.widget ul li .btn-group > a:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn, .comment-form
.btn-group > .btn-group:not(:last-child) > input[type="submit"], .search-form
.btn-group > .btn-group:not(:last-child) > .search-submit, .content-builder .gform_wrapper .gform_footer
.btn-group > .btn-group:not(:last-child) > input[type="submit"], body.blog .blog-nav section.widget ul li
.btn-group > .btn-group:not(:last-child) > a,
body.category .blog-nav section.widget ul li
.btn-group > .btn-group:not(:last-child) > a,
body.search .blog-nav section.widget ul li
.btn-group > .btn-group:not(:last-child) > a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .comment-form .btn-group > input[type="submit"]:not(:first-child), .search-form .btn-group > .search-submit:not(:first-child), .content-builder .gform_wrapper .gform_footer .btn-group > input[type="submit"]:not(:first-child), body.blog .blog-nav section.widget ul li .btn-group > a:not(:first-child),
body.category .blog-nav section.widget ul li .btn-group > a:not(:first-child),
body.search .blog-nav section.widget ul li .btn-group > a:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn, .comment-form
.btn-group > .btn-group:not(:first-child) > input[type="submit"], .search-form
.btn-group > .btn-group:not(:first-child) > .search-submit, .content-builder .gform_wrapper .gform_footer
.btn-group > .btn-group:not(:first-child) > input[type="submit"], body.blog .blog-nav section.widget ul li
.btn-group > .btn-group:not(:first-child) > a,
body.category .blog-nav section.widget ul li
.btn-group > .btn-group:not(:first-child) > a,
body.search .blog-nav section.widget ul li
.btn-group > .btn-group:not(:first-child) > a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .comment-form .btn-group-sm > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-sm > .search-submit + .dropdown-toggle-split, .content-builder .gform_wrapper .gform_footer .btn-group-sm > input[type="submit"] + .dropdown-toggle-split, body.blog .blog-nav section.widget ul li .btn-group-sm > a + .dropdown-toggle-split,
body.category .blog-nav section.widget ul li .btn-group-sm > a + .dropdown-toggle-split,
body.search .blog-nav section.widget ul li .btn-group-sm > a + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .comment-form .btn-group-lg > input[type="submit"] + .dropdown-toggle-split, .search-form .btn-group-lg > .search-submit + .dropdown-toggle-split, .content-builder .gform_wrapper .gform_footer .btn-group-lg > input[type="submit"] + .dropdown-toggle-split, body.blog .blog-nav section.widget ul li .btn-group-lg > a + .dropdown-toggle-split,
body.category .blog-nav section.widget ul li .btn-group-lg > a + .dropdown-toggle-split,
body.search .blog-nav section.widget ul li .btn-group-lg > a + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .comment-form input[type="submit"], .comment-form .btn-group-vertical input[type="submit"], .btn-group-vertical .search-form .search-submit, .search-form .btn-group-vertical .search-submit, .btn-group-vertical .content-builder .gform_wrapper .gform_footer input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group-vertical input[type="submit"], .btn-group-vertical body.blog .blog-nav section.widget ul li a, body.blog .blog-nav section.widget ul li .btn-group-vertical a, .btn-group-vertical
  body.category .blog-nav section.widget ul li a,
  body.category .blog-nav section.widget ul li .btn-group-vertical a, .btn-group-vertical
  body.search .blog-nav section.widget ul li a,
  body.search .blog-nav section.widget ul li .btn-group-vertical a,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .comment-form .btn-group-vertical > input[type="submit"] + .btn, .search-form .btn-group-vertical > .search-submit + .btn, .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"] + .btn, body.blog .blog-nav section.widget ul li .btn-group-vertical > a + .btn,
  body.category .blog-nav section.widget ul li .btn-group-vertical > a + .btn,
  body.search .blog-nav section.widget ul li .btn-group-vertical > a + .btn, .comment-form .btn-group-vertical > .btn + input[type="submit"], .comment-form .btn-group-vertical > input[type="submit"] + input[type="submit"], .search-form .comment-form .btn-group-vertical > .search-submit + input[type="submit"], .comment-form .search-form .btn-group-vertical > .search-submit + input[type="submit"], body.blog .blog-nav section.widget ul li .comment-form .btn-group-vertical > a + input[type="submit"], .comment-form body.blog .blog-nav section.widget ul li .btn-group-vertical > a + input[type="submit"],
  body.category .blog-nav section.widget ul li .comment-form .btn-group-vertical > a + input[type="submit"], .comment-form
  body.category .blog-nav section.widget ul li .btn-group-vertical > a + input[type="submit"],
  body.search .blog-nav section.widget ul li .comment-form .btn-group-vertical > a + input[type="submit"], .comment-form
  body.search .blog-nav section.widget ul li .btn-group-vertical > a + input[type="submit"], .search-form .btn-group-vertical > .btn + .search-submit, .comment-form .search-form .btn-group-vertical > input[type="submit"] + .search-submit, .search-form .comment-form .btn-group-vertical > input[type="submit"] + .search-submit, .search-form .btn-group-vertical > .search-submit + .search-submit, .content-builder .gform_wrapper .gform_footer .search-form .btn-group-vertical > input[type="submit"] + .search-submit, .search-form .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"] + .search-submit, body.blog .blog-nav section.widget ul li .search-form .btn-group-vertical > a + .search-submit, .search-form body.blog .blog-nav section.widget ul li .btn-group-vertical > a + .search-submit,
  body.category .blog-nav section.widget ul li .search-form .btn-group-vertical > a + .search-submit, .search-form
  body.category .blog-nav section.widget ul li .btn-group-vertical > a + .search-submit,
  body.search .blog-nav section.widget ul li .search-form .btn-group-vertical > a + .search-submit, .search-form
  body.search .blog-nav section.widget ul li .btn-group-vertical > a + .search-submit, .content-builder .gform_wrapper .gform_footer .btn-group-vertical > .btn + input[type="submit"], .search-form .content-builder .gform_wrapper .gform_footer .btn-group-vertical > .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .search-form .btn-group-vertical > .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"] + input[type="submit"], body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group-vertical > a + input[type="submit"], .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li .btn-group-vertical > a + input[type="submit"],
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group-vertical > a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li .btn-group-vertical > a + input[type="submit"],
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group-vertical > a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li .btn-group-vertical > a + input[type="submit"], body.blog .blog-nav section.widget ul li .btn-group-vertical > .btn + a, .comment-form body.blog .blog-nav section.widget ul li .btn-group-vertical > input[type="submit"] + a, body.blog .blog-nav section.widget ul li .comment-form .btn-group-vertical > input[type="submit"] + a, .search-form body.blog .blog-nav section.widget ul li .btn-group-vertical > .search-submit + a, body.blog .blog-nav section.widget ul li .search-form .btn-group-vertical > .search-submit + a, .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li .btn-group-vertical > input[type="submit"] + a, body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"] + a, body.blog .blog-nav section.widget ul li .btn-group-vertical > a + a,
  body.category .blog-nav section.widget ul li .btn-group-vertical > .btn + a, .comment-form
  body.category .blog-nav section.widget ul li .btn-group-vertical > input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .comment-form .btn-group-vertical > input[type="submit"] + a, .search-form
  body.category .blog-nav section.widget ul li .btn-group-vertical > .search-submit + a,
  body.category .blog-nav section.widget ul li .search-form .btn-group-vertical > .search-submit + a, .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li .btn-group-vertical > input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"] + a, body.category .blog-nav section.widget ul li .btn-group-vertical > a + a,
  body.search .blog-nav section.widget ul li .btn-group-vertical > .btn + a, .comment-form
  body.search .blog-nav section.widget ul li .btn-group-vertical > input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .comment-form .btn-group-vertical > input[type="submit"] + a, .search-form
  body.search .blog-nav section.widget ul li .btn-group-vertical > .search-submit + a,
  body.search .blog-nav section.widget ul li .search-form .btn-group-vertical > .search-submit + a, .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li .btn-group-vertical > input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"] + a, body.search .blog-nav section.widget ul li .btn-group-vertical > a + a,
  .btn-group-vertical > .btn + .btn-group, .comment-form
  .btn-group-vertical > input[type="submit"] + .btn-group, .search-form
  .btn-group-vertical > .search-submit + .btn-group, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical > input[type="submit"] + .btn-group, body.blog .blog-nav section.widget ul li
  .btn-group-vertical > a + .btn-group,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical > a + .btn-group,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical > a + .btn-group,
  .btn-group-vertical > .btn-group + .btn, .comment-form
  .btn-group-vertical > .btn-group + input[type="submit"], .search-form
  .btn-group-vertical > .btn-group + .search-submit, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical > .btn-group + input[type="submit"], body.blog .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group + a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group + a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group + a,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .comment-form .btn-group-vertical > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form .btn-group-vertical > .search-submit:not(:last-child):not(.dropdown-toggle), .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"]:not(:last-child):not(.dropdown-toggle), body.blog .blog-nav section.widget ul li .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle),
  body.category .blog-nav section.widget ul li .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle),
  body.search .blog-nav section.widget ul li .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn, .comment-form
  .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"], .search-form
  .btn-group-vertical > .btn-group:not(:last-child) > .search-submit, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"], body.blog .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group:not(:last-child) > a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group:not(:last-child) > a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group:not(:last-child) > a {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .comment-form .btn-group-vertical > input[type="submit"]:not(:first-child), .search-form .btn-group-vertical > .search-submit:not(:first-child), .content-builder .gform_wrapper .gform_footer .btn-group-vertical > input[type="submit"]:not(:first-child), body.blog .blog-nav section.widget ul li .btn-group-vertical > a:not(:first-child),
  body.category .blog-nav section.widget ul li .btn-group-vertical > a:not(:first-child),
  body.search .blog-nav section.widget ul li .btn-group-vertical > a:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn, .comment-form
  .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"], .search-form
  .btn-group-vertical > .btn-group:not(:first-child) > .search-submit, .content-builder .gform_wrapper .gform_footer
  .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"], body.blog .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group:not(:first-child) > a,
  body.category .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group:not(:first-child) > a,
  body.search .blog-nav section.widget ul li
  .btn-group-vertical > .btn-group:not(:first-child) > a {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .comment-form .btn-group-toggle > input[type="submit"], .search-form .btn-group-toggle > .search-submit, .content-builder .gform_wrapper .gform_footer .btn-group-toggle > input[type="submit"], body.blog .blog-nav section.widget ul li .btn-group-toggle > a,
body.category .blog-nav section.widget ul li .btn-group-toggle > a,
body.search .blog-nav section.widget ul li .btn-group-toggle > a,
.btn-group-toggle > .btn-group > .btn, .comment-form
.btn-group-toggle > .btn-group > input[type="submit"], .search-form
.btn-group-toggle > .btn-group > .search-submit, .content-builder .gform_wrapper .gform_footer
.btn-group-toggle > .btn-group > input[type="submit"], body.blog .blog-nav section.widget ul li
.btn-group-toggle > .btn-group > a,
body.category .blog-nav section.widget ul li
.btn-group-toggle > .btn-group > a,
body.search .blog-nav section.widget ul li
.btn-group-toggle > .btn-group > a {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .comment-form .btn-group-toggle > input[type="submit"] input[type="radio"], .search-form .btn-group-toggle > .search-submit input[type="radio"], .content-builder .gform_wrapper .gform_footer .btn-group-toggle > input[type="submit"] input[type="radio"], body.blog .blog-nav section.widget ul li .btn-group-toggle > a input[type="radio"],
  body.category .blog-nav section.widget ul li .btn-group-toggle > a input[type="radio"],
  body.search .blog-nav section.widget ul li .btn-group-toggle > a input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"], .comment-form
  .btn-group-toggle > input[type="submit"] input[type="checkbox"], .search-form
  .btn-group-toggle > .search-submit input[type="checkbox"], .content-builder .gform_wrapper .gform_footer
  .btn-group-toggle > input[type="submit"] input[type="checkbox"], body.blog .blog-nav section.widget ul li
  .btn-group-toggle > a input[type="checkbox"],
  body.category .blog-nav section.widget ul li
  .btn-group-toggle > a input[type="checkbox"],
  body.search .blog-nav section.widget ul li
  .btn-group-toggle > a input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"], .comment-form
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"], .search-form
  .btn-group-toggle > .btn-group > .search-submit input[type="radio"], .content-builder .gform_wrapper .gform_footer
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"], body.blog .blog-nav section.widget ul li
  .btn-group-toggle > .btn-group > a input[type="radio"],
  body.category .blog-nav section.widget ul li
  .btn-group-toggle > .btn-group > a input[type="radio"],
  body.search .blog-nav section.widget ul li
  .btn-group-toggle > .btn-group > a input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .comment-form
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"], .search-form
  .btn-group-toggle > .btn-group > .search-submit input[type="checkbox"], .content-builder .gform_wrapper .gform_footer
  .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"], body.blog .blog-nav section.widget ul li
  .btn-group-toggle > .btn-group > a input[type="checkbox"],
  body.category .blog-nav section.widget ul li
  .btn-group-toggle > .btn-group > a input[type="checkbox"],
  body.search .blog-nav section.widget ul li
  .btn-group-toggle > .btn-group > a input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .comment-form .input-group > input[type="text"],
  .comment-form .input-group > input[type="email"],
  .comment-form .input-group > input[type="url"],
  .comment-form .input-group > textarea, .search-form .input-group > .search-field, .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group > textarea,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus, .comment-form .input-group > input[type="text"]:focus,
    .comment-form .input-group > input[type="email"]:focus,
    .comment-form .input-group > input[type="url"]:focus,
    .comment-form .input-group > textarea:focus, .search-form .input-group > .search-field:focus, .content-builder .gform_wrapper .input-group > input:focus:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group > textarea:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control, .comment-form .input-group > input[type="text"] + .form-control,
    .comment-form .input-group > input[type="email"] + .form-control,
    .comment-form .input-group > input[type="url"] + .form-control,
    .comment-form .input-group > textarea + .form-control, .search-form .input-group > .search-field + .form-control, .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + .form-control, .content-builder .gform_wrapper .input-group > textarea + .form-control, .comment-form .input-group > .form-control + input[type="text"], .comment-form .input-group > input[type="text"] + input[type="text"], .comment-form .input-group > input[type="email"] + input[type="text"], .comment-form .input-group > input[type="url"] + input[type="text"], .comment-form .input-group > textarea + input[type="text"], .search-form .comment-form .input-group > .search-field + input[type="text"], .comment-form .search-form .input-group > .search-field + input[type="text"], .content-builder .gform_wrapper .comment-form .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + input[type="text"], .comment-form .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + input[type="text"], .comment-form .content-builder .gform_wrapper .input-group > textarea + input[type="text"],
    .comment-form .input-group > .form-control + input[type="email"], .comment-form .input-group > input[type="text"] + input[type="email"], .comment-form .input-group > input[type="email"] + input[type="email"], .comment-form .input-group > input[type="url"] + input[type="email"], .comment-form .input-group > textarea + input[type="email"], .search-form
    .comment-form .input-group > .search-field + input[type="email"],
    .comment-form .search-form .input-group > .search-field + input[type="email"], .content-builder .gform_wrapper
    .comment-form .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + input[type="email"],
    .comment-form .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + input[type="email"],
    .comment-form .content-builder .gform_wrapper .input-group > textarea + input[type="email"],
    .comment-form .input-group > .form-control + input[type="url"], .comment-form .input-group > input[type="text"] + input[type="url"], .comment-form .input-group > input[type="email"] + input[type="url"], .comment-form .input-group > input[type="url"] + input[type="url"], .comment-form .input-group > textarea + input[type="url"], .search-form
    .comment-form .input-group > .search-field + input[type="url"],
    .comment-form .search-form .input-group > .search-field + input[type="url"], .content-builder .gform_wrapper
    .comment-form .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + input[type="url"],
    .comment-form .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + input[type="url"],
    .comment-form .content-builder .gform_wrapper .input-group > textarea + input[type="url"],
    .comment-form .input-group > .form-control + textarea, .comment-form .input-group > input[type="text"] + textarea, .comment-form .input-group > input[type="email"] + textarea, .comment-form .input-group > input[type="url"] + textarea, .comment-form .input-group > textarea + textarea, .search-form
    .comment-form .input-group > .search-field + textarea,
    .comment-form .search-form .input-group > .search-field + textarea, .content-builder .gform_wrapper
    .comment-form .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + textarea, .search-form .input-group > .form-control + .search-field, .comment-form .search-form .input-group > input[type="text"] + .search-field, .search-form .comment-form .input-group > input[type="text"] + .search-field,
    .comment-form .search-form .input-group > input[type="email"] + .search-field, .search-form
    .comment-form .input-group > input[type="email"] + .search-field,
    .comment-form .search-form .input-group > input[type="url"] + .search-field, .search-form
    .comment-form .input-group > input[type="url"] + .search-field,
    .comment-form .search-form .input-group > textarea + .search-field, .search-form
    .comment-form .input-group > textarea + .search-field, .search-form .input-group > .search-field + .search-field, .content-builder .gform_wrapper .search-form .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + .search-field, .search-form .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + .search-field, .content-builder .gform_wrapper .search-form .input-group > textarea + .search-field, .search-form .content-builder .gform_wrapper .input-group > textarea + .search-field, .content-builder .gform_wrapper .input-group > .form-control + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .comment-form .content-builder .gform_wrapper .input-group > input[type="text"] + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .comment-form .input-group > input[type="text"] + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
    .comment-form .content-builder .gform_wrapper .input-group > input[type="email"] + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper
    .comment-form .input-group > input[type="email"] + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
    .comment-form .content-builder .gform_wrapper .input-group > input[type="url"] + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper
    .comment-form .input-group > input[type="url"] + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper
    .comment-form .input-group > textarea + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .search-form .content-builder .gform_wrapper .input-group > .search-field + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .search-form .input-group > .search-field + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group > textarea + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group > .form-control + textarea, .comment-form .content-builder .gform_wrapper .input-group > input[type="text"] + textarea,
    .comment-form .content-builder .gform_wrapper .input-group > input[type="email"] + textarea,
    .comment-form .content-builder .gform_wrapper .input-group > input[type="url"] + textarea, .content-builder .gform_wrapper
    .comment-form .input-group > textarea + textarea, .search-form .content-builder .gform_wrapper .input-group > .search-field + textarea, .content-builder .gform_wrapper .search-form .input-group > .search-field + textarea, .content-builder .gform_wrapper .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + textarea, .content-builder .gform_wrapper .input-group > textarea + textarea,
    .input-group > .form-control + .custom-select, .comment-form
    .input-group > input[type="text"] + .custom-select,
    .comment-form
    .input-group > input[type="email"] + .custom-select,
    .comment-form
    .input-group > input[type="url"] + .custom-select,
    .comment-form
    .input-group > textarea + .custom-select, .search-form
    .input-group > .search-field + .custom-select, .content-builder .gform_wrapper
    .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + .custom-select, .content-builder .gform_wrapper
    .input-group > textarea + .custom-select,
    .input-group > .form-control + .custom-file, .comment-form
    .input-group > input[type="text"] + .custom-file,
    .comment-form
    .input-group > input[type="email"] + .custom-file,
    .comment-form
    .input-group > input[type="url"] + .custom-file,
    .comment-form
    .input-group > textarea + .custom-file, .search-form
    .input-group > .search-field + .custom-file, .content-builder .gform_wrapper
    .input-group > input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) + .custom-file, .content-builder .gform_wrapper
    .input-group > textarea + .custom-file,
    .input-group > .custom-select + .form-control, .comment-form
    .input-group > .custom-select + input[type="text"],
    .comment-form
    .input-group > .custom-select + input[type="email"],
    .comment-form
    .input-group > .custom-select + input[type="url"],
    .comment-form
    .input-group > .custom-select + textarea, .search-form
    .input-group > .custom-select + .search-field, .content-builder .gform_wrapper
    .input-group > .custom-select + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper
    .input-group > .custom-select + textarea,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control, .comment-form
    .input-group > .custom-file + input[type="text"],
    .comment-form
    .input-group > .custom-file + input[type="email"],
    .comment-form
    .input-group > .custom-file + input[type="url"],
    .comment-form
    .input-group > .custom-file + textarea, .search-form
    .input-group > .custom-file + .search-field, .content-builder .gform_wrapper
    .input-group > .custom-file + input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper
    .input-group > .custom-file + textarea,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child), .comment-form .input-group > input[type="text"]:not(:last-child),
  .comment-form .input-group > input[type="email"]:not(:last-child),
  .comment-form .input-group > input[type="url"]:not(:last-child),
  .comment-form .input-group > textarea:not(:last-child), .search-form .input-group > .search-field:not(:last-child), .content-builder .gform_wrapper .input-group > input:not(:last-child):not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group > textarea:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .comment-form .input-group > input[type="text"]:not(:first-child),
  .comment-form .input-group > input[type="email"]:not(:first-child),
  .comment-form .input-group > input[type="url"]:not(:first-child),
  .comment-form .input-group > textarea:not(:first-child), .search-form .input-group > .search-field:not(:first-child), .content-builder .gform_wrapper .input-group > input:not(:first-child):not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper .input-group > textarea:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .comment-form input[type="submit"], .comment-form .input-group-prepend input[type="submit"], .input-group-prepend .search-form .search-submit, .search-form .input-group-prepend .search-submit, .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"], .content-builder .gform_wrapper .gform_footer .input-group-prepend input[type="submit"], .input-group-prepend body.blog .blog-nav section.widget ul li a, body.blog .blog-nav section.widget ul li .input-group-prepend a, .input-group-prepend
  body.category .blog-nav section.widget ul li a,
  body.category .blog-nav section.widget ul li .input-group-prepend a, .input-group-prepend
  body.search .blog-nav section.widget ul li a,
  body.search .blog-nav section.widget ul li .input-group-prepend a,
  .input-group-append .btn,
  .input-group-append .comment-form input[type="submit"], .comment-form
  .input-group-append input[type="submit"],
  .input-group-append .search-form .search-submit, .search-form
  .input-group-append .search-submit,
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"],
  .input-group-append body.blog .blog-nav section.widget ul li a, body.blog .blog-nav section.widget ul li
  .input-group-append a,
  .input-group-append
  body.category .blog-nav section.widget ul li a,
  body.category .blog-nav section.widget ul li
  .input-group-append a,
  .input-group-append
  body.search .blog-nav section.widget ul li a,
  body.search .blog-nav section.widget ul li
  .input-group-append a {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn, .input-group-prepend .comment-form input[type="submit"] + .btn, .comment-form .input-group-prepend input[type="submit"] + .btn, .input-group-prepend .search-form .search-submit + .btn, .search-form .input-group-prepend .search-submit + .btn, .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"] + .btn, .content-builder .gform_wrapper .gform_footer .input-group-prepend input[type="submit"] + .btn, .input-group-prepend body.blog .blog-nav section.widget ul li a + .btn, body.blog .blog-nav section.widget ul li .input-group-prepend a + .btn, .input-group-prepend
  body.category .blog-nav section.widget ul li a + .btn,
  body.category .blog-nav section.widget ul li .input-group-prepend a + .btn, .input-group-prepend
  body.search .blog-nav section.widget ul li a + .btn,
  body.search .blog-nav section.widget ul li .input-group-prepend a + .btn, .input-group-prepend .comment-form .btn + input[type="submit"], .comment-form .input-group-prepend .btn + input[type="submit"], .input-group-prepend .comment-form input[type="submit"] + input[type="submit"], .comment-form .input-group-prepend input[type="submit"] + input[type="submit"], .input-group-prepend .search-form .comment-form .search-submit + input[type="submit"], .comment-form .input-group-prepend .search-form .search-submit + input[type="submit"], .search-form .input-group-prepend .comment-form .search-submit + input[type="submit"], .comment-form .search-form .input-group-prepend .search-submit + input[type="submit"], .input-group-prepend body.blog .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form .input-group-prepend body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li .input-group-prepend .comment-form a + input[type="submit"], .comment-form body.blog .blog-nav section.widget ul li .input-group-prepend a + input[type="submit"], .input-group-prepend
  body.category .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form .input-group-prepend
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li .input-group-prepend .comment-form a + input[type="submit"], .comment-form
  body.category .blog-nav section.widget ul li .input-group-prepend a + input[type="submit"], .input-group-prepend
  body.search .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form .input-group-prepend
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li .input-group-prepend .comment-form a + input[type="submit"], .comment-form
  body.search .blog-nav section.widget ul li .input-group-prepend a + input[type="submit"], .input-group-prepend .search-form .btn + .search-submit, .search-form .input-group-prepend .btn + .search-submit, .input-group-prepend .comment-form .search-form input[type="submit"] + .search-submit, .search-form .input-group-prepend .comment-form input[type="submit"] + .search-submit, .comment-form .input-group-prepend .search-form input[type="submit"] + .search-submit, .search-form .comment-form .input-group-prepend input[type="submit"] + .search-submit, .input-group-prepend .search-form .search-submit + .search-submit, .search-form .input-group-prepend .search-submit + .search-submit, .input-group-prepend .content-builder .gform_wrapper .gform_footer .search-form input[type="submit"] + .search-submit, .search-form .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"] + .search-submit, .content-builder .gform_wrapper .gform_footer .input-group-prepend .search-form input[type="submit"] + .search-submit, .search-form .content-builder .gform_wrapper .gform_footer .input-group-prepend input[type="submit"] + .search-submit, .input-group-prepend body.blog .blog-nav section.widget ul li .search-form a + .search-submit, .search-form .input-group-prepend body.blog .blog-nav section.widget ul li a + .search-submit, body.blog .blog-nav section.widget ul li .input-group-prepend .search-form a + .search-submit, .search-form body.blog .blog-nav section.widget ul li .input-group-prepend a + .search-submit, .input-group-prepend
  body.category .blog-nav section.widget ul li .search-form a + .search-submit, .search-form .input-group-prepend
  body.category .blog-nav section.widget ul li a + .search-submit,
  body.category .blog-nav section.widget ul li .input-group-prepend .search-form a + .search-submit, .search-form
  body.category .blog-nav section.widget ul li .input-group-prepend a + .search-submit, .input-group-prepend
  body.search .blog-nav section.widget ul li .search-form a + .search-submit, .search-form .input-group-prepend
  body.search .blog-nav section.widget ul li a + .search-submit,
  body.search .blog-nav section.widget ul li .input-group-prepend .search-form a + .search-submit, .search-form
  body.search .blog-nav section.widget ul li .input-group-prepend a + .search-submit, .input-group-prepend .content-builder .gform_wrapper .gform_footer .btn + input[type="submit"], .content-builder .gform_wrapper .gform_footer .input-group-prepend .btn + input[type="submit"], .input-group-prepend .search-form .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .input-group-prepend .search-form .search-submit + input[type="submit"], .search-form .input-group-prepend .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .search-form .input-group-prepend .search-submit + input[type="submit"], .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"] + input[type="submit"], .content-builder .gform_wrapper .gform_footer .input-group-prepend input[type="submit"] + input[type="submit"], .input-group-prepend body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer .input-group-prepend body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li .input-group-prepend .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li .input-group-prepend a + input[type="submit"], .input-group-prepend
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer .input-group-prepend
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li .input-group-prepend .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li .input-group-prepend a + input[type="submit"], .input-group-prepend
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer .input-group-prepend
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li .input-group-prepend .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li .input-group-prepend a + input[type="submit"], .input-group-prepend body.blog .blog-nav section.widget ul li .btn + a, body.blog .blog-nav section.widget ul li .input-group-prepend .btn + a, .input-group-prepend .comment-form body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .input-group-prepend .comment-form input[type="submit"] + a, .comment-form .input-group-prepend body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .comment-form .input-group-prepend input[type="submit"] + a, .input-group-prepend .search-form body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li .input-group-prepend .search-form .search-submit + a, .search-form .input-group-prepend body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li .search-form .input-group-prepend .search-submit + a, .input-group-prepend .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer .input-group-prepend body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .input-group-prepend input[type="submit"] + a, .input-group-prepend body.blog .blog-nav section.widget ul li a + a, body.blog .blog-nav section.widget ul li .input-group-prepend a + a, .input-group-prepend
  body.category .blog-nav section.widget ul li .btn + a,
  body.category .blog-nav section.widget ul li .input-group-prepend .btn + a, .input-group-prepend .comment-form
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .input-group-prepend .comment-form input[type="submit"] + a, .comment-form .input-group-prepend
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .comment-form .input-group-prepend input[type="submit"] + a, .input-group-prepend .search-form
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li .input-group-prepend .search-form .search-submit + a, .search-form .input-group-prepend
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li .search-form .input-group-prepend .search-submit + a, .input-group-prepend .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer .input-group-prepend
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .input-group-prepend input[type="submit"] + a, .input-group-prepend body.category .blog-nav section.widget ul li a + a, body.category .blog-nav section.widget ul li .input-group-prepend a + a, .input-group-prepend
  body.search .blog-nav section.widget ul li .btn + a,
  body.search .blog-nav section.widget ul li .input-group-prepend .btn + a, .input-group-prepend .comment-form
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .input-group-prepend .comment-form input[type="submit"] + a, .comment-form .input-group-prepend
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .comment-form .input-group-prepend input[type="submit"] + a, .input-group-prepend .search-form
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li .input-group-prepend .search-form .search-submit + a, .search-form .input-group-prepend
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li .search-form .input-group-prepend .search-submit + a, .input-group-prepend .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer .input-group-prepend
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer .input-group-prepend input[type="submit"] + a, .input-group-prepend body.search .blog-nav section.widget ul li a + a, body.search .blog-nav section.widget ul li .input-group-prepend a + a,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .comment-form input[type="submit"] + .input-group-text, .comment-form
  .input-group-prepend input[type="submit"] + .input-group-text,
  .input-group-prepend .search-form .search-submit + .input-group-text, .search-form
  .input-group-prepend .search-submit + .input-group-text,
  .input-group-prepend .content-builder .gform_wrapper .gform_footer input[type="submit"] + .input-group-text, .content-builder .gform_wrapper .gform_footer
  .input-group-prepend input[type="submit"] + .input-group-text,
  .input-group-prepend body.blog .blog-nav section.widget ul li a + .input-group-text, body.blog .blog-nav section.widget ul li
  .input-group-prepend a + .input-group-text,
  .input-group-prepend
  body.category .blog-nav section.widget ul li a + .input-group-text,
  body.category .blog-nav section.widget ul li
  .input-group-prepend a + .input-group-text,
  .input-group-prepend
  body.search .blog-nav section.widget ul li a + .input-group-text,
  body.search .blog-nav section.widget ul li
  .input-group-prepend a + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .comment-form .input-group-text + input[type="submit"], .comment-form
  .input-group-prepend .input-group-text + input[type="submit"],
  .input-group-prepend .search-form .input-group-text + .search-submit, .search-form
  .input-group-prepend .input-group-text + .search-submit,
  .input-group-prepend .content-builder .gform_wrapper .gform_footer .input-group-text + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-prepend .input-group-text + input[type="submit"],
  .input-group-prepend body.blog .blog-nav section.widget ul li .input-group-text + a, body.blog .blog-nav section.widget ul li
  .input-group-prepend .input-group-text + a,
  .input-group-prepend
  body.category .blog-nav section.widget ul li .input-group-text + a,
  body.category .blog-nav section.widget ul li
  .input-group-prepend .input-group-text + a,
  .input-group-prepend
  body.search .blog-nav section.widget ul li .input-group-text + a,
  body.search .blog-nav section.widget ul li
  .input-group-prepend .input-group-text + a,
  .input-group-append .btn + .btn,
  .input-group-append .comment-form input[type="submit"] + .btn, .comment-form
  .input-group-append input[type="submit"] + .btn,
  .input-group-append .search-form .search-submit + .btn, .search-form
  .input-group-append .search-submit + .btn,
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"] + .btn, .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"] + .btn,
  .input-group-append body.blog .blog-nav section.widget ul li a + .btn, body.blog .blog-nav section.widget ul li
  .input-group-append a + .btn,
  .input-group-append
  body.category .blog-nav section.widget ul li a + .btn,
  body.category .blog-nav section.widget ul li
  .input-group-append a + .btn,
  .input-group-append
  body.search .blog-nav section.widget ul li a + .btn,
  body.search .blog-nav section.widget ul li
  .input-group-append a + .btn,
  .input-group-append .comment-form .btn + input[type="submit"], .comment-form
  .input-group-append .btn + input[type="submit"],
  .input-group-append .comment-form input[type="submit"] + input[type="submit"], .comment-form
  .input-group-append input[type="submit"] + input[type="submit"],
  .input-group-append .search-form .comment-form .search-submit + input[type="submit"], .comment-form
  .input-group-append .search-form .search-submit + input[type="submit"], .search-form
  .input-group-append .comment-form .search-submit + input[type="submit"], .comment-form .search-form
  .input-group-append .search-submit + input[type="submit"],
  .input-group-append body.blog .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form
  .input-group-append body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li
  .input-group-append .comment-form a + input[type="submit"], .comment-form body.blog .blog-nav section.widget ul li
  .input-group-append a + input[type="submit"],
  .input-group-append
  body.category .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form
  .input-group-append
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li
  .input-group-append .comment-form a + input[type="submit"], .comment-form
  body.category .blog-nav section.widget ul li
  .input-group-append a + input[type="submit"],
  .input-group-append
  body.search .blog-nav section.widget ul li .comment-form a + input[type="submit"], .comment-form
  .input-group-append
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li
  .input-group-append .comment-form a + input[type="submit"], .comment-form
  body.search .blog-nav section.widget ul li
  .input-group-append a + input[type="submit"],
  .input-group-append .search-form .btn + .search-submit, .search-form
  .input-group-append .btn + .search-submit,
  .input-group-append .comment-form .search-form input[type="submit"] + .search-submit, .search-form
  .input-group-append .comment-form input[type="submit"] + .search-submit, .comment-form
  .input-group-append .search-form input[type="submit"] + .search-submit, .search-form .comment-form
  .input-group-append input[type="submit"] + .search-submit,
  .input-group-append .search-form .search-submit + .search-submit, .search-form
  .input-group-append .search-submit + .search-submit,
  .input-group-append .content-builder .gform_wrapper .gform_footer .search-form input[type="submit"] + .search-submit, .search-form
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"] + .search-submit, .content-builder .gform_wrapper .gform_footer
  .input-group-append .search-form input[type="submit"] + .search-submit, .search-form .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"] + .search-submit,
  .input-group-append body.blog .blog-nav section.widget ul li .search-form a + .search-submit, .search-form
  .input-group-append body.blog .blog-nav section.widget ul li a + .search-submit, body.blog .blog-nav section.widget ul li
  .input-group-append .search-form a + .search-submit, .search-form body.blog .blog-nav section.widget ul li
  .input-group-append a + .search-submit,
  .input-group-append
  body.category .blog-nav section.widget ul li .search-form a + .search-submit, .search-form
  .input-group-append
  body.category .blog-nav section.widget ul li a + .search-submit,
  body.category .blog-nav section.widget ul li
  .input-group-append .search-form a + .search-submit, .search-form
  body.category .blog-nav section.widget ul li
  .input-group-append a + .search-submit,
  .input-group-append
  body.search .blog-nav section.widget ul li .search-form a + .search-submit, .search-form
  .input-group-append
  body.search .blog-nav section.widget ul li a + .search-submit,
  body.search .blog-nav section.widget ul li
  .input-group-append .search-form a + .search-submit, .search-form
  body.search .blog-nav section.widget ul li
  .input-group-append a + .search-submit,
  .input-group-append .content-builder .gform_wrapper .gform_footer .btn + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append .btn + input[type="submit"],
  .input-group-append .search-form .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append .search-form .search-submit + input[type="submit"], .search-form
  .input-group-append .content-builder .gform_wrapper .gform_footer .search-submit + input[type="submit"], .content-builder .gform_wrapper .gform_footer .search-form
  .input-group-append .search-submit + input[type="submit"],
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"] + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"] + input[type="submit"],
  .input-group-append body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append body.blog .blog-nav section.widget ul li a + input[type="submit"], body.blog .blog-nav section.widget ul li
  .input-group-append .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li
  .input-group-append a + input[type="submit"],
  .input-group-append
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append
  body.category .blog-nav section.widget ul li a + input[type="submit"],
  body.category .blog-nav section.widget ul li
  .input-group-append .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li
  .input-group-append a + input[type="submit"],
  .input-group-append
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append
  body.search .blog-nav section.widget ul li a + input[type="submit"],
  body.search .blog-nav section.widget ul li
  .input-group-append .content-builder .gform_wrapper .gform_footer a + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li
  .input-group-append a + input[type="submit"],
  .input-group-append body.blog .blog-nav section.widget ul li .btn + a, body.blog .blog-nav section.widget ul li
  .input-group-append .btn + a,
  .input-group-append .comment-form body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li
  .input-group-append .comment-form input[type="submit"] + a, .comment-form
  .input-group-append body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .comment-form
  .input-group-append input[type="submit"] + a,
  .input-group-append .search-form body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li
  .input-group-append .search-form .search-submit + a, .search-form
  .input-group-append body.blog .blog-nav section.widget ul li .search-submit + a, body.blog .blog-nav section.widget ul li .search-form
  .input-group-append .search-submit + a,
  .input-group-append .content-builder .gform_wrapper .gform_footer body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer
  .input-group-append body.blog .blog-nav section.widget ul li input[type="submit"] + a, body.blog .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"] + a,
  .input-group-append body.blog .blog-nav section.widget ul li a + a, body.blog .blog-nav section.widget ul li
  .input-group-append a + a,
  .input-group-append
  body.category .blog-nav section.widget ul li .btn + a,
  body.category .blog-nav section.widget ul li
  .input-group-append .btn + a,
  .input-group-append .comment-form
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li
  .input-group-append .comment-form input[type="submit"] + a, .comment-form
  .input-group-append
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .comment-form
  .input-group-append input[type="submit"] + a,
  .input-group-append .search-form
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li
  .input-group-append .search-form .search-submit + a, .search-form
  .input-group-append
  body.category .blog-nav section.widget ul li .search-submit + a,
  body.category .blog-nav section.widget ul li .search-form
  .input-group-append .search-submit + a,
  .input-group-append .content-builder .gform_wrapper .gform_footer
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer
  .input-group-append
  body.category .blog-nav section.widget ul li input[type="submit"] + a,
  body.category .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"] + a,
  .input-group-append body.category .blog-nav section.widget ul li a + a, body.category .blog-nav section.widget ul li
  .input-group-append a + a,
  .input-group-append
  body.search .blog-nav section.widget ul li .btn + a,
  body.search .blog-nav section.widget ul li
  .input-group-append .btn + a,
  .input-group-append .comment-form
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li
  .input-group-append .comment-form input[type="submit"] + a, .comment-form
  .input-group-append
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .comment-form
  .input-group-append input[type="submit"] + a,
  .input-group-append .search-form
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li
  .input-group-append .search-form .search-submit + a, .search-form
  .input-group-append
  body.search .blog-nav section.widget ul li .search-submit + a,
  body.search .blog-nav section.widget ul li .search-form
  .input-group-append .search-submit + a,
  .input-group-append .content-builder .gform_wrapper .gform_footer
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"] + a, .content-builder .gform_wrapper .gform_footer
  .input-group-append
  body.search .blog-nav section.widget ul li input[type="submit"] + a,
  body.search .blog-nav section.widget ul li .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"] + a,
  .input-group-append body.search .blog-nav section.widget ul li a + a, body.search .blog-nav section.widget ul li
  .input-group-append a + a,
  .input-group-append .btn + .input-group-text,
  .input-group-append .comment-form input[type="submit"] + .input-group-text, .comment-form
  .input-group-append input[type="submit"] + .input-group-text,
  .input-group-append .search-form .search-submit + .input-group-text, .search-form
  .input-group-append .search-submit + .input-group-text,
  .input-group-append .content-builder .gform_wrapper .gform_footer input[type="submit"] + .input-group-text, .content-builder .gform_wrapper .gform_footer
  .input-group-append input[type="submit"] + .input-group-text,
  .input-group-append body.blog .blog-nav section.widget ul li a + .input-group-text, body.blog .blog-nav section.widget ul li
  .input-group-append a + .input-group-text,
  .input-group-append
  body.category .blog-nav section.widget ul li a + .input-group-text,
  body.category .blog-nav section.widget ul li
  .input-group-append a + .input-group-text,
  .input-group-append
  body.search .blog-nav section.widget ul li a + .input-group-text,
  body.search .blog-nav section.widget ul li
  .input-group-append a + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .comment-form .input-group-text + input[type="submit"], .comment-form
  .input-group-append .input-group-text + input[type="submit"],
  .input-group-append .search-form .input-group-text + .search-submit, .search-form
  .input-group-append .input-group-text + .search-submit,
  .input-group-append .content-builder .gform_wrapper .gform_footer .input-group-text + input[type="submit"], .content-builder .gform_wrapper .gform_footer
  .input-group-append .input-group-text + input[type="submit"],
  .input-group-append body.blog .blog-nav section.widget ul li .input-group-text + a, body.blog .blog-nav section.widget ul li
  .input-group-append .input-group-text + a,
  .input-group-append
  body.category .blog-nav section.widget ul li .input-group-text + a,
  body.category .blog-nav section.widget ul li
  .input-group-append .input-group-text + a,
  .input-group-append
  body.search .blog-nav section.widget ul li .input-group-text + a,
  body.search .blog-nav section.widget ul li
  .input-group-append .input-group-text + a {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn, .comment-form .input-group > .input-group-prepend > input[type="submit"], .search-form .input-group > .input-group-prepend > .search-submit, .content-builder .gform_wrapper .gform_footer .input-group > .input-group-prepend > input[type="submit"], body.blog .blog-nav section.widget ul li .input-group > .input-group-prepend > a,
body.category .blog-nav section.widget ul li .input-group > .input-group-prepend > a,
body.search .blog-nav section.widget ul li .input-group > .input-group-prepend > a,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn, .comment-form
.input-group > .input-group-append:not(:last-child) > input[type="submit"], .search-form
.input-group > .input-group-append:not(:last-child) > .search-submit, .content-builder .gform_wrapper .gform_footer
.input-group > .input-group-append:not(:last-child) > input[type="submit"], body.blog .blog-nav section.widget ul li
.input-group > .input-group-append:not(:last-child) > a,
body.category .blog-nav section.widget ul li
.input-group > .input-group-append:not(:last-child) > a,
body.search .blog-nav section.widget ul li
.input-group > .input-group-append:not(:last-child) > a,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .comment-form
.input-group > .input-group-append:last-child > input[type="submit"]:not(:last-child):not(.dropdown-toggle), .search-form
.input-group > .input-group-append:last-child > .search-submit:not(:last-child):not(.dropdown-toggle), .content-builder .gform_wrapper .gform_footer
.input-group > .input-group-append:last-child > input[type="submit"]:not(:last-child):not(.dropdown-toggle), body.blog .blog-nav section.widget ul li
.input-group > .input-group-append:last-child > a:not(:last-child):not(.dropdown-toggle),
body.category .blog-nav section.widget ul li
.input-group > .input-group-append:last-child > a:not(:last-child):not(.dropdown-toggle),
body.search .blog-nav section.widget ul li
.input-group > .input-group-append:last-child > a:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .comment-form .input-group > .input-group-append > input[type="submit"], .search-form .input-group > .input-group-append > .search-submit, .content-builder .gform_wrapper .gform_footer .input-group > .input-group-append > input[type="submit"], body.blog .blog-nav section.widget ul li .input-group > .input-group-append > a,
body.category .blog-nav section.widget ul li .input-group > .input-group-append > a,
body.search .blog-nav section.widget ul li .input-group > .input-group-append > a,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn, .comment-form
.input-group > .input-group-prepend:not(:first-child) > input[type="submit"], .search-form
.input-group > .input-group-prepend:not(:first-child) > .search-submit, .content-builder .gform_wrapper .gform_footer
.input-group > .input-group-prepend:not(:first-child) > input[type="submit"], body.blog .blog-nav section.widget ul li
.input-group > .input-group-prepend:not(:first-child) > a,
body.category .blog-nav section.widget ul li
.input-group > .input-group-prepend:not(:first-child) > a,
body.search .blog-nav section.widget ul li
.input-group > .input-group-prepend:not(:first-child) > a,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), .comment-form
.input-group > .input-group-prepend:first-child > input[type="submit"]:not(:first-child), .search-form
.input-group > .input-group-prepend:first-child > .search-submit:not(:first-child), .content-builder .gform_wrapper .gform_footer
.input-group > .input-group-prepend:first-child > input[type="submit"]:not(:first-child), body.blog .blog-nav section.widget ul li
.input-group > .input-group-prepend:first-child > a:not(:first-child),
body.category .blog-nav section.widget ul li
.input-group > .input-group-prepend:first-child > a:not(:first-child),
body.search .blog-nav section.widget ul li
.input-group > .input-group-prepend:first-child > a:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFFFFF' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #dee2e6 #dee2e6 #FFFFFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge, .comment-form input[type="submit"] .badge, .search-form .search-submit .badge, .content-builder .gform_wrapper .gform_footer input[type="submit"] .badge, body.blog .blog-nav section.widget ul li a .badge,
body.category .blog-nav section.widget ul li a .badge,
body.search .blog-nav section.widget ul li a .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #FFFFFF;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #FFFFFF;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #FFFFFF;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #FFFFFF;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #FFFFFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #FFFFFF; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .screen-reader-text:active, .sr-only-focusable:focus, .screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center, header.banner .header-bar {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.comment-form input[type="submit"]):not(.search-form .search-submit):not(.content-builder .gform_wrapper .gform_footer input[type="submit"]):not(body.blog .blog-nav section.widget ul li a):not(
  body.category .blog-nav section.widget ul li a):not(
  body.search .blog-nav section.widget ul li a) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

body {
  font-family: "Roboto Slab", serif; }

a {
  color: #5C483A;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  a:hover {
    color: #B3D8EF;
    text-decoration: none; }

h2, h3 {
  color: #5C483A; }

p {
  color: #5C483A;
  font-weight: 400; }

.mm-menu {
  background: #F1EEDC !important; }
  .mm-menu .mm-navbar {
    border-bottom: none;
    padding: 0 3rem;
    height: auto; }
    .mm-menu .mm-navbar a {
      color: #5C483A !important;
      font-weight: 100;
      font-size: 1.5rem;
      line-height: 1.5rem;
      padding: 2rem 0 1rem;
      text-align: left; }
  .mm-menu .mm-listview > li:not(.mm-divider):after {
    border-bottom-color: transparent; }
  .mm-menu .mm-listview {
    margin-top: 1rem !important; }
    .mm-menu .mm-listview li a {
      font-weight: 400;
      color: #5C483A;
      padding: 1rem 2rem 1rem 3rem;
      text-transform: uppercase;
      position: relative; }
    .mm-menu .mm-listview li.active a:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f1b0";
      position: absolute;
      top: 50%;
      left: 1rem;
      color: #B3D8EF;
      font-size: 1.2rem;
      margin-top: -10px; }

.btn.btn, .comment-form input[type="submit"], .search-form .search-submit, .content-builder .gform_wrapper .gform_footer input[type="submit"], body.blog .blog-nav section.widget ul li a,
body.category .blog-nav section.widget ul li a,
body.search .blog-nav section.widget ul li a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  padding: .4rem 1rem .5rem;
  font-size: .9rem; }

.btn.btn-primary, .comment-form input.btn-primary[type="submit"], .search-form .btn-primary.search-submit, .content-builder .gform_wrapper .gform_footer input.btn-primary[type="submit"], body.blog .blog-nav section.widget ul li a,
body.category .blog-nav section.widget ul li a,
body.search .blog-nav section.widget ul li a {
  background: #b3d8ef;
  border-color: #FFFFFF;
  font-weight: 700;
  color: #FFFFFF; }
  .btn.btn-primary:hover, .comment-form input.btn-primary[type="submit"]:hover, .search-form .btn-primary.search-submit:hover, .content-builder .gform_wrapper .gform_footer input.btn-primary[type="submit"]:hover, body.blog .blog-nav section.widget ul li a:hover,
  body.category .blog-nav section.widget ul li a:hover,
  body.search .blog-nav section.widget ul li a:hover {
    background: #5C483A;
    color: #B3D8EF; }

.btn.btn-secondary, .comment-form input[type="submit"], .search-form .search-submit, .content-builder .gform_wrapper .gform_footer input.btn-secondary[type="submit"], .content-builder .gform_wrapper .gform_footer .comment-form input[type="submit"], .comment-form .content-builder .gform_wrapper .gform_footer input[type="submit"], .content-builder .gform_wrapper .gform_footer .search-form input[type="submit"].search-submit, .search-form .content-builder .gform_wrapper .gform_footer input[type="submit"].search-submit, body.blog .blog-nav section.widget ul li a.btn-secondary, body.blog .blog-nav section.widget ul li .search-form a.search-submit, .search-form body.blog .blog-nav section.widget ul li a.search-submit,
body.category .blog-nav section.widget ul li a.btn-secondary,
body.category .blog-nav section.widget ul li .search-form a.search-submit, .search-form
body.category .blog-nav section.widget ul li a.search-submit,
body.search .blog-nav section.widget ul li a.btn-secondary,
body.search .blog-nav section.widget ul li .search-form a.search-submit, .search-form
body.search .blog-nav section.widget ul li a.search-submit {
  background: #F1EEDC;
  border: 1px solid #5C483A;
  color: #5C483A; }
  .btn.btn-secondary:hover, .comment-form input[type="submit"]:hover, .search-form .search-submit:hover, .content-builder .gform_wrapper .gform_footer input.btn-secondary[type="submit"]:hover, .content-builder .gform_wrapper .gform_footer .comment-form input[type="submit"]:hover, .comment-form .content-builder .gform_wrapper .gform_footer input[type="submit"]:hover, .content-builder .gform_wrapper .gform_footer .search-form input[type="submit"].search-submit:hover, .search-form .content-builder .gform_wrapper .gform_footer input[type="submit"].search-submit:hover, body.blog .blog-nav section.widget ul li a.btn-secondary:hover, body.blog .blog-nav section.widget ul li .search-form a.search-submit:hover, .search-form body.blog .blog-nav section.widget ul li a.search-submit:hover,
  body.category .blog-nav section.widget ul li a.btn-secondary:hover,
  body.category .blog-nav section.widget ul li .search-form a.search-submit:hover, .search-form
  body.category .blog-nav section.widget ul li a.search-submit:hover,
  body.search .blog-nav section.widget ul li a.btn-secondary:hover,
  body.search .blog-nav section.widget ul li .search-form a.search-submit:hover, .search-form
  body.search .blog-nav section.widget ul li a.search-submit:hover {
    background: #5C483A;
    color: #FFFFFF; }

.btn.btn-info, .comment-form input.btn-info[type="submit"], .search-form .btn-info.search-submit, .content-builder .gform_wrapper .gform_footer input[type="submit"], body.blog .blog-nav section.widget ul li a.btn-info, body.blog .blog-nav section.widget ul li.current-cat a,
body.category .blog-nav section.widget ul li a.btn-info, body.category .blog-nav section.widget ul li.current-cat a,
body.search .blog-nav section.widget ul li a.btn-info, body.search .blog-nav section.widget ul li.current-cat a {
  background: #5C483A;
  color: #B3D8EF;
  border: 1px solid #B3D8EF;
  padding: .3rem 1rem .4rem; }
  .btn.btn-info i, .comment-form input.btn-info[type="submit"] i, .search-form .btn-info.search-submit i, .content-builder .gform_wrapper .gform_footer input[type="submit"] i, body.blog .blog-nav section.widget ul li a.btn-info i, body.blog .blog-nav section.widget ul li.current-cat a i,
  body.category .blog-nav section.widget ul li a.btn-info i, body.category .blog-nav section.widget ul li.current-cat a i,
  body.search .blog-nav section.widget ul li a.btn-info i, body.search .blog-nav section.widget ul li.current-cat a i {
    margin-left: .3rem; }
  .btn.btn-info:hover, .comment-form input.btn-info[type="submit"]:hover, .search-form .btn-info.search-submit:hover, .content-builder .gform_wrapper .gform_footer input[type="submit"]:hover, body.blog .blog-nav section.widget ul li a.btn-info:hover, body.blog .blog-nav section.widget ul li.current-cat a:hover,
  body.category .blog-nav section.widget ul li a.btn-info:hover, body.category .blog-nav section.widget ul li.current-cat a:hover,
  body.search .blog-nav section.widget ul li a.btn-info:hover, body.search .blog-nav section.widget ul li.current-cat a:hover {
    background: #B3D8EF;
    border: 1px solid #B3D8EF;
    color: #FFFFFF; }

.btn.btn-white-outline, .comment-form input.btn-white-outline[type="submit"], .search-form .btn-white-outline.search-submit, .content-builder .gform_wrapper .gform_footer input.btn-white-outline[type="submit"], body.blog .blog-nav section.widget ul li a.btn-white-outline,
body.category .blog-nav section.widget ul li a.btn-white-outline,
body.search .blog-nav section.widget ul li a.btn-white-outline {
  background: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: .4rem 1rem .4rem;
  font-size: .8rem;
  font-weight: 700;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .btn.btn-white-outline i, .comment-form input.btn-white-outline[type="submit"] i, .search-form .btn-white-outline.search-submit i, .content-builder .gform_wrapper .gform_footer input.btn-white-outline[type="submit"] i, body.blog .blog-nav section.widget ul li a.btn-white-outline i,
  body.category .blog-nav section.widget ul li a.btn-white-outline i,
  body.search .blog-nav section.widget ul li a.btn-white-outline i {
    margin-left: .3rem; }
  .btn.btn-white-outline:hover, .comment-form input.btn-white-outline[type="submit"]:hover, .search-form .btn-white-outline.search-submit:hover, .content-builder .gform_wrapper .gform_footer input.btn-white-outline[type="submit"]:hover, body.blog .blog-nav section.widget ul li a.btn-white-outline:hover,
  body.category .blog-nav section.widget ul li a.btn-white-outline:hover,
  body.search .blog-nav section.widget ul li a.btn-white-outline:hover {
    background: #FFFFFF;
    color: #5C483A; }

.comment-list ol {
  list-style: none; }

.form-control, .comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea, .search-form .search-field, .content-builder .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .content-builder .gform_wrapper textarea {
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px; }

.search-form label {
  font-weight: normal; }

.search-form .search-field {
  border: 1px solid #5C483A; }

.search-form .search-submit {
  display: none; }

.content-builder .gform_wrapper {
  margin: 0; }
  .content-builder .gform_wrapper .gform_body {
    margin-left: -15px;
    margin-right: -15px; }
  .content-builder .gform_wrapper ul.gform_fields li.gfield {
    padding-right: .5rem;
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: .5rem; }
    .content-builder .gform_wrapper ul.gform_fields li.gfield:before {
      display: none !important; }
    .content-builder .gform_wrapper ul.gform_fields li.gfield.no-label .gfield_label {
      display: none; }
  .content-builder .gform_wrapper .top_label div.ginput_container {
    margin-top: 0; }
  .content-builder .gform_wrapper textarea {
    min-height: 150px; }
  .content-builder .gform_wrapper .gform_footer {
    padding: 1rem 0 0;
    margin: 0; }

.main {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .main {
      flex: 0 0 100%;
      max-width: 100%; }
      .sidebar-primary .main {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; } }

.sidebar {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .sidebar {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; } }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 0.5rem auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 0.5rem;
  height: auto; }

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 0.5rem; }
  .alignright {
    float: right;
    margin-left: 0.5rem; } }

.mm-hidden {
  display: none !important; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.mm-panels {
  overflow: hidden; }

.mm-panel {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left; }

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0); }

.mm-panel.mm-highest {
  z-index: 1; }

.mm-panel.mm-noanimation {
  -webkit-transition: none !important;
  transition: none !important; }

.mm-panel.mm-noanimation.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px; }

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px; }

.mm-panels > .mm-panel:not(.mm-hidden) {
  display: block; }

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  content: '';
  display: block;
  height: 20px; }

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important; }

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent; }

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block; }

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  box-sizing: border-box;
  height: 40px;
  bottom: auto; }

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px; }

.mm-btn {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: 1; }

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px; }

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px; }

.mm-next:after, .mm-prev:before {
  content: '';
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0; }

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto; }

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto; }

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.mm-navbar > * {
  display: block;
  padding: 10px 0; }

.mm-navbar a, .mm-navbar a:hover {
  text-decoration: none; }

.mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mm-navbar .mm-btn:first-child {
  left: 0; }

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0; }

.mm-panel .mm-navbar {
  display: none; }

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block; }

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px; }

.mm-listview a, .mm-listview a:hover {
  text-decoration: none; }

.mm-listview > li {
  position: relative; }

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit; }

.mm-listview > li > a, .mm-listview > li > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 0; }

.mm-listview > li:not(.mm-divider):after {
  content: '';
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

.mm-listview > li:not(.mm-divider):after {
  left: 20px; }

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2; }

.mm-listview .mm-next:before {
  content: '';
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0; }

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
  margin-right: 50px; }

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%; }

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none; }

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0; }

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px; }

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px; }

.mm-menu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75); }

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu .mm-listview {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5); }

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.5); }

.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu .mm-divider {
  background: rgba(0, 0, 0, 0.05); }

.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  z-index: 1; }

html.mm-opened {
  overflow-x: hidden;
  position: relative; }

html.mm-blocking {
  overflow: hidden; }

html.mm-blocking body {
  overflow: hidden; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2; }

html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed; }

.mm-menu.mm-offcanvas.mm-opened {
  display: block; }

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
  z-index: 10; }

.mm-menu.mm-offcanvas {
  width: 80%;
  min-width: 140px;
  max-width: 440px; }

html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0); } }

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important; }

.mm-menu .mm-listview.mm-border-none > li:after, .mm-menu .mm-listview > li.mm-border-none:after, .mm-menu.mm-border-none .mm-listview > li:after {
  content: none; }

.mm-menu .mm-listview.mm-border-full > li:after, .mm-menu .mm-listview > li.mm-border-full:after, .mm-menu.mm-border-full .mm-listview > li:after {
  left: 0 !important; }

.mm-menu .mm-listview.mm-border-offset > li:after, .mm-menu .mm-listview > li.mm-border-offset:after, .mm-menu.mm-border-offset .mm-listview > li:after {
  right: 20px; }

.mm-menu.mm-offcanvas.mm-fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px; }

html.mm-opening .mm-menu.mm-fullscreen.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

@media all and (max-width: 140px) {
  html.mm-opening .mm-menu.mm-fullscreen.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 10000px) {
  html.mm-opening .mm-menu.mm-fullscreen.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(10000px, 0);
    -ms-transform: translate(10000px, 0);
    transform: translate(10000px, 0);
    -webkit-transform: translate3d(10000px, 0, 0);
    transform: translate3d(10000px, 0, 0); } }

html.mm-right.mm-opening .mm-menu.mm-fullscreen.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

@media all and (max-width: 140px) {
  html.mm-right.mm-opening .mm-menu.mm-fullscreen.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 10000px) {
  html.mm-right.mm-opening .mm-menu.mm-fullscreen.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-10000px, 0);
    -ms-transform: translate(-10000px, 0);
    transform: translate(-10000px, 0);
    -webkit-transform: translate3d(-10000px, 0, 0);
    transform: translate3d(-10000px, 0, 0); } }

.mm-menu.mm-offcanvas.mm-fullscreen.mm-bottom, .mm-menu.mm-offcanvas.mm-fullscreen.mm-top {
  height: 100%;
  min-height: 140px;
  max-height: 10000px; }

.mm-menu.mm-fx-menu-zoom {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease; }

html.mm-opened .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center; }

html.mm-opening .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0); }

html.mm-right.mm-opened .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center; }

html.mm-right.mm-opening .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0); }

.mm-menu.mm-fx-menu-slide {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease; }

html.mm-opened .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0); }

html.mm-opening .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

html.mm-right.mm-opened .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0); }

html.mm-right.mm-opening .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu.mm-fx-menu-fade {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease; }

html.mm-opening .mm-menu.mm-fx-menu-fade {
  opacity: 1; }

.mm-menu .mm-fx-panels-none.mm-panel, .mm-menu.mm-fx-panels-none .mm-panel {
  -webkit-transition-property: none;
  transition-property: none; }

.mm-menu .mm-fx-panels-none.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-none .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu .mm-fx-panels-zoom.mm-panel, .mm-menu.mm-fx-panels-zoom .mm-panel {
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  transform: scale(1.5, 1.5) translate3d(100%, 0, 0); }

.mm-menu .mm-fx-panels-zoom.mm-panel.mm-opened, .mm-menu.mm-fx-panels-zoom .mm-panel.mm-opened {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0); }

.mm-menu .mm-fx-panels-zoom.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-zoom .mm-panel.mm-subopened {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0); }

.mm-menu .mm-fx-panels-slide-0.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-0 .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu .mm-fx-panels-slide-100.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-100 .mm-panel.mm-subopened {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.mm-menu .mm-fx-panels-slide-up.mm-panel, .mm-menu.mm-fx-panels-slide-up .mm-panel {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

.mm-menu .mm-fx-panels-slide-up.mm-panel.mm-opened, .mm-menu.mm-fx-panels-slide-up .mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu .mm-fx-panels-slide-up.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-up .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li {
  -webkit-transition: none .4s ease;
  transition: none .4s ease; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(1) {
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(2) {
  -webkit-transition-delay: .1s;
  transition-delay: .1s; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(3) {
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(4) {
  -webkit-transition-delay: .2s;
  transition-delay: .2s; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(5) {
  -webkit-transition-delay: 250ms;
  transition-delay: 250ms; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(6) {
  -webkit-transition-delay: .3s;
  transition-delay: .3s; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(7) {
  -webkit-transition-delay: 350ms;
  transition-delay: 350ms; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(8) {
  -webkit-transition-delay: .4s;
  transition-delay: .4s; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(9) {
  -webkit-transition-delay: 450ms;
  transition-delay: 450ms; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(10) {
  -webkit-transition-delay: .5s;
  transition-delay: .5s; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(11) {
  -webkit-transition-delay: 550ms;
  transition-delay: 550ms; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(12) {
  -webkit-transition-delay: .6s;
  transition-delay: .6s; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(13) {
  -webkit-transition-delay: 650ms;
  transition-delay: 650ms; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(14) {
  -webkit-transition-delay: .7s;
  transition-delay: .7s; }

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(15) {
  -webkit-transition-delay: 750ms;
  transition-delay: 750ms; }

.mm-menu.mm-fx-listitems-slide .mm-listview > li {
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
  opacity: 0; }

html.mm-opening .mm-menu.mm-fx-listitems-slide .mm-panel.mm-opened .mm-listview > li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.mm-menu.mm-fx-listitems-fade .mm-listview > li {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 0; }

html.mm-opening .mm-menu.mm-fx-listitems-fade .mm-panel.mm-opened .mm-listview > li {
  opacity: 1; }

.mm-menu.mm-fx-listitems-drop .mm-listview > li {
  -webkit-transition-property: opacity,top;
  transition-property: opacity,top;
  opacity: 0;
  top: -25%; }

html.mm-opening .mm-menu.mm-fx-listitems-drop .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
  top: 0; }

html.mm-iconbar body {
  overflow-x: hidden; }

html.mm-iconbar .mm-page {
  background: inherit;
  min-height: 100vh; }

html.mm-iconbar .mm-slideout {
  box-sizing: border-box;
  padding-right: 60px;
  -webkit-transform: translate(60px, 0);
  -ms-transform: translate(60px, 0);
  transform: translate(60px, 0);
  -webkit-transform: translate3d(60px, 0, 0);
  transform: translate3d(60px, 0, 0); }

.mm-menu.mm-iconbar {
  display: block; }

.mm-menu .mm-panels > .mm-panel.mm-listview-justify:after, .mm-menu .mm-panels > .mm-panel.mm-listview-justify:before, .mm-menu.mm-listview-justify .mm-panels > .mm-panel:after, .mm-menu.mm-listview-justify .mm-panels > .mm-panel:before {
  content: none;
  display: none; }

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0; }

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  min-height: 40px; }

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li:not(.mm-divider), .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li > a:not(.mm-next), .mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li > span, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li > a:not(.mm-next), .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li > span {
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.mm-listview-small .mm-listview > li > a:not(.mm-next), .mm-listview-small .mm-listview > li > span {
  padding: 7px 10px 7px 20px; }

.mm-listview-small .mm-listview > li.mm-vertical > .mm-next, .mm-listview-small .mm-vertical > .mm-listview > li > .mm-next {
  height: 34px; }

.mm-listview-large .mm-listview > li > a:not(.mm-next), .mm-listview-large .mm-listview > li > span {
  padding: 15px 10px 15px 20px; }

.mm-listview-large .mm-listview > li.mm-vertical > .mm-next, .mm-listview-large .mm-vertical > .mm-listview > li > .mm-next {
  height: 50px; }

.mm-listview-huge .mm-listview > li > a:not(.mm-next), .mm-listview-huge .mm-listview > li > span {
  padding: 20px 10px 20px 20px; }

.mm-listview-huge .mm-listview > li.mm-vertical > .mm-next, .mm-listview-huge .mm-vertical > .mm-listview > li > .mm-next {
  height: 60px; }

.mm-listview .mm-divider {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px; }

.mm-listview .mm-spacer {
  padding-top: 40px; }

.mm-listview .mm-spacer > .mm-next {
  top: 40px; }

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px; }

.mm-listview .mm-inset {
  list-style: inside disc;
  padding: 0 10px 15px 40px;
  margin: 0; }

.mm-listview .mm-inset > li {
  padding: 5px 0; }

.mm-menu .mm-listview.mm-multiline > li > a, .mm-menu .mm-listview.mm-multiline > li > span, .mm-menu .mm-listview > li.mm-multiline > a, .mm-menu .mm-listview > li.mm-multiline > span, .mm-menu.mm-multiline .mm-listview > li > a, .mm-menu.mm-multiline .mm-listview > li > span {
  text-overflow: clip;
  white-space: normal; }

.mm-menu.mm-opened[class*=mm-pagedim] ~ #mm-blocker {
  opacity: 0; }

html.mm-opening .mm-menu.mm-opened[class*=mm-pagedim] ~ #mm-blocker {
  opacity: .3;
  -webkit-transition: opacity .4s ease .4s;
  transition: opacity .4s ease .4s; }

.mm-menu.mm-opened.mm-pagedim ~ #mm-blocker {
  background: inherit; }

.mm-menu.mm-opened.mm-pagedim-white ~ #mm-blocker {
  background: #fff; }

.mm-menu.mm-opened.mm-pagedim-black ~ #mm-blocker {
  background: #000; }

.mm-menu.mm-popup {
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
  opacity: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
  min-height: 140px;
  max-height: 880px;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

.mm-menu.mm-popup.mm-opened ~ .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0; }

.mm-menu.mm-popup.mm-opened ~ #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1; }

html.mm-opening .mm-menu.mm-popup {
  opacity: 1; }

.mm-menu.mm-offcanvas.mm-right {
  left: auto; }

html.mm-right.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0); } }

html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0; }

html.mm-front #mm-blocker {
  z-index: 1; }

html.mm-front .mm-menu.mm-offcanvas {
  z-index: 2; }

.mm-menu.mm-offcanvas.mm-front, .mm-menu.mm-offcanvas.mm-next {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.mm-menu.mm-offcanvas.mm-front.mm-right, .mm-menu.mm-offcanvas.mm-next.mm-right {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.mm-menu.mm-offcanvas.mm-top {
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

.mm-menu.mm-offcanvas.mm-bottom {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
  width: 100%;
  min-width: 100%;
  max-width: 100%; }

.mm-menu.mm-offcanvas.mm-bottom {
  top: auto; }

html.mm-opening .mm-menu.mm-offcanvas.mm-front, html.mm-opening .mm-menu.mm-offcanvas.mm-next {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
  height: 80%;
  min-height: 140px;
  max-height: 880px; }

.mm-menu.mm-shadow-page:after {
  content: "";
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 100;
  -webkit-clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%);
  clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%); }

.mm-menu.mm-shadow-page.mm-right:after {
  left: auto;
  right: 100%; }

.mm-menu.mm-shadow-page.mm-front:after, .mm-menu.mm-shadow-page.mm-next:after {
  content: none;
  display: none; }

.mm-menu.mm-shadow-page:after {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-menu.mm-theme-dark {
  background: #333;
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8); }

.mm-menu.mm-theme-dark .mm-navbar a, .mm-menu.mm-theme-dark .mm-navbar > * {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-dark .mm-btn:after, .mm-menu.mm-theme-dark .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-dark .mm-listview {
  border-color: rgba(0, 0, 0, 0.15); }

.mm-menu.mm-theme-dark .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-dark .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  tap-highlight-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-theme-dark .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-dark .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.05); }

.mm-menu.mm-theme-dark .mm-divider {
  background: rgba(255, 255, 255, 0.05); }

.mm-menu.mm-theme-dark label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.8); }

.mm-menu.mm-theme-dark em.mm-counter {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-dark .mm-fixeddivider span {
  background: rgba(255, 255, 255, 0.05); }

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-shadow-page.mm-theme-dark:after {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-menu.mm-shadow-panels.mm-theme-dark .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-menu.mm-theme-dark .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8); }

.mm-menu.mm-theme-dark .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-dark .mm-indexer a {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-theme-dark label.mm-toggle {
  background: rgba(0, 0, 0, 0.15); }

.mm-menu.mm-theme-dark label.mm-toggle:before {
  background: #333; }

.mm-menu.mm-theme-dark input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

.mm-menu.mm-theme-white {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-theme-white .mm-navbar a, .mm-menu.mm-theme-white .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-theme-white .mm-btn:after, .mm-menu.mm-theme-white .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-theme-white .mm-listview {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-theme-white .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-theme-white .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  tap-highlight-color: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-theme-white .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-white .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-theme-white .mm-divider {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-theme-white label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-theme-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-theme-white .mm-fixeddivider span {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-shadow-page.mm-theme-white:after {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

.mm-menu.mm-shadow-panels.mm-theme-white .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

.mm-menu.mm-theme-white .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-theme-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-theme-white .mm-indexer a {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.05); }

.mm-menu.mm-theme-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-theme-white label.mm-toggle:before {
  background: #fff; }

.mm-menu.mm-theme-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

.mm-menu.mm-theme-black {
  background: #000;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-theme-black .mm-navbar a, .mm-menu.mm-theme-black .mm-navbar > * {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-black .mm-btn:after, .mm-menu.mm-theme-black .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-black .mm-listview {
  border-color: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-theme-black .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-black .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
  tap-highlight-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-theme-black .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-black .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-theme-black .mm-divider {
  background: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-theme-black label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-theme-black em.mm-counter {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-black .mm-fixeddivider span {
  background: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-shadow-page.mm-theme-black:after {
  content: none;
  display: none; }

.mm-menu.mm-shadow-panels.mm-theme-black .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: false; }

.mm-menu.mm-theme-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-theme-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-theme-black .mm-indexer a {
  color: rgba(255, 255, 255, 0.4); }

.mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-theme-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-theme-black label.mm-toggle:before {
  background: #000; }

.mm-menu.mm-theme-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

.mm-menu .mm-tileview.mm-listview:after, .mm-menu.mm-tileview .mm-listview:after {
  content: '';
  display: block;
  clear: both; }

.mm-menu .mm-tileview.mm-listview > li, .mm-menu.mm-tileview .mm-listview > li {
  width: 50%;
  height: 0;
  padding: 50% 0 0 0;
  float: left;
  position: relative; }

.mm-menu .mm-tileview.mm-listview > li:after, .mm-menu.mm-tileview .mm-listview > li:after {
  left: 0;
  top: 0;
  border-right-width: 1px;
  border-right-style: solid;
  z-index: -1; }

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xs, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xs {
  width: 12.5%;
  padding-top: 12.5%; }

.mm-menu .mm-tileview.mm-listview > li.mm-tile-s, .mm-menu.mm-tileview .mm-listview > li.mm-tile-s {
  width: 25%;
  padding-top: 25%; }

.mm-menu .mm-tileview.mm-listview > li.mm-tile-l, .mm-menu.mm-tileview .mm-listview > li.mm-tile-l {
  width: 75%;
  padding-top: 75%; }

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xl, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xl {
  width: 100%;
  padding-top: 100%; }

.mm-menu .mm-tileview.mm-listview > li > a, .mm-menu .mm-tileview.mm-listview > li > span, .mm-menu.mm-tileview .mm-listview > li > a, .mm-menu.mm-tileview .mm-listview > li > span {
  line-height: 1px;
  text-align: center;
  padding: 50% 10px 0 10px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0; }

.mm-menu .mm-tileview.mm-listview > li > .mm-next, .mm-menu.mm-tileview .mm-listview > li > .mm-next {
  width: auto; }

.mm-menu .mm-tileview.mm-listview > li > .mm-next:after, .mm-menu .mm-tileview.mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-listview > li > .mm-next:after, .mm-menu.mm-tileview .mm-listview > li > .mm-next:before {
  content: none;
  display: none; }

.mm-menu.mm-tileview .mm-panel {
  padding-left: 0;
  padding-right: 0; }

.mm-menu.mm-tileview .mm-panel:after {
  content: none;
  display: none; }

.mm-menu.mm-tileview .mm-listview {
  margin: 0; }

html.mm-widescreen body {
  position: relative; }

html.mm-widescreen #mm-blocker {
  display: none !important; }

html.mm-widescreen .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  width: 70% !important;
  margin-left: 30% !important; }

html.mm-widescreen .mm-page {
  background: inherit;
  box-sizing: border-box; }

html.mm-widescreen.mm-blocking, html.mm-widescreen.mm-blocking body {
  overflow: auto; }

.mm-menu.mm-widescreen {
  border-right-width: 1px;
  border-right-style: solid;
  display: block !important;
  width: 30% !important;
  min-width: 0 !important;
  max-width: none !important;
  top: 0 !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 100 !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important; }

.mm-menu.mm-widescreen.mm-pageshadow:after {
  content: none;
  display: none; }

.mm-menu.mm-autoheight {
  -webkit-transition: none .4s ease;
  transition: none .4s ease;
  -webkit-transition-property: height,-webkit-transform;
  transition-property: height,-webkit-transform;
  transition-property: transform,height;
  transition-property: transform,height,-webkit-transform; }

.mm-menu.mm-autoheight:not(.mm-offcanvas) {
  position: relative; }

.mm-menu.mm-measureheight .mm-panel.mm-vertical.mm-opened, .mm-menu.mm-measureheight .mm-panel:not(.mm-vertical) {
  display: block !important; }

.mm-menu.mm-measureheight .mm-panels > .mm-panel {
  bottom: auto !important;
  height: auto !important; }

.mm-columns {
  -webkit-transition-property: width;
  transition-property: width; }

.mm-columns .mm-panels > .mm-panel {
  right: auto;
  -webkit-transition-property: width,-webkit-transform;
  transition-property: width,-webkit-transform;
  transition-property: width,transform;
  transition-property: width,transform,-webkit-transform; }

.mm-columns .mm-panels > .mm-panel.mm-opened, .mm-columns .mm-panels > .mm-panel.mm-subopened {
  border-left: 1px solid;
  border-color: inherit;
  display: block !important; }

.mm-columns .mm-panels > .mm-columns-0 {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-columns-0 .mm-panels > .mm-panel {
  z-index: 0; }

.mm-columns-0 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-columns-0 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.mm-menu.mm-offcanvas.mm-columns-0 {
  width: 80%;
  min-width: 140px;
  max-width: 0; }

html.mm-opening .mm-menu.mm-columns-0.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-0.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 0px) {
  html.mm-opening .mm-menu.mm-columns-0.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

html.mm-right.mm-opening .mm-menu.mm-columns-0.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-0.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 0px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-0.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.mm-columns .mm-panels > .mm-columns-1 {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.mm-columns-1 .mm-panels > .mm-panel {
  z-index: 1;
  width: 100%; }

.mm-columns-1 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-columns-1 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0); }

.mm-menu.mm-offcanvas.mm-columns-1 {
  width: 80%;
  min-width: 140px;
  max-width: 440px; }

html.mm-opening .mm-menu.mm-columns-1.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-1.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu.mm-columns-1.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0); } }

html.mm-right.mm-opening .mm-menu.mm-columns-1.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-1.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-1.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0); } }

.mm-columns .mm-panels > .mm-columns-2 {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0); }

.mm-columns-2 .mm-panels > .mm-panel {
  z-index: 2;
  width: 50%; }

.mm-columns-2 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-columns-2 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0); }

.mm-menu.mm-offcanvas.mm-columns-2 {
  width: 80%;
  min-width: 140px;
  max-width: 880px; }

html.mm-opening .mm-menu.mm-columns-2.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-2.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 1100px) {
  html.mm-opening .mm-menu.mm-columns-2.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(880px, 0);
    -ms-transform: translate(880px, 0);
    transform: translate(880px, 0);
    -webkit-transform: translate3d(880px, 0, 0);
    transform: translate3d(880px, 0, 0); } }

html.mm-right.mm-opening .mm-menu.mm-columns-2.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-2.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 1100px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-2.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-880px, 0);
    -ms-transform: translate(-880px, 0);
    transform: translate(-880px, 0);
    -webkit-transform: translate3d(-880px, 0, 0);
    transform: translate3d(-880px, 0, 0); } }

.mm-columns .mm-panels > .mm-columns-3 {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0); }

.mm-columns-3 .mm-panels > .mm-panel {
  z-index: 3;
  width: 33.34%; }

.mm-columns-3 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-columns-3 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0); }

.mm-menu.mm-offcanvas.mm-columns-3 {
  width: 80%;
  min-width: 140px;
  max-width: 1320px; }

html.mm-opening .mm-menu.mm-columns-3.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-3.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 1650px) {
  html.mm-opening .mm-menu.mm-columns-3.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(1320px, 0);
    -ms-transform: translate(1320px, 0);
    transform: translate(1320px, 0);
    -webkit-transform: translate3d(1320px, 0, 0);
    transform: translate3d(1320px, 0, 0); } }

html.mm-right.mm-opening .mm-menu.mm-columns-3.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-3.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 1650px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-3.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-1320px, 0);
    -ms-transform: translate(-1320px, 0);
    transform: translate(-1320px, 0);
    -webkit-transform: translate3d(-1320px, 0, 0);
    transform: translate3d(-1320px, 0, 0); } }

.mm-columns .mm-panels > .mm-columns-4 {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0); }

.mm-columns-4 .mm-panels > .mm-panel {
  z-index: 4;
  width: 25%; }

.mm-columns-4 .mm-panels > .mm-panel else {
  width: 100%; }

.mm-columns-4 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(500%, 0);
  -ms-transform: translate(500%, 0);
  transform: translate(500%, 0);
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0); }

.mm-menu.mm-offcanvas.mm-columns-4 {
  width: 80%;
  min-width: 140px;
  max-width: 1760px; }

html.mm-opening .mm-menu.mm-columns-4.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-4.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 2200px) {
  html.mm-opening .mm-menu.mm-columns-4.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(1760px, 0);
    -ms-transform: translate(1760px, 0);
    transform: translate(1760px, 0);
    -webkit-transform: translate3d(1760px, 0, 0);
    transform: translate3d(1760px, 0, 0); } }

html.mm-right.mm-opening .mm-menu.mm-columns-4.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-4.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 2200px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-4.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(-1760px, 0);
    -ms-transform: translate(-1760px, 0);
    transform: translate(-1760px, 0);
    -webkit-transform: translate3d(-1760px, 0, 0);
    transform: translate3d(-1760px, 0, 0); } }

.mm-columns.mm-offcanvas.mm-bottom, .mm-columns.mm-offcanvas.mm-top {
  width: 100%;
  max-width: 100%;
  min-width: 100%; }

html.mm-opening .mm-columns.mm-offcanvas.mm-front, html.mm-opening .mm-columns.mm-offcanvas.mm-next {
  -webkit-transition-property: width,min-width,max-width,-webkit-transform;
  transition-property: width,min-width,max-width,-webkit-transform;
  transition-property: width,min-width,max-width,transform;
  transition-property: width,min-width,max-width,transform,-webkit-transform; }

em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 45px;
  top: 50%; }

em.mm-counter + a.mm-next {
  width: 90px; }

em.mm-counter + a.mm-next + a, em.mm-counter + a.mm-next + span {
  margin-right: 90px; }

em.mm-counter + a.mm-fullsubopen {
  padding-left: 0; }

em.mm-counter + a.mm-fullsubopen + a, em.mm-counter + a.mm-fullsubopen + span {
  padding-right: 90px; }

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a, .mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span {
  padding-right: 90px; }

.mm-vertical > .mm-counter {
  top: 12px;
  margin-top: 0; }

.mm-vertical.mm-spacer > .mm-counter {
  margin-top: 40px; }

.mm-nosubresults > .mm-counter {
  display: none; }

.mm-menu em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-divider > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  line-height: 25px; }

.mm-divider.mm-opened a.mm-next:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.mm-collapsed:not(.mm-uncollapsed) {
  display: none; }

.mm-fixeddivider {
  background: inherit;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-fixeddivider:after {
  content: none !important;
  display: none !important; }

.mm-hasdividers .mm-fixeddivider {
  display: block; }

.mm-menu .mm-fixeddivider span {
  background: rgba(0, 0, 0, 0.05); }

html.mm-opened.mm-dragging .mm-menu, html.mm-opened.mm-dragging .mm-slideout {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.mm-menu.mm-dropdown {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%; }

html.mm-dropdown .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0; }

html.mm-dropdown #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1; }

html.mm-dropdown .mm-menu {
  z-index: 2; }

html.mm-dropdown.mm-opened:not(.mm-opening) .mm-menu.mm-dropdown {
  display: none; }

.mm-menu.mm-tip:before {
  content: '';
  background: inherit;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.mm-menu.mm-tipleft:before {
  left: 22px; }

.mm-menu.mm-tipright:before {
  right: 22px; }

.mm-menu.mm-tiptop:before {
  top: -8px; }

.mm-menu.mm-tipbottom:before {
  bottom: -8px; }

.mm-iconpanel .mm-panels > .mm-panel {
  -webkit-transition-property: left,-webkit-transform;
  transition-property: left,-webkit-transform;
  transition-property: transform,left;
  transition-property: transform,left,-webkit-transform; }

.mm-iconpanel .mm-panels > .mm-panel.mm-opened, .mm-iconpanel .mm-panels > .mm-panel.mm-subopened {
  border-left: 1px solid;
  border-color: inherit;
  display: block !important; }

.mm-iconpanel .mm-panels > .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  left: 0; }

.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  left: 40px; }

.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  left: 80px; }

.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  left: 120px; }

.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  left: 160px; }

.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  left: 200px; }

.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  left: 240px; }

.mm-subblocker {
  background: inherit;
  opacity: 0;
  display: block;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease; }

.mm-subopened .mm-subblocker {
  opacity: .6;
  bottom: -100000px; }

.mm-keyboardfocus a:focus {
  outline: 0; }

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(255, 255, 255, 0.5); }

.mm-navbars-bottom, .mm-navbars-top {
  background: inherit;
  border-color: inherit;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3; }

.mm-navbars-bottom > .mm-navbar, .mm-navbars-top > .mm-navbar {
  border: none;
  padding: 0;
  position: relative;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-navbars-top {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 0; }

.mm-navbars-bottom {
  border-top-style: solid;
  border-top-width: 1px;
  bottom: 0; }

.mm-navbar.mm-hasbtns {
  padding: 0 40px; }

.mm-navbar[class*=mm-navbar-content-] > * {
  box-sizing: border-box;
  display: block;
  float: left; }

.mm-navbar .mm-breadcrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0 0 0 17px; }

.mm-navbar .mm-breadcrumbs > * {
  display: inline-block;
  padding: 10px 3px; }

.mm-navbar .mm-breadcrumbs > a {
  text-decoration: underline; }

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
  margin-left: -40px; }

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
  margin-left: 0;
  padding-left: 0; }

.mm-hasnavbar-top-1 .mm-panels {
  top: 40px; }

.mm-hasnavbar-top-2 .mm-panels {
  top: 80px; }

.mm-hasnavbar-top-3 .mm-panels {
  top: 120px; }

.mm-hasnavbar-top-4 .mm-panels {
  top: 160px; }

.mm-hasnavbar-bottom-1 .mm-panels {
  bottom: 40px; }

.mm-hasnavbar-bottom-2 .mm-panels {
  bottom: 80px; }

.mm-hasnavbar-bottom-3 .mm-panels {
  bottom: 120px; }

.mm-hasnavbar-bottom-4 .mm-panels {
  bottom: 160px; }

.mm-navbar-size-2 {
  height: 80px; }

.mm-navbar-size-3 {
  height: 120px; }

.mm-navbar-size-4 {
  height: 160px; }

.mm-navbar-content-2 > * {
  width: 50%; }

.mm-navbar-content-3 > * {
  width: 33.33%; }

.mm-navbar-content-4 > * {
  width: 25%; }

.mm-navbar-content-5 > * {
  width: 20%; }

.mm-navbar-content-6 > * {
  width: 16.67%; }

.mm-menu.mm-rtl {
  direction: rtl; }

.mm-menu.mm-rtl.mm-offcanvas {
  right: auto; }

.mm-menu.mm-rtl .mm-panel:not(.mm-opened) {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.mm-menu.mm-rtl .mm-panel.mm-subopened {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0); }

.mm-menu.mm-rtl .mm-navbar .mm-btn:first-child {
  left: auto;
  right: 0; }

.mm-menu.mm-rtl .mm-navbar .mm-btn:last-child {
  right: auto;
  left: 0; }

.mm-menu.mm-rtl .mm-navbar .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto; }

.mm-menu.mm-rtl .mm-navbar .mm-prev:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto; }

.mm-menu.mm-rtl .mm-listview > li:not(.mm-divider)::after {
  left: 0;
  right: 20px; }

.mm-menu.mm-rtl .mm-listview > li > a:not(.mm-next), .mm-menu.mm-rtl .mm-listview > li > span:not(.mm-next) {
  padding-left: 10px;
  padding-right: 20px !important;
  margin-right: 0 !important; }

.mm-menu.mm-rtl .mm-listview .mm-next {
  right: auto;
  left: 0; }

.mm-menu.mm-rtl .mm-listview .mm-next:before {
  left: auto;
  right: 0; }

.mm-menu.mm-rtl .mm-listview .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto; }

.mm-menu.mm-rtl .mm-listview .mm-next + a, .mm-menu.mm-rtl .mm-listview .mm-next + span {
  margin-left: 50px; }

.mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + span {
  padding-left: 50px; }

.mm-menu.mm-rtl em.mm-counter {
  left: 45px;
  right: auto; }

.mm-menu.mm-rtl em.mm-counter + a.mm-next + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + span {
  margin-left: 90px; }

.mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + span {
  padding-left: 90px; }

.mm-menu.mm-rtl label.mm-check, .mm-menu.mm-rtl label.mm-toggle {
  left: 20px;
  right: auto !important; }

.mm-menu.mm-rtl label.mm-toggle + a, .mm-menu.mm-rtl label.mm-toggle + span {
  padding-left: 80px; }

.mm-menu.mm-rtl label.mm-check + a, .mm-menu.mm-rtl label.mm-check + span {
  padding-left: 60px; }

.mm-menu.mm-rtl a.mm-next + label.mm-check, .mm-menu.mm-rtl a.mm-next + label.mm-toggle {
  left: 60px; }

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  margin-left: 50px; }

.mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  padding-left: 70px; }

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span {
  padding-left: 50px; }

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle {
  left: 100px; }

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + span, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-left: 90px; }

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-] {
  left: 0; }

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-].mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel {
  -webkit-transition-property: right,-webkit-transform;
  transition-property: right,-webkit-transform;
  transition-property: transform,right;
  transition-property: transform,right,-webkit-transform; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  right: 0; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  right: 40px; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  right: 80px; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  right: 120px; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  right: 160px; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  right: 200px; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  right: 240px; }

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-opened {
  border-left: none;
  border-right: 1px solid;
  border-color: inherit; }

.mm-search, .mm-search input {
  box-sizing: border-box; }

.mm-search {
  height: 40px;
  padding: 7px 10px 0 10px;
  position: relative; }

.mm-search input {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  font: inherit;
  font-size: 14px;
  line-height: 26px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  margin: 0;
  padding: 0 10px; }

.mm-search input::-ms-clear {
  display: none; }

.mm-search .mm-clear, .mm-search .mm-next {
  right: 0; }

.mm-panel > .mm-search {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.mm-panel.mm-hassearch {
  padding-top: 40px; }

.mm-panel.mm-hassearch.mm-hasnavbar {
  padding-top: 80px; }

.mm-panel.mm-hassearch.mm-hasnavbar > .mm-search {
  top: 40px; }

.mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  padding: 40px 0; }

.mm-noresults .mm-indexer {
  display: none !important; }

li.mm-nosubresults > a.mm-next {
  display: none; }

li.mm-nosubresults > a.mm-next + a, li.mm-nosubresults > a.mm-next + span {
  padding-right: 10px; }

.mm-menu .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.75); }

.mm-menu .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-indexer {
  background: inherit;
  text-align: center;
  font-size: 12px;
  box-sizing: border-box;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100px;
  z-index: 15;
  -webkit-transition: right .4s ease;
  transition: right .4s ease;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-indexer a {
  text-decoration: none;
  display: block;
  height: 3.85%; }

.mm-indexer ~ .mm-panel.mm-hasindexer {
  padding-right: 40px; }

.mm-hasindexer .mm-indexer {
  right: 0; }

.mm-hasindexer .mm-fixeddivider {
  right: 20px; }

.mm-menu .mm-indexer a {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a:not(.mm-fullsubopen) {
  -webkit-transition: background .4s ease;
  transition: background .4s ease; }

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen):hover {
  background: rgba(255, 255, 255, 0.5); }

.mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.5); }

input.mm-check, input.mm-toggle {
  position: absolute;
  left: -10000px; }

label.mm-check, label.mm-toggle {
  margin: 0;
  position: absolute;
  top: 50%;
  z-index: 2; }

label.mm-check:before, label.mm-toggle:before {
  content: '';
  display: block; }

label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin-top: -15px; }

label.mm-toggle:before {
  border-radius: 30px;
  width: 28px;
  height: 28px;
  margin: 1px; }

input.mm-toggle:checked ~ label.mm-toggle:before {
  float: right; }

label.mm-check {
  width: 30px;
  height: 30px;
  margin-top: -15px; }

label.mm-check:before {
  border-left: 3px solid;
  border-bottom: 3px solid;
  width: 40%;
  height: 20%;
  margin: 25% 0 0 20%;
  opacity: .1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

input.mm-check:checked ~ label.mm-check:before {
  opacity: 1; }

li.mm-vertical label.mm-check, li.mm-vertical label.mm-toggle {
  bottom: auto;
  margin-top: 0; }

li.mm-vertical label.mm-toggle {
  top: 5px; }

li.mm-vertical label.mm-check {
  top: 5px; }

label.mm-check, label.mm-toggle {
  right: 20px; }

label.mm-toggle + a, label.mm-toggle + span {
  padding-right: 80px; }

label.mm-check + a, label.mm-check + span {
  padding-right: 60px; }

a.mm-next + label.mm-check, a.mm-next + label.mm-toggle {
  right: 60px; }

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span, a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  margin-right: 50px; }

a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  padding-right: 70px; }

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span {
  padding-right: 50px; }

em.mm-counter + a.mm-next + label.mm-check, em.mm-counter + a.mm-next + label.mm-toggle {
  right: 100px; }

em.mm-counter + a.mm-next + label.mm-check + a, em.mm-counter + a.mm-next + label.mm-check + span, em.mm-counter + a.mm-next + label.mm-toggle + a, em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-right: 90px; }

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu label.mm-toggle:before {
  background: #f3f3f3; }

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

.mm-menu label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.75); }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent; }

.hamburger:hover {
  opacity: .7; }

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px; }

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px; }

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #000; }

.hamburger-inner:after, .hamburger-inner:before {
  display: block;
  content: ""; }

.hamburger-inner:before {
  top: -10px; }

.hamburger-inner:after {
  bottom: -10px; }

.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dx .hamburger-inner:after, .hamburger--3dx .hamburger-inner:before {
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dx.is-active .hamburger-inner {
  transform: rotateY(180deg);
  background-color: transparent; }

.hamburger--3dx.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dx-r .hamburger-inner:after, .hamburger--3dx-r .hamburger-inner:before {
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dx-r.is-active .hamburger-inner {
  transform: rotateY(-180deg);
  background-color: transparent; }

.hamburger--3dx-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dy .hamburger-inner:after, .hamburger--3dy .hamburger-inner:before {
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dy.is-active .hamburger-inner {
  transform: rotateX(-180deg);
  background-color: transparent; }

.hamburger--3dy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dy-r .hamburger-inner:after, .hamburger--3dy-r .hamburger-inner:before {
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dy-r.is-active .hamburger-inner {
  transform: rotateX(180deg);
  background-color: transparent; }

.hamburger--3dy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dxy .hamburger-inner:after, .hamburger--3dxy .hamburger-inner:before {
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dxy.is-active .hamburger-inner {
  transform: rotateX(180deg) rotateY(180deg);
  background-color: transparent; }

.hamburger--3dxy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dxy-r .hamburger-inner:after, .hamburger--3dxy-r .hamburger-inner:before {
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s; }

.hamburger--3dxy-r.is-active .hamburger-inner {
  transform: rotateX(180deg) rotateY(180deg) rotate(-180deg);
  background-color: transparent; }

.hamburger--3dxy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--arrow.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7); }

.hamburger--arrow.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7); }

.hamburger--arrow-r.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7); }

.hamburger--arrow-r.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7); }

.hamburger--arrowalt .hamburger-inner:before {
  transition: top 0.1s ease 0.1s, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom 0.1s ease 0.1s, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, transform 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7); }

.hamburger--arrowalt.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7); }

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top 0.1s ease 0.1s, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom 0.1s ease 0.1s, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, transform 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7); }

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7); }

.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }

.hamburger--arrowturn.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7); }

.hamburger--arrowturn.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7); }

.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }

.hamburger--arrowturn-r.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7); }

.hamburger--arrowturn-r.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7); }

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:after, .hamburger--boring .hamburger-inner:before {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }

.hamburger--boring.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0; }

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg); }

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: .13s; }

.hamburger--collapse .hamburger-inner:after {
  top: -20px;
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear; }

.hamburger--collapse .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--collapse.is-active .hamburger-inner:after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
  opacity: 0; }

.hamburger--collapse.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
  transform: rotate(-90deg); }

.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: .13s; }

.hamburger--collapse-r .hamburger-inner:after {
  top: -20px;
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear; }

.hamburger--collapse-r .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, -10px, 0) rotate(45deg); }

.hamburger--collapse-r.is-active .hamburger-inner:after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
  opacity: 0; }

.hamburger--collapse-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
  transform: rotate(90deg); }

.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-duration: .275s; }

.hamburger--elastic .hamburger-inner:before {
  top: 10px;
  transition: opacity .125s ease .275s; }

.hamburger--elastic .hamburger-inner:after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0) rotate(135deg); }

.hamburger--elastic.is-active .hamburger-inner:before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0) rotate(-270deg); }

.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-duration: .275s; }

.hamburger--elastic-r .hamburger-inner:before {
  top: 10px;
  transition: opacity .125s ease .275s; }

.hamburger--elastic-r .hamburger-inner:after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0) rotate(-135deg); }

.hamburger--elastic-r.is-active .hamburger-inner:before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic-r.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0) rotate(270deg); }

.hamburger--emphatic {
  overflow: hidden; }

.hamburger--emphatic .hamburger-inner {
  transition: background-color .125s ease-in .175s; }

.hamburger--emphatic .hamburger-inner:before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, left 0.125s ease-in 0.175s; }

.hamburger--emphatic .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, right 0.125s ease-in 0.175s; }

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent; }

.hamburger--emphatic.is-active .hamburger-inner:before {
  top: -80px;
  left: -80px;
  transition: left 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
  transform: translate3d(80px, 80px, 0) rotate(45deg); }

.hamburger--emphatic.is-active .hamburger-inner:after {
  top: -80px;
  right: -80px;
  transition: right 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg); }

.hamburger--emphatic-r {
  overflow: hidden; }

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color .125s ease-in .175s; }

.hamburger--emphatic-r .hamburger-inner:before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, left 0.125s ease-in 0.175s; }

.hamburger--emphatic-r .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, right 0.125s ease-in 0.175s; }

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent; }

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  top: 80px;
  left: -80px;
  transition: left 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
  transform: translate3d(80px, -80px, 0) rotate(-45deg); }

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  top: 80px;
  right: -80px;
  transition: right 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
  transform: translate3d(-80px, -80px, 0) rotate(45deg); }

.hamburger--minus .hamburger-inner:after, .hamburger--minus .hamburger-inner:before {
  transition: bottom .08s ease-out 0s,top .08s ease-out 0s,opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner:after, .hamburger--minus.is-active .hamburger-inner:before {
  transition: bottom .08s ease-out,top .08s ease-out,opacity 0s linear .08s;
  opacity: 0; }

.hamburger--minus.is-active .hamburger-inner:before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner:after {
  bottom: 0; }

.hamburger--slider .hamburger-inner {
  top: 2px; }

.hamburger--slider .hamburger-inner:before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform,opacity; }

.hamburger--slider .hamburger-inner:after {
  top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--slider.is-active .hamburger-inner:before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0) rotate(-90deg); }

.hamburger--slider-r .hamburger-inner {
  top: 2px; }

.hamburger--slider-r .hamburger-inner:before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform,opacity; }

.hamburger--slider-r .hamburger-inner:after {
  top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }

.hamburger--slider-r.is-active .hamburger-inner:before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0) rotate(90deg); }

.hamburger--spin .hamburger-inner {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: .22s; }

.hamburger--spin .hamburger-inner:before {
  transition: top .1s ease-in .25s,opacity .1s ease-in; }

.hamburger--spin .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(225deg); }

.hamburger--spin.is-active .hamburger-inner:before {
  top: 0;
  transition: top .1s ease-out,opacity .1s ease-out .12s;
  opacity: 0; }

.hamburger--spin.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg); }

.hamburger--spin-r .hamburger-inner {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: .22s; }

.hamburger--spin-r .hamburger-inner:before {
  transition: top .1s ease-in .25s,opacity .1s ease-in; }

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(-225deg); }

.hamburger--spin-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top .1s ease-out,opacity .1s ease-out .12s;
  opacity: 0; }

.hamburger--spin-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(90deg); }

.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s linear .13s; }

.hamburger--spring .hamburger-inner:before {
  top: 10px;
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring .hamburger-inner:after {
  top: 20px;
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: .22s;
  background-color: transparent; }

.hamburger--spring.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--spring.is-active .hamburger-inner:after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(-45deg); }

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: .13s; }

.hamburger--spring-r .hamburger-inner:after {
  top: -20px;
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0s linear; }

.hamburger--spring-r .hamburger-inner:before {
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

.hamburger--spring-r.is-active .hamburger-inner:after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s linear 0.22s;
  opacity: 0; }

.hamburger--spring-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: rotate(90deg); }

.hamburger--stand .hamburger-inner {
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.15s, background-color 0s linear 75ms; }

.hamburger--stand .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s; }

.hamburger--stand .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s; }

.hamburger--stand.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.15s;
  transform: rotate(90deg);
  background-color: transparent; }

.hamburger--stand.is-active .hamburger-inner:before {
  top: 0;
  transition: top 75ms ease-out 0.1s, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
  transform: rotate(-45deg); }

.hamburger--stand.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 75ms ease-out 0.1s, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
  transform: rotate(45deg); }

.hamburger--stand-r .hamburger-inner {
  transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.15s, background-color 0s linear 75ms; }

.hamburger--stand-r .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s; }

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s; }

.hamburger--stand-r.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.15s;
  transform: rotate(-90deg);
  background-color: transparent; }

.hamburger--stand-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 75ms ease-out 0.1s, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
  transform: rotate(-45deg); }

.hamburger--stand-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 75ms ease-out 0.1s, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
  transform: rotate(45deg); }

.hamburger--squeeze .hamburger-inner {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms; }

.hamburger--squeeze .hamburger-inner:before {
  transition: top 75ms ease .12s,opacity 75ms ease; }

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg); }

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  transition: top 75ms ease,opacity 75ms ease .12s;
  opacity: 0; }

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg); }

.hamburger--vortex .hamburger-inner {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-duration: .2s; }

.hamburger--vortex .hamburger-inner:after, .hamburger--vortex .hamburger-inner:before {
  transition-delay: .1s;
  transition-timing-function: linear;
  transition-duration: 0s; }

.hamburger--vortex .hamburger-inner:before {
  transition-property: top,opacity; }

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom,transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(765deg); }

.hamburger--vortex.is-active .hamburger-inner:after, .hamburger--vortex.is-active .hamburger-inner:before {
  transition-delay: 0s; }

.hamburger--vortex.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0; }

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(90deg); }

.hamburger--vortex-r .hamburger-inner {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-duration: .2s; }

.hamburger--vortex-r .hamburger-inner:after, .hamburger--vortex-r .hamburger-inner:before {
  transition-delay: .1s;
  transition-timing-function: linear;
  transition-duration: 0s; }

.hamburger--vortex-r .hamburger-inner:before {
  transition-property: top,opacity; }

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom,transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(-765deg); }

.hamburger--vortex-r.is-active .hamburger-inner:after, .hamburger--vortex-r.is-active .hamburger-inner:before {
  transition-delay: 0s; }

.hamburger--vortex-r.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0; }

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg); }

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

header.banner #open-close-menu {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  left: 1rem;
  z-index: 10; }
  header.banner #open-close-menu .hamburger-inner, header.banner #open-close-menu .hamburger-inner:after, header.banner #open-close-menu .hamburger-inner:before {
    background-color: #B3D8EF; }

header.banner .header-main {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  header.banner .header-main a.brand img {
    max-height: 70px; }
    @media (min-width: 576px) {
      header.banner .header-main a.brand img {
        max-height: 120px; } }
  header.banner .header-main ul.nav {
    text-transform: uppercase; }
    header.banner .header-main ul.nav li {
      padding-right: 1rem;
      font-size: .7rem; }
      header.banner .header-main ul.nav li:last-child {
        padding-right: 0; }
      @media (min-width: 992px) {
        header.banner .header-main ul.nav li {
          padding-right: 2.4rem;
          font-size: .75rem; } }
      @media (min-width: 1200px) {
        header.banner .header-main ul.nav li {
          font-size: .9rem;
          padding-right: 3.2rem; } }
      header.banner .header-main ul.nav li a {
        position: relative;
        padding-bottom: .4rem; }
        header.banner .header-main ul.nav li a:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f1b0";
          position: absolute;
          top: 0;
          line-height: 1.6rem;
          left: 50%;
          color: #FFFFFF;
          font-size: 1.6rem;
          margin-left: -13px;
          z-index: -1;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out; }
        header.banner .header-main ul.nav li a:after {
          content: '';
          position: absolute;
          left: .2rem;
          right: .2rem;
          bottom: 0;
          height: 2px;
          background: transparent;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out; }
        header.banner .header-main ul.nav li a:hover {
          color: #5C483A; }
          header.banner .header-main ul.nav li a:hover:before {
            color: #B3D8EF;
            top: -28px; }
          header.banner .header-main ul.nav li a:hover:after {
            background: #B3D8EF; }
      header.banner .header-main ul.nav li.active a:before {
        color: #B3D8EF;
        top: -28px; }
      header.banner .header-main ul.nav li.active a:after {
        background: #B3D8EF; }

header.banner ul.nav.contact-nav {
  text-align: right; }
  header.banner ul.nav.contact-nav li {
    padding-right: 1rem; }
  @media (min-width: 992px) {
    header.banner ul.nav.contact-nav li {
      padding-right: 1.6rem; } }
  @media (min-width: 1200px) {
    header.banner ul.nav.contact-nav li {
      padding-right: 2.5rem; } }

header.banner ul.social {
  float: right;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  font-size: .8rem;
  position: relative;
  top: 2px; }
  header.banner ul.social li {
    display: inline-block;
    margin-left: .5rem; }
    header.banner ul.social li:first-child {
      margin-left: 0; }
  @media (min-width: 768px) {
    header.banner ul.social {
      display: none; } }
  @media (min-width: 992px) {
    header.banner ul.social {
      display: block;
      font-size: .7rem; } }
  @media (min-width: 1200px) {
    header.banner ul.social {
      display: block;
      font-size: .8rem; } }

header.banner .header-bar {
  background: #F1EEDC;
  font-size: .6rem;
  padding-top: .7rem;
  padding-bottom: .6rem;
  font-weight: 300;
  color: #5C483A; }
  @media (min-width: 576px) {
    header.banner .header-bar {
      font-size: .8rem; } }
  header.banner .header-bar a.phone-number,
  header.banner .header-bar address,
  header.banner .header-bar .hours-header {
    display: inline-block;
    margin-bottom: 0; }
  header.banner .header-bar a.phone-number {
    display: inline-block;
    margin-right: 1rem; }
    @media (min-width: 576px) {
      header.banner .header-bar a.phone-number {
        margin-right: 2rem; } }
  header.banner .header-bar .hours-header {
    list-style: none;
    padding-left: 0;
    margin-right: 2rem; }
    header.banner .header-bar .hours-header li {
      display: inline-block;
      position: relative;
      margin-right: 1rem; }
      header.banner .header-bar .hours-header li:after {
        content: '';
        height: 13px;
        width: 1px;
        background: #5C483A;
        top: 3px;
        right: -.6rem;
        z-index: 10;
        position: absolute; }
      header.banner .header-bar .hours-header li:nth-child(3) {
        margin-right: 0; }
        header.banner .header-bar .hours-header li:nth-child(3):after {
          display: none; }
      header.banner .header-bar .hours-header li:last-child {
        display: none; }

body.single-services header.banner .header-main ul.nav li.menu-services a:before {
  color: #B3D8EF;
  top: -28px; }

body.single-services header.banner .header-main ul.nav li.menu-services a:after {
  background: #B3D8EF; }

body.single-team header.banner .header-main ul.nav li.menu-our-team a:before {
  color: #B3D8EF;
  top: -28px; }

body.single-team header.banner .header-main ul.nav li.menu-our-team a:after {
  background: #B3D8EF; }

body.contact-us header.banner .header-main ul.nav li.contact-us a:before {
  color: #B3D8EF;
  top: -28px; }

body.contact-us header.banner .header-main ul.nav li.contact-us a:after {
  background: #B3D8EF; }

footer.content-info {
  background: #B3D8EF; }
  footer.content-info .quote {
    background: #FFFFFF;
    color: #B3D8EF;
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.8rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300; }
    footer.content-info .quote:after {
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -10px;
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #FFFFFF; }
    @media (min-width: 768px) {
      footer.content-info .quote {
        font-size: 2rem;
        line-height: 1.5rem; } }
  footer.content-info .footer-columns {
    background: #5C483A;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #F1EEDC;
    font-size: .8rem;
    line-height: 1.3rem; }
    footer.content-info .footer-columns h3 {
      color: #F1EEDC; }
    footer.content-info .footer-columns a.brand {
      float: left;
      display: block;
      width: 20%;
      margin-right: 1rem; }
      footer.content-info .footer-columns a.brand img {
        max-width: 100%;
        margin-bottom: 1rem; }
      @media (min-width: 768px) {
        footer.content-info .footer-columns a.brand {
          float: none;
          width: auto;
          margin-right: 0; }
          footer.content-info .footer-columns a.brand img {
            max-width: 35%; } }
    footer.content-info .footer-columns a {
      color: #B3D8EF; }
    footer.content-info .footer-columns p {
      font-size: .8rem;
      line-height: 1.3rem;
      font-weight: 400;
      color: #F1EEDC; }
    footer.content-info .footer-columns ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      color: #B3D8EF; }
    footer.content-info .footer-columns address {
      margin-bottom: 0;
      color: #B3D8EF;
      border-top: 1px solid #F1EEDC;
      border-bottom: 1px solid #F1EEDC;
      padding: .7rem 0;
      margin: 1rem 0; }
  footer.content-info .copyright {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: .7rem;
    line-height: 19px; }
    footer.content-info .copyright cite,
    footer.content-info .copyright nav,
    footer.content-info .copyright ul.social {
      display: block; }
      @media (min-width: 768px) {
        footer.content-info .copyright cite,
        footer.content-info .copyright nav,
        footer.content-info .copyright ul.social {
          display: inline-block; } }
    footer.content-info .copyright cite {
      margin-right: 0;
      color: #FFFFFF;
      font-style: normal;
      margin-bottom: .6rem; }
      @media (min-width: 768px) {
        footer.content-info .copyright cite {
          margin-right: 3.5rem;
          margin-bottom: 0; } }
    footer.content-info .copyright nav {
      text-align: center; }
      footer.content-info .copyright nav ul li {
        padding: .2rem 1rem .2rem 0; }
        footer.content-info .copyright nav ul li a:hover {
          color: #FFFFFF; }
      @media (min-width: 768px) {
        footer.content-info .copyright nav ul li {
          padding: 0 2.5rem 0 0; } }
    footer.content-info .copyright ul.social {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      padding-top: .5rem;
      font-size: .8rem; }
      footer.content-info .copyright ul.social li {
        display: inline-block;
        margin-right: .8rem; }
      @media (min-width: 768px) {
        footer.content-info .copyright ul.social {
          padding-top: 0;
          float: right;
          margin-left: .3rem;
          margin-right: 0; } }
  footer.content-info .latest-posts-footer .col-sm-6:nth-child(1), footer.content-info .latest-posts-footer .col-sm-6:nth-child(2) {
    margin-bottom: 1.5rem; }
    footer.content-info .latest-posts-footer .col-sm-6:nth-child(1) .post-inner, footer.content-info .latest-posts-footer .col-sm-6:nth-child(2) .post-inner {
      border-bottom: 1px solid #F1EEDC;
      padding-bottom: .75rem; }
  footer.content-info .latest-posts-footer h5 {
    font-size: 1rem;
    margin-left: 1rem;
    margin: 0 1rem .5rem; }
  footer.content-info .latest-posts-footer p {
    height: 60px;
    overflow: hidden;
    margin: 0 1rem;
    font-size: .7rem;
    line-height: 1.2rem; }

/* Page Header styles */
.page-header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-image: url("../images/heading-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .page-header h1 {
    margin-bottom: 0;
    font-weight: 300;
    color: #5C483A; }
  .page-header cite {
    font-weight: 100;
    font-style: normal;
    color: #5C483A; }
  @media (min-width: 768px) {
    .page-header {
      padding-top: 4rem;
      padding-bottom: 4rem; } }

.background-video {
  position: relative;
  overflow: hidden; }
  .background-video #myVideo {
    display: none; }
    @media (min-width: 768px) {
      .background-video #myVideo {
        display: block; } }
  .background-video .banner-content {
    background: #f7f9f7;
    background: rgba(247, 249, 247, 0.8);
    padding: 1.5rem 2rem 1.8rem; }
    .background-video .banner-content h2 {
      font-weight: 700;
      color: #5C483A;
      font-size: 2.7em;
      line-height: 2.8rem;
      margin-bottom: 0; }
    .background-video .banner-content p {
      font-weight: 300;
      font-size: 1rem;
      color: #5C483A;
      margin-bottom: 1.2rem; }
    @media (min-width: 768px) {
      .background-video .banner-content {
        position: absolute;
        top: 50%;
        left: 15%;
        margin-top: -89px; } }
  @media (min-width: 768px) {
    .background-video {
      height: 550px; }
      .background-video #myVideo {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block; } }

/* Owl Carousel styles */
.owl-carousel.hero-carousel .banner-content {
  background: #f7f9f7;
  background: rgba(247, 249, 247, 0.8);
  padding: 1.5rem 2rem 1.8rem; }
  .owl-carousel.hero-carousel .banner-content h2 {
    font-weight: 700;
    color: #5C483A;
    font-size: 2.7em;
    line-height: 2.8rem;
    margin-bottom: 0; }
  .owl-carousel.hero-carousel .banner-content p {
    font-weight: 300;
    font-size: 1rem;
    color: #5C483A;
    margin-bottom: 1.2rem; }
  @media (min-width: 768px) {
    .owl-carousel.hero-carousel .banner-content {
      position: absolute;
      top: 50%;
      left: 15%;
      margin-top: -89px; } }

.owl-carousel.hero-carousel .owl-nav .owl-prev,
.owl-carousel.hero-carousel .owl-nav .owl-next {
  top: 24%; }
  @media (min-width: 576px) {
    .owl-carousel.hero-carousel .owl-nav .owl-prev,
    .owl-carousel.hero-carousel .owl-nav .owl-next {
      top: 26%; } }
  @media (min-width: 768px) {
    .owl-carousel.hero-carousel .owl-nav .owl-prev,
    .owl-carousel.hero-carousel .owl-nav .owl-next {
      top: 50%; } }

.owl-carousel.hero-carousel .owl-dots {
  display: none; }
  @media (min-width: 768px) {
    .owl-carousel.hero-carousel .owl-dots {
      display: block; } }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  color: #8c9b97;
  background: white;
  background: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1.1rem;
  text-align: center;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .owl-carousel .owl-nav .owl-prev:hover,
  .owl-carousel .owl-nav .owl-next:hover {
    background: white;
    background: rgba(255, 255, 255, 0.5); }
  .owl-carousel .owl-nav .owl-prev.disabled,
  .owl-carousel .owl-nav .owl-next.disabled {
    display: none; }

.owl-carousel .owl-nav .owl-prev {
  left: 2rem; }

.owl-carousel .owl-nav .owl-next {
  right: 2rem; }

.owl-carousel .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
  text-align: center; }
  .owl-carousel .owl-dots .owl-dot {
    display: inline-block; }
    .owl-carousel .owl-dots .owl-dot span {
      display: block;
      width: 15px;
      height: 15px;
      margin: 0 .3rem;
      background: white;
      background: rgba(255, 255, 255, 0.25);
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      border-radius: 15px;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
    .owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
      background: white;
      background: rgba(255, 255, 255, 0.5); }

/* Content builder styles */
section.content-builder {
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* Column Styles */
  /* Team Styles */
  /* Content Block Styles */
  /* Service Block Styles */
  /* Counter Block Styles */
  /* Testimonials Block Styles */
  /* Latest Posts Styles */
  /* Gallery / Video Block Styles */
  /* Google Map Block Styles */
  /* Service List Styles */
  /* Full Width Content Styles */ }
  @media (min-width: 768px) {
    section.content-builder {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  section.content-builder.columns img {
    max-width: 80%;
    margin-bottom: 1rem; }
  section.content-builder.columns h3 {
    font-weight: 400;
    font-size: 1.2rem;
    color: #5C483A;
    margin-bottom: 1rem; }
  section.content-builder.columns p {
    font-size: .8rem;
    color: #94b3c6;
    font-weight: 300;
    line-height: 1.4rem;
    margin-bottom: 0; }
    section.content-builder.columns p a {
      font-style: italic;
      text-transform: uppercase; }
      section.content-builder.columns p a:hover {
        text-decoration: underline;
        color: #5C483A; }
  section.content-builder.columns .col-md {
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem; }
    section.content-builder.columns .col-md:last-child {
      margin-bottom: 0; }
  @media (min-width: 576px) {
    section.content-builder.columns .col-md {
      margin-bottom: 0;
      padding-left: 15px;
      padding-right: 15px; } }
  section.content-builder.our-team {
    background: #B3D8EF; }
    section.content-builder.our-team h3 {
      font-weight: 100;
      font-size: 2.2rem;
      margin-bottom: 1.5rem;
      color: #5C483A; }
    section.content-builder.our-team p {
      color: #5C483A;
      font-weight: 300;
      margin-bottom: 1.5rem; }
    section.content-builder.our-team .meet-our-team {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 2rem; }
      @media (min-width: 768px) {
        section.content-builder.our-team .meet-our-team {
          padding-left: 15px;
          padding-right: 15px; } }
      @media (min-width: 1200px) {
        section.content-builder.our-team .meet-our-team {
          margin-bottom: 0; } }
    section.content-builder.our-team .team-member {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 2rem; }
      section.content-builder.our-team .team-member:last-child {
        margin-bottom: 0; }
      @media (min-width: 768px) {
        section.content-builder.our-team .team-member {
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 0; } }
      section.content-builder.our-team .team-member a {
        margin-bottom: 75px;
        display: block; }
      section.content-builder.our-team .team-member .member-info {
        background: #f5f5f5;
        padding: 1rem 1.5rem;
        position: absolute;
        bottom: 0;
        left: 2rem;
        right: 2rem;
        height: 75px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; }
        @media (min-width: 768px) {
          section.content-builder.our-team .team-member .member-info {
            left: 0;
            right: 0; } }
        section.content-builder.our-team .team-member .member-info h4 {
          color: #5C483A;
          font-size: 1.1rem;
          font-weight: 700;
          margin-bottom: 0;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out; }
        section.content-builder.our-team .team-member .member-info p {
          color: #94b3c6;
          font-size: .8rem;
          line-height: 1.4rem;
          margin-bottom: 0; }
        section.content-builder.our-team .team-member .member-info:after {
          position: absolute;
          top: -6px;
          left: 2rem;
          content: '';
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 8px solid #f5f5f5;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out; }
      section.content-builder.our-team .team-member:hover .member-info {
        background: #5C483A;
        padding-bottom: 1rem;
        height: 80px; }
        section.content-builder.our-team .team-member:hover .member-info h4 {
          color: #F1EEDC; }
        section.content-builder.our-team .team-member:hover .member-info:after {
          border-bottom: 6px solid #5C483A; }
  section.content-builder.content-block .block-image,
  section.content-builder.content-block .block-content {
    padding-left: 2rem;
    padding-right: 2rem; }
    @media (min-width: 768px) {
      section.content-builder.content-block .block-image,
      section.content-builder.content-block .block-content {
        padding-left: 15px;
        padding-right: 15px; }
        section.content-builder.content-block .block-image .block-image,
        section.content-builder.content-block .block-content .block-image {
          margin-bottom: 0; } }
  section.content-builder.content-block .block-image {
    margin-bottom: 2rem; }
  section.content-builder.content-block .content-area {
    margin-bottom: 2rem; }
    section.content-builder.content-block .content-area:last-child {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      section.content-builder.content-block .content-area {
        margin-bottom: 0; }
        section.content-builder.content-block .content-area:nth-child(1), section.content-builder.content-block .content-area:nth-child(2) {
          margin-bottom: 2rem; } }
  section.content-builder.content-block h2 {
    font-weight: 100;
    font-size: 2.5rem;
    margin-bottom: 2rem; }
  section.content-builder.content-block h3 {
    color: #94b3c6;
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 1rem; }
    section.content-builder.content-block h3 i {
      color: #dad3ac;
      font-size: 1.4rem;
      margin-right: .2rem;
      position: relative;
      top: 2px; }
  section.content-builder.content-block p {
    font-size: .8rem;
    line-height: 1.4rem;
    margin-bottom: .5rem; }
  section.content-builder.content-block a {
    font-weight: 700;
    font-size: .8rem;
    line-height: 1.4rem; }
  section.content-builder.sevices-block {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (min-width: 768px) {
      section.content-builder.sevices-block {
        padding-top: 6rem;
        padding-bottom: 6rem; } }
    section.content-builder.sevices-block .service-wrap {
      padding-left: 2rem;
      padding-right: 2rem; }
      @media (min-width: 768px) {
        section.content-builder.sevices-block .service-wrap {
          padding-left: 15px;
          padding-right: 15px; } }
      section.content-builder.sevices-block .service-wrap .service-inner {
        padding: 2rem;
        background: white;
        background: rgba(255, 255, 255, 0.7); }
      section.content-builder.sevices-block .service-wrap ul {
        list-style: none;
        padding-left: 0;
        -moz-column-count: 2;
        -moz-column-gap: 20px;
        -webkit-column-count: 2;
        -webkit-column-gap: 20px;
        column-count: 2;
        column-gap: 20px; }
        section.content-builder.sevices-block .service-wrap ul li.service {
          padding-left: 0;
          padding-right: 0; }
      section.content-builder.sevices-block .service-wrap h3 {
        font-weight: 100;
        margin-bottom: 1.5rem; }
      section.content-builder.sevices-block .service-wrap p {
        font-weight: 300;
        font-size: .8rem;
        line-height: 1.5rem;
        margin-bottom: 1.5rem; }
      section.content-builder.sevices-block .service-wrap .row, section.content-builder.sevices-block .service-wrap .gform_wrapper ul.gform_fields, section.content-builder.sevices-block .gform_wrapper .service-wrap ul.gform_fields {
        margin-bottom: 1.5rem; }
      section.content-builder.sevices-block .service-wrap .service {
        font-size: .8rem;
        font-weight: 700;
        margin-bottom: .3rem;
        padding-left: 30px;
        padding-right: 30px; }
        section.content-builder.sevices-block .service-wrap .service a {
          position: relative;
          display: block;
          padding-left: 15px; }
          section.content-builder.sevices-block .service-wrap .service a:before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            display: block;
            width: 10px;
            height: 10px;
            background: #FFFFFF;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            border-radius: 10px;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out; }
          section.content-builder.sevices-block .service-wrap .service a:hover {
            color: #5C483A; }
            section.content-builder.sevices-block .service-wrap .service a:hover:before {
              background: #B3D8EF; }
  section.content-builder.counter-columns {
    background: #5C483A;
    color: #FFFFFF;
    padding-top: 2.5rem;
    padding-bottom: 3rem; }
    @media (min-width: 768px) {
      section.content-builder.counter-columns {
        padding-top: 3.5rem;
        padding-bottom: 4rem; } }
    section.content-builder.counter-columns .count-block {
      margin-bottom: 2.5rem;
      padding-left: 2rem;
      padding-right: 2rem; }
      section.content-builder.counter-columns .count-block:last-child {
        margin-bottom: 0; }
      @media (min-width: 768px) {
        section.content-builder.counter-columns .count-block {
          margin-bottom: 0;
          padding-left: 15px;
          padding-right: 15px; } }
    section.content-builder.counter-columns h5 {
      text-transform: uppercase;
      font-size: .9rem;
      line-height: 1.3rem;
      margin-bottom: 1.5rem; }
    section.content-builder.counter-columns span.fig-number {
      font-size: 3rem;
      font-weight: 100;
      display: block;
      margin-bottom: 1rem;
      line-height: 3rem; }
  section.content-builder.testimonials {
    padding-top: 10%;
    padding-bottom: 10%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; }
    section.content-builder.testimonials:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      background: rgba(255, 255, 255, 0.7); }
    section.content-builder.testimonials .col-sm-8 {
      padding-left: 2rem;
      padding-right: 2rem; }
      @media (min-width: 768px) {
        section.content-builder.testimonials .col-sm-8 {
          padding-left: 15px;
          padding-right: 15px; } }
    section.content-builder.testimonials p {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.8rem; }
    section.content-builder.testimonials h4 {
      font-size: .9rem;
      font-weight: 300;
      color: #5C483A; }
  section.content-builder.latest-posts {
    padding-top: 0;
    padding-bottom: 0; }
    section.content-builder.latest-posts .container-fluid {
      padding-left: 0;
      padding-right: 0; }
    section.content-builder.latest-posts h3 {
      font-weight: 100;
      font-size: 2.2rem;
      margin-bottom: 1.5rem;
      color: #5C483A; }
    section.content-builder.latest-posts p {
      color: #5C483A;
      font-weight: 300;
      margin-bottom: 1.5rem; }
    section.content-builder.latest-posts .team-member {
      padding-left: 0;
      padding-right: 0; }
      section.content-builder.latest-posts .team-member a {
        margin-bottom: 75px;
        display: block; }
      section.content-builder.latest-posts .team-member .member-info {
        background: #B3D8EF;
        padding: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 75px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; }
        section.content-builder.latest-posts .team-member .member-info h4 {
          color: #5C483A;
          font-size: 1rem;
          font-weight: 700;
          margin-bottom: 0;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out; }
        section.content-builder.latest-posts .team-member .member-info p {
          color: #94b3c6;
          font-size: .8rem;
          line-height: 1.4rem;
          margin-bottom: 0; }
        section.content-builder.latest-posts .team-member .member-info:after {
          position: absolute;
          top: -6px;
          left: 2rem;
          content: '';
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 8px solid #B3D8EF;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          -ms-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out; }
      section.content-builder.latest-posts .team-member:hover .member-info {
        background: #5C483A;
        padding-bottom: 1rem;
        height: 80px; }
        section.content-builder.latest-posts .team-member:hover .member-info h4 {
          color: #F1EEDC; }
        section.content-builder.latest-posts .team-member:hover .member-info:after {
          border-bottom: 6px solid #5C483A; }
  section.content-builder.gallery-video-block {
    background: #F1EEDC;
    padding-top: 3rem; }
    @media (min-width: 768px) {
      section.content-builder.gallery-video-block {
        padding-top: 4rem; } }
    section.content-builder.gallery-video-block .col-lg-6 {
      padding-left: 2rem;
      padding-right: 2rem; }
      section.content-builder.gallery-video-block .col-lg-6:first-child {
        margin-bottom: 2rem; }
      @media (min-width: 992px) {
        section.content-builder.gallery-video-block .col-lg-6 {
          padding-left: 15px;
          padding-right: 15px; }
          section.content-builder.gallery-video-block .col-lg-6:first-child {
            margin-bottom: 0; } }
    section.content-builder.gallery-video-block .inner {
      background: #FFFFFF;
      padding: .5rem; }
    section.content-builder.gallery-video-block h2 {
      font-weight: 100;
      font-size: 2.5rem;
      margin-bottom: 1rem; }
    section.content-builder.gallery-video-block h4 {
      font-weight: 100;
      font-size: 2rem;
      margin-bottom: 1rem;
      color: #5C483A; }
    section.content-builder.gallery-video-block p {
      font-weight: 300;
      font-size: .8rem;
      line-height: 1.5rem;
      margin-bottom: 1rem; }
    section.content-builder.gallery-video-block ul {
      list-style: none;
      margin-bottom: 1.5rem;
      padding-left: 1rem; }
      section.content-builder.gallery-video-block ul:last-child {
        margin-bottom: 0; }
      section.content-builder.gallery-video-block ul li {
        font-size: .9rem;
        line-height: 1.5rem;
        margin-bottom: .5rem;
        color: #5C483A;
        position: relative;
        padding-left: 1rem; }
        section.content-builder.gallery-video-block ul li:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f105";
          position: absolute;
          top: 0px;
          left: 0;
          color: #B3D8EF; }
  section.content-builder.google-map {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 3px solid #5C483A; }
    section.content-builder.google-map .row, section.content-builder.google-map .gform_wrapper ul.gform_fields {
      margin-left: 0;
      margin-right: 0; }
    section.content-builder.google-map .contact-info {
      padding-left: 2rem !important;
      padding-right: 2rem !important; }
      section.content-builder.google-map .contact-info .row, section.content-builder.google-map .contact-info .gform_wrapper ul.gform_fields, section.content-builder.google-map .gform_wrapper .contact-info ul.gform_fields {
        margin-left: 0;
        margin-right: 0; }
        section.content-builder.google-map .contact-info .row .col-sm-6, section.content-builder.google-map .contact-info .gform_wrapper ul.gform_fields .col-sm-6, section.content-builder.google-map .gform_wrapper .contact-info ul.gform_fields .col-sm-6 {
          padding-left: 0;
          padding-right: 0; }
          section.content-builder.google-map .contact-info .row .col-sm-6:nth-child(1), section.content-builder.google-map .contact-info .gform_wrapper ul.gform_fields .col-sm-6:nth-child(1), section.content-builder.google-map .gform_wrapper .contact-info ul.gform_fields .col-sm-6:nth-child(1) {
            padding-right: 1.5rem; }
          section.content-builder.google-map .contact-info .row .col-sm-6:nth-child(2), section.content-builder.google-map .contact-info .gform_wrapper ul.gform_fields .col-sm-6:nth-child(2), section.content-builder.google-map .gform_wrapper .contact-info ul.gform_fields .col-sm-6:nth-child(2) {
            padding-left: 1.5rem; }
    section.content-builder.google-map .col-md-6 {
      padding-left: 0;
      padding-right: 0; }
      section.content-builder.google-map .col-md-6:nth-child(1) {
        height: 550px;
        overflow: hidden; }
    section.content-builder.google-map h3 {
      color: #94b3c6;
      font-weight: 400;
      font-size: 1.1rem; }
    section.content-builder.google-map p {
      font-size: .9rem;
      line-height: 1.5rem;
      margin-bottom: 1.5rem; }
    section.content-builder.google-map address {
      font-size: .9rem;
      line-height: 1.5rem;
      color: #5C483A;
      margin-bottom: 0; }
  section.content-builder.sevices-list {
    padding-bottom: 2rem; }
    section.content-builder.sevices-list h2 {
      font-weight: 100;
      color: #5C483A;
      text-align: center;
      margin-bottom: 2rem; }
      @media (min-width: 768px) {
        section.content-builder.sevices-list h2 {
          margin-bottom: 4rem; } }
    section.content-builder.sevices-list .service {
      margin-bottom: 3rem;
      padding-left: 2rem;
      padding-right: 2rem; }
      section.content-builder.sevices-list .service h3 {
        font-weight: 700;
        font-size: 1.2rem; }
      section.content-builder.sevices-list .service p {
        font-size: .8rem;
        line-height: 1.4rem;
        color: #94b3c6;
        font-weight: 300;
        margin-bottom: 0; }
      section.content-builder.sevices-list .service a {
        font-size: .8rem;
        line-height: 1.4rem;
        font-weight: 300;
        text-transform: uppercase; }
      section.content-builder.sevices-list .service img {
        margin-bottom: 1.5rem; }
      @media (min-width: 768px) {
        section.content-builder.sevices-list .service {
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 2rem; }
          section.content-builder.sevices-list .service img {
            padding-bottom: 0; } }
  section.content-builder.full-width-content {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; }
    section.content-builder.full-width-content:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      background: rgba(255, 255, 255, 0.7); }
    section.content-builder.full-width-content h3 {
      font-weight: 100;
      font-size: 2.5rem;
      margin-bottom: 1rem; }
    section.content-builder.full-width-content h4 {
      font-weight: 100;
      font-size: 2rem;
      margin-bottom: 1rem;
      color: #5C483A; }
    section.content-builder.full-width-content ul {
      list-style: none;
      margin-bottom: 1.5rem;
      padding-left: 1rem; }
      section.content-builder.full-width-content ul li {
        font-size: .9rem;
        line-height: 1.5rem;
        margin-bottom: .5rem;
        color: #5C483A;
        position: relative;
        padding-left: 1rem; }
        section.content-builder.full-width-content ul li:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f105";
          position: absolute;
          top: 0px;
          left: 0;
          color: #B3D8EF; }
    section.content-builder.full-width-content p {
      font-size: .9rem;
      line-height: 1.5rem;
      margin-bottom: 1.5rem; }

body.blog .blog-nav,
body.category .blog-nav,
body.search .blog-nav {
  margin-top: 2.5rem; }
  body.blog .blog-nav section.widget,
  body.category .blog-nav section.widget,
  body.search .blog-nav section.widget {
    display: inline-block; }
    body.blog .blog-nav section.widget h3,
    body.category .blog-nav section.widget h3,
    body.search .blog-nav section.widget h3 {
      display: none; }
    body.blog .blog-nav section.widget ul,
    body.category .blog-nav section.widget ul,
    body.search .blog-nav section.widget ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 2rem;
      margin-right: 1rem; }
      body.blog .blog-nav section.widget ul li,
      body.category .blog-nav section.widget ul li,
      body.search .blog-nav section.widget ul li {
        display: inline-block;
        margin-right: .3rem;
        margin-bottom: .5rem; }

body.blog .blog-list-outer,
body.category .blog-list-outer,
body.search .blog-list-outer {
  background: #F1EEDC; }

body.blog .blog-list,
body.category .blog-list,
body.search .blog-list {
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  margin-left: 0;
  margin-right: 0; }
  body.blog .blog-list img,
  body.category .blog-list img,
  body.search .blog-list img {
    margin-bottom: 90px; }
  body.blog .blog-list h2,
  body.category .blog-list h2,
  body.search .blog-list h2 {
    font-size: 1rem;
    margin-bottom: 0; }
  body.blog .blog-list p,
  body.category .blog-list p,
  body.search .blog-list p {
    font-size: .8rem;
    margin-bottom: 0;
    margin-top: 1rem;
    color: #B3D8EF;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  body.blog .blog-list .post-content,
  body.category .blog-list .post-content,
  body.search .blog-list .post-content {
    padding: 1.5rem;
    background: #FFFFFF;
    min-height: 90px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    body.blog .blog-list .post-content:after,
    body.category .blog-list .post-content:after,
    body.search .blog-list .post-content:after {
      position: absolute;
      top: -8px;
      left: 2rem;
      content: '';
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 8px solid #FFFFFF;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
  body.blog .blog-list .featured,
  body.blog .blog-list .two-five,
  body.blog .blog-list .regular-post,
  body.category .blog-list .featured,
  body.category .blog-list .two-five,
  body.category .blog-list .regular-post,
  body.search .blog-list .featured,
  body.search .blog-list .two-five,
  body.search .blog-list .regular-post {
    padding: 0; }
  body.blog .blog-list .featured h2,
  body.category .blog-list .featured h2,
  body.search .blog-list .featured h2 {
    font-size: 1.1rem; }
  body.blog .blog-list .featured:hover .post-content,
  body.category .blog-list .featured:hover .post-content,
  body.search .blog-list .featured:hover .post-content {
    background: #5C483A;
    padding-bottom: 2rem; }
    body.blog .blog-list .featured:hover .post-content:after,
    body.category .blog-list .featured:hover .post-content:after,
    body.search .blog-list .featured:hover .post-content:after {
      border-bottom: 8px solid #5C483A; }
    body.blog .blog-list .featured:hover .post-content h2 a,
    body.category .blog-list .featured:hover .post-content h2 a,
    body.search .blog-list .featured:hover .post-content h2 a {
      color: #F1EEDC; }
    body.blog .blog-list .featured:hover .post-content p,
    body.category .blog-list .featured:hover .post-content p,
    body.search .blog-list .featured:hover .post-content p {
      color: #FFFFFF; }
      body.blog .blog-list .featured:hover .post-content p a,
      body.category .blog-list .featured:hover .post-content p a,
      body.search .blog-list .featured:hover .post-content p a {
        color: #B3D8EF; }
  body.blog .blog-list .two-five .row, body.blog .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields, .content-builder .gform_wrapper body.blog .blog-list .two-five ul.gform_fields,
  body.category .blog-list .two-five .row,
  body.category .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields, .content-builder .gform_wrapper
  body.category .blog-list .two-five ul.gform_fields,
  body.search .blog-list .two-five .row,
  body.search .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields, .content-builder .gform_wrapper
  body.search .blog-list .two-five ul.gform_fields {
    margin: 0; }
    body.blog .blog-list .two-five .row .col-sm-6, body.blog .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6, .content-builder .gform_wrapper body.blog .blog-list .two-five ul.gform_fields .col-sm-6,
    body.category .blog-list .two-five .row .col-sm-6,
    body.category .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6, .content-builder .gform_wrapper
    body.category .blog-list .two-five ul.gform_fields .col-sm-6,
    body.search .blog-list .two-five .row .col-sm-6,
    body.search .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6, .content-builder .gform_wrapper
    body.search .blog-list .two-five ul.gform_fields .col-sm-6 {
      padding: 0; }
      body.blog .blog-list .two-five .row .col-sm-6:hover .post-content, body.blog .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content, .content-builder .gform_wrapper body.blog .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content,
      body.category .blog-list .two-five .row .col-sm-6:hover .post-content,
      body.category .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content, .content-builder .gform_wrapper
      body.category .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content,
      body.search .blog-list .two-five .row .col-sm-6:hover .post-content,
      body.search .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content, .content-builder .gform_wrapper
      body.search .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content {
        background: #5C483A;
        padding-bottom: 2rem; }
        body.blog .blog-list .two-five .row .col-sm-6:hover .post-content:after, body.blog .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content:after, .content-builder .gform_wrapper body.blog .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content:after,
        body.category .blog-list .two-five .row .col-sm-6:hover .post-content:after,
        body.category .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content:after, .content-builder .gform_wrapper
        body.category .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content:after,
        body.search .blog-list .two-five .row .col-sm-6:hover .post-content:after,
        body.search .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content:after, .content-builder .gform_wrapper
        body.search .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content:after {
          border-bottom: 8px solid #5C483A; }
        body.blog .blog-list .two-five .row .col-sm-6:hover .post-content h2 a, body.blog .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content h2 a, .content-builder .gform_wrapper body.blog .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content h2 a,
        body.category .blog-list .two-five .row .col-sm-6:hover .post-content h2 a,
        body.category .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content h2 a, .content-builder .gform_wrapper
        body.category .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content h2 a,
        body.search .blog-list .two-five .row .col-sm-6:hover .post-content h2 a,
        body.search .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content h2 a, .content-builder .gform_wrapper
        body.search .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content h2 a {
          color: #F1EEDC; }
        body.blog .blog-list .two-five .row .col-sm-6:hover .post-content p, body.blog .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content p, .content-builder .gform_wrapper body.blog .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content p,
        body.category .blog-list .two-five .row .col-sm-6:hover .post-content p,
        body.category .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content p, .content-builder .gform_wrapper
        body.category .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content p,
        body.search .blog-list .two-five .row .col-sm-6:hover .post-content p,
        body.search .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content p, .content-builder .gform_wrapper
        body.search .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content p {
          color: #FFFFFF; }
          body.blog .blog-list .two-five .row .col-sm-6:hover .post-content p a, body.blog .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content p a, .content-builder .gform_wrapper body.blog .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content p a,
          body.category .blog-list .two-five .row .col-sm-6:hover .post-content p a,
          body.category .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content p a, .content-builder .gform_wrapper
          body.category .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content p a,
          body.search .blog-list .two-five .row .col-sm-6:hover .post-content p a,
          body.search .blog-list .two-five .content-builder .gform_wrapper ul.gform_fields .col-sm-6:hover .post-content p a, .content-builder .gform_wrapper
          body.search .blog-list .two-five ul.gform_fields .col-sm-6:hover .post-content p a {
            color: #B3D8EF; }
  body.blog .blog-list .regular-post:hover .post-content,
  body.category .blog-list .regular-post:hover .post-content,
  body.search .blog-list .regular-post:hover .post-content {
    background: #5C483A;
    padding-bottom: 2rem; }
    body.blog .blog-list .regular-post:hover .post-content:after,
    body.category .blog-list .regular-post:hover .post-content:after,
    body.search .blog-list .regular-post:hover .post-content:after {
      border-bottom: 8px solid #5C483A; }
    body.blog .blog-list .regular-post:hover .post-content h2 a,
    body.category .blog-list .regular-post:hover .post-content h2 a,
    body.search .blog-list .regular-post:hover .post-content h2 a {
      color: #F1EEDC; }
    body.blog .blog-list .regular-post:hover .post-content p,
    body.category .blog-list .regular-post:hover .post-content p,
    body.search .blog-list .regular-post:hover .post-content p {
      color: #FFFFFF; }
      body.blog .blog-list .regular-post:hover .post-content p a,
      body.category .blog-list .regular-post:hover .post-content p a,
      body.search .blog-list .regular-post:hover .post-content p a {
        color: #B3D8EF; }

body.single-post .page-content-wrap {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background: #F1EEDC; }
  body.single-post .page-content-wrap .entry-content img {
    background: #FFFFFF;
    padding: .4rem;
    margin-bottom: 1rem; }
    body.single-post .page-content-wrap .entry-content img.alignleft {
      margin-right: 2.5rem; }
    body.single-post .page-content-wrap .entry-content img.alignright {
      margin-left: 2.5rem; }
  body.single-post .page-content-wrap .entry-content ul {
    color: #5C483A; }
  body.single-post .page-content-wrap .entry-content p {
    font-size: .9rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem; }
    body.single-post .page-content-wrap .entry-content p:last-child {
      margin-bottom: 0; }

body.single-team .page-content-wrap {
  background: #B3D8EF;
  padding-top: 2.5rem;
  padding-bottom: 4.5rem; }

body.single-team .team-main,
body.single-team .team-blocks {
  margin-left: 0;
  margin-right: 0; }
  body.single-team .team-main > div,
  body.single-team .team-blocks > div {
    padding-left: 0;
    padding-right: 0; }

body.single-team .team-main h2 {
  font-weight: 100;
  padding-right: 2rem;
  margin-bottom: 1.5rem; }

body.single-team .team-main p {
  padding-right: 2rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-bottom: 1.5rem; }

body.single-team .team-blocks > div {
  background: #5C483A; }

body.single-team .team-blocks .p-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important; }

body.single-team .team-blocks h3 {
  color: #F1EEDC;
  font-weight: 100;
  font-size: 2rem; }

body.single-team .team-blocks p {
  color: #F1EEDC;
  font-weight: 300;
  margin-bottom: 0; }

body#tinymce {
  margin: 12px !important; }
