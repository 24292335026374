@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700');

$roboto: 'Roboto Slab', serif;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


$brown:					#5C483A;
$tan:						#F1EEDC;
$light-blue:		#B3D8EF;
$white:					#FFFFFF;
$blue-text:			#94b3c6;


@mixin animate-element($time) {
	-webkit-transition: 	all $time ease-in-out;
	-moz-transition: 			all $time ease-in-out;
	-ms-transition: 			all $time ease-in-out;
	-o-transition: 				all $time ease-in-out;
	transition: 					all $time ease-in-out;	
}

@mixin background-cover() {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;	
}

@mixin round($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin white-overlary() {
	background:  rgb(255,255,255);
	background: rgba(255,255,255, .7);	
}
