body {
	font-family: $roboto;
}

a {
	color: $brown;
	text-decoration: none;
	@include animate-element(.2s);
	&:hover {
		color: $light-blue;
		text-decoration: none;
	}
}

h2, h3 {
	color: $brown;
}

p {
	color: $brown;
	font-weight: 400;
}

.mm-menu {
	background: $tan !important;
	
	.mm-navbar {
		border-bottom: none;
		padding: 0 3rem;
		height: auto;
		a {
			color: $brown !important;
			font-weight: 100;
			font-size: 1.5rem;
			line-height: 1.5rem;
			padding: 2rem 0 1rem;
			text-align: left;
		}
	}
	
	.mm-listview>li:not(.mm-divider):after {
		border-bottom-color: transparent;
	}
	
	.mm-listview {
		margin-top: 1rem !important;
		li {
			a {
				font-weight: 400;
				color: $brown;
				padding: 1rem 2rem 1rem 3rem;
				text-transform: uppercase;
				position: relative;
			}
			&.active {
				a {
					&:before {
						font-family: "Font Awesome 5 Free";
						font-weight: 900; 
						content: "\f1b0";
						position: absolute;
						top: 50%;
						left: 1rem;
						color: $light-blue;
						font-size: 1.2rem;
						margin-top: -10px;
					}
				}
			}
		}
	}
	
}